"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CamelDefinitionYamlStep = void 0;
/**
 * Generated by karavan build tools - do NOT edit this file!
 */
var IntegrationDefinition_1 = require("../model/IntegrationDefinition");
var CamelDefinition_1 = require("../model/CamelDefinition");
var CamelUtil_1 = require("./CamelUtil");
var CamelMetadata_1 = require("../model/CamelMetadata");
var CamelDefinitionYamlStep = /** @class */ (function () {
    function CamelDefinitionYamlStep() {
    }
    CamelDefinitionYamlStep.readProcessorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ProcessorDefinition(__assign({}, element)) : new CamelDefinition_1.ProcessorDefinition();
        if ((element === null || element === void 0 ? void 0 : element.idempotentConsumer) !== undefined) {
            if (Array.isArray(element.idempotentConsumer)) {
                def.idempotentConsumer = CamelDefinitionYamlStep.readIdempotentConsumerDefinition(element.idempotentConsumer[0]);
            }
            else {
                def.idempotentConsumer = CamelDefinitionYamlStep.readIdempotentConsumerDefinition(element.idempotentConsumer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.resumable) !== undefined) {
            if (Array.isArray(element.resumable)) {
                def.resumable = CamelDefinitionYamlStep.readResumableDefinition(element.resumable[0]);
            }
            else {
                def.resumable = CamelDefinitionYamlStep.readResumableDefinition(element.resumable);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.doTry) !== undefined) {
            if (Array.isArray(element.doTry)) {
                def.doTry = CamelDefinitionYamlStep.readTryDefinition(element.doTry[0]);
            }
            else {
                def.doTry = CamelDefinitionYamlStep.readTryDefinition(element.doTry);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.convertBodyTo) !== undefined) {
            if (Array.isArray(element.convertBodyTo)) {
                def.convertBodyTo = CamelDefinitionYamlStep.readConvertBodyDefinition(element.convertBodyTo[0]);
            }
            else {
                def.convertBodyTo = CamelDefinitionYamlStep.readConvertBodyDefinition(element.convertBodyTo);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.recipientList) !== undefined) {
            if (Array.isArray(element.recipientList)) {
                def.recipientList = CamelDefinitionYamlStep.readRecipientListDefinition(element.recipientList[0]);
            }
            else {
                def.recipientList = CamelDefinitionYamlStep.readRecipientListDefinition(element.recipientList);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.when) !== undefined) {
            if (Array.isArray(element.when)) {
                def.when = CamelDefinitionYamlStep.readWhenDefinition(element.when[0]);
            }
            else {
                def.when = CamelDefinitionYamlStep.readWhenDefinition(element.when);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.setHeader) !== undefined) {
            if (Array.isArray(element.setHeader)) {
                def.setHeader = CamelDefinitionYamlStep.readSetHeaderDefinition(element.setHeader[0]);
            }
            else {
                def.setHeader = CamelDefinitionYamlStep.readSetHeaderDefinition(element.setHeader);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.inOnly) !== undefined) {
            if (Array.isArray(element.inOnly)) {
                def.inOnly = CamelDefinitionYamlStep.readInOnlyDefinition(element.inOnly[0]);
            }
            else {
                def.inOnly = CamelDefinitionYamlStep.readInOnlyDefinition(element.inOnly);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.inOut) !== undefined) {
            if (Array.isArray(element.inOut)) {
                def.inOut = CamelDefinitionYamlStep.readInOutDefinition(element.inOut[0]);
            }
            else {
                def.inOut = CamelDefinitionYamlStep.readInOutDefinition(element.inOut);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.split) !== undefined) {
            if (Array.isArray(element.split)) {
                def.split = CamelDefinitionYamlStep.readSplitDefinition(element.split[0]);
            }
            else {
                def.split = CamelDefinitionYamlStep.readSplitDefinition(element.split);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint) !== undefined) {
            if (Array.isArray(element.interceptSendToEndpoint)) {
                def.interceptSendToEndpoint = CamelDefinitionYamlStep.readInterceptSendToEndpointDefinition(element.interceptSendToEndpoint[0]);
            }
            else {
                def.interceptSendToEndpoint = CamelDefinitionYamlStep.readInterceptSendToEndpointDefinition(element.interceptSendToEndpoint);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.loop) !== undefined) {
            if (Array.isArray(element.loop)) {
                def.loop = CamelDefinitionYamlStep.readLoopDefinition(element.loop[0]);
            }
            else {
                def.loop = CamelDefinitionYamlStep.readLoopDefinition(element.loop);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.setExchangePattern) !== undefined) {
            if (Array.isArray(element.setExchangePattern)) {
                def.setExchangePattern = CamelDefinitionYamlStep.readSetExchangePatternDefinition(element.setExchangePattern[0]);
            }
            else {
                def.setExchangePattern = CamelDefinitionYamlStep.readSetExchangePatternDefinition(element.setExchangePattern);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.marshal) !== undefined) {
            if (Array.isArray(element.marshal)) {
                def.marshal = CamelDefinitionYamlStep.readMarshalDefinition(element.marshal[0]);
            }
            else {
                def.marshal = CamelDefinitionYamlStep.readMarshalDefinition(element.marshal);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.onFallback) !== undefined) {
            if (Array.isArray(element.onFallback)) {
                def.onFallback = CamelDefinitionYamlStep.readOnFallbackDefinition(element.onFallback[0]);
            }
            else {
                def.onFallback = CamelDefinitionYamlStep.readOnFallbackDefinition(element.onFallback);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.circuitBreaker) !== undefined) {
            if (Array.isArray(element.circuitBreaker)) {
                def.circuitBreaker = CamelDefinitionYamlStep.readCircuitBreakerDefinition(element.circuitBreaker[0]);
            }
            else {
                def.circuitBreaker = CamelDefinitionYamlStep.readCircuitBreakerDefinition(element.circuitBreaker);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.enrich) !== undefined) {
            if (Array.isArray(element.enrich)) {
                def.enrich = CamelDefinitionYamlStep.readEnrichDefinition(element.enrich[0]);
            }
            else {
                def.enrich = CamelDefinitionYamlStep.readEnrichDefinition(element.enrich);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.kamelet) !== undefined) {
            if (Array.isArray(element.kamelet)) {
                def.kamelet = CamelDefinitionYamlStep.readKameletDefinition(element.kamelet[0]);
            }
            else {
                def.kamelet = CamelDefinitionYamlStep.readKameletDefinition(element.kamelet);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.saga) !== undefined) {
            if (Array.isArray(element.saga)) {
                def.saga = CamelDefinitionYamlStep.readSagaDefinition(element.saga[0]);
            }
            else {
                def.saga = CamelDefinitionYamlStep.readSagaDefinition(element.saga);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bean) !== undefined) {
            if (Array.isArray(element.bean)) {
                def.bean = CamelDefinitionYamlStep.readBeanDefinition(element.bean[0]);
            }
            else {
                def.bean = CamelDefinitionYamlStep.readBeanDefinition(element.bean);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.otherwise) !== undefined) {
            if (Array.isArray(element.otherwise)) {
                def.otherwise = CamelDefinitionYamlStep.readOtherwiseDefinition(element.otherwise[0]);
            }
            else {
                def.otherwise = CamelDefinitionYamlStep.readOtherwiseDefinition(element.otherwise);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.sort) !== undefined) {
            if (Array.isArray(element.sort)) {
                def.sort = CamelDefinitionYamlStep.readSortDefinition(element.sort[0]);
            }
            else {
                def.sort = CamelDefinitionYamlStep.readSortDefinition(element.sort);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.loadBalance) !== undefined) {
            if (Array.isArray(element.loadBalance)) {
                def.loadBalance = CamelDefinitionYamlStep.readLoadBalanceDefinition(element.loadBalance[0]);
            }
            else {
                def.loadBalance = CamelDefinitionYamlStep.readLoadBalanceDefinition(element.loadBalance);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.script) !== undefined) {
            if (Array.isArray(element.script)) {
                def.script = CamelDefinitionYamlStep.readScriptDefinition(element.script[0]);
            }
            else {
                def.script = CamelDefinitionYamlStep.readScriptDefinition(element.script);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.removeHeader) !== undefined) {
            if (Array.isArray(element.removeHeader)) {
                def.removeHeader = CamelDefinitionYamlStep.readRemoveHeaderDefinition(element.removeHeader[0]);
            }
            else {
                def.removeHeader = CamelDefinitionYamlStep.readRemoveHeaderDefinition(element.removeHeader);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.delay) !== undefined) {
            if (Array.isArray(element.delay)) {
                def.delay = CamelDefinitionYamlStep.readDelayDefinition(element.delay[0]);
            }
            else {
                def.delay = CamelDefinitionYamlStep.readDelayDefinition(element.delay);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.stop) !== undefined) {
            if (Array.isArray(element.stop)) {
                def.stop = CamelDefinitionYamlStep.readStopDefinition(element.stop[0]);
            }
            else {
                def.stop = CamelDefinitionYamlStep.readStopDefinition(element.stop);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.serviceCall) !== undefined) {
            if (Array.isArray(element.serviceCall)) {
                def.serviceCall = CamelDefinitionYamlStep.readServiceCallDefinition(element.serviceCall[0]);
            }
            else {
                def.serviceCall = CamelDefinitionYamlStep.readServiceCallDefinition(element.serviceCall);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.intercept) !== undefined) {
            if (Array.isArray(element.intercept)) {
                def.intercept = CamelDefinitionYamlStep.readInterceptDefinition(element.intercept[0]);
            }
            else {
                def.intercept = CamelDefinitionYamlStep.readInterceptDefinition(element.intercept);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.whenSkipSendToEndpoint) !== undefined) {
            if (Array.isArray(element.whenSkipSendToEndpoint)) {
                def.whenSkipSendToEndpoint = CamelDefinitionYamlStep.readWhenSkipSendToEndpointDefinition(element.whenSkipSendToEndpoint[0]);
            }
            else {
                def.whenSkipSendToEndpoint = CamelDefinitionYamlStep.readWhenSkipSendToEndpointDefinition(element.whenSkipSendToEndpoint);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.setProperty) !== undefined) {
            if (Array.isArray(element.setProperty)) {
                def.setProperty = CamelDefinitionYamlStep.readSetPropertyDefinition(element.setProperty[0]);
            }
            else {
                def.setProperty = CamelDefinitionYamlStep.readSetPropertyDefinition(element.setProperty);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.removeProperty) !== undefined) {
            if (Array.isArray(element.removeProperty)) {
                def.removeProperty = CamelDefinitionYamlStep.readRemovePropertyDefinition(element.removeProperty[0]);
            }
            else {
                def.removeProperty = CamelDefinitionYamlStep.readRemovePropertyDefinition(element.removeProperty);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.interceptFrom) !== undefined) {
            if (Array.isArray(element.interceptFrom)) {
                def.interceptFrom = CamelDefinitionYamlStep.readInterceptFromDefinition(element.interceptFrom[0]);
            }
            else {
                def.interceptFrom = CamelDefinitionYamlStep.readInterceptFromDefinition(element.interceptFrom);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.onCompletion) !== undefined) {
            if (Array.isArray(element.onCompletion)) {
                def.onCompletion = CamelDefinitionYamlStep.readOnCompletionDefinition(element.onCompletion[0]);
            }
            else {
                def.onCompletion = CamelDefinitionYamlStep.readOnCompletionDefinition(element.onCompletion);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pausable) !== undefined) {
            if (Array.isArray(element.pausable)) {
                def.pausable = CamelDefinitionYamlStep.readPausableDefinition(element.pausable[0]);
            }
            else {
                def.pausable = CamelDefinitionYamlStep.readPausableDefinition(element.pausable);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.throttle) !== undefined) {
            if (Array.isArray(element.throttle)) {
                def.throttle = CamelDefinitionYamlStep.readThrottleDefinition(element.throttle[0]);
            }
            else {
                def.throttle = CamelDefinitionYamlStep.readThrottleDefinition(element.throttle);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.doFinally) !== undefined) {
            if (Array.isArray(element.doFinally)) {
                def.doFinally = CamelDefinitionYamlStep.readFinallyDefinition(element.doFinally[0]);
            }
            else {
                def.doFinally = CamelDefinitionYamlStep.readFinallyDefinition(element.doFinally);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.log) !== undefined) {
            if (Array.isArray(element.log)) {
                def.log = CamelDefinitionYamlStep.readLogDefinition(element.log[0]);
            }
            else {
                def.log = CamelDefinitionYamlStep.readLogDefinition(element.log);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.doCatch) !== undefined) {
            if (Array.isArray(element.doCatch)) {
                def.doCatch = CamelDefinitionYamlStep.readCatchDefinition(element.doCatch[0]);
            }
            else {
                def.doCatch = CamelDefinitionYamlStep.readCatchDefinition(element.doCatch);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.transacted) !== undefined) {
            if (Array.isArray(element.transacted)) {
                def.transacted = CamelDefinitionYamlStep.readTransactedDefinition(element.transacted[0]);
            }
            else {
                def.transacted = CamelDefinitionYamlStep.readTransactedDefinition(element.transacted);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.claimCheck) !== undefined) {
            if (Array.isArray(element.claimCheck)) {
                def.claimCheck = CamelDefinitionYamlStep.readClaimCheckDefinition(element.claimCheck[0]);
            }
            else {
                def.claimCheck = CamelDefinitionYamlStep.readClaimCheckDefinition(element.claimCheck);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pollEnrich) !== undefined) {
            if (Array.isArray(element.pollEnrich)) {
                def.pollEnrich = CamelDefinitionYamlStep.readPollEnrichDefinition(element.pollEnrich[0]);
            }
            else {
                def.pollEnrich = CamelDefinitionYamlStep.readPollEnrichDefinition(element.pollEnrich);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.removeHeaders) !== undefined) {
            if (Array.isArray(element.removeHeaders)) {
                def.removeHeaders = CamelDefinitionYamlStep.readRemoveHeadersDefinition(element.removeHeaders[0]);
            }
            else {
                def.removeHeaders = CamelDefinitionYamlStep.readRemoveHeadersDefinition(element.removeHeaders);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.aggregate) !== undefined) {
            if (Array.isArray(element.aggregate)) {
                def.aggregate = CamelDefinitionYamlStep.readAggregateDefinition(element.aggregate[0]);
            }
            else {
                def.aggregate = CamelDefinitionYamlStep.readAggregateDefinition(element.aggregate);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.resequence) !== undefined) {
            if (Array.isArray(element.resequence)) {
                def.resequence = CamelDefinitionYamlStep.readResequenceDefinition(element.resequence[0]);
            }
            else {
                def.resequence = CamelDefinitionYamlStep.readResequenceDefinition(element.resequence);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.routingSlip) !== undefined) {
            if (Array.isArray(element.routingSlip)) {
                def.routingSlip = CamelDefinitionYamlStep.readRoutingSlipDefinition(element.routingSlip[0]);
            }
            else {
                def.routingSlip = CamelDefinitionYamlStep.readRoutingSlipDefinition(element.routingSlip);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.transform) !== undefined) {
            if (Array.isArray(element.transform)) {
                def.transform = CamelDefinitionYamlStep.readTransformDefinition(element.transform[0]);
            }
            else {
                def.transform = CamelDefinitionYamlStep.readTransformDefinition(element.transform);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.removeProperties) !== undefined) {
            if (Array.isArray(element.removeProperties)) {
                def.removeProperties = CamelDefinitionYamlStep.readRemovePropertiesDefinition(element.removeProperties[0]);
            }
            else {
                def.removeProperties = CamelDefinitionYamlStep.readRemovePropertiesDefinition(element.removeProperties);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.policy) !== undefined) {
            if (Array.isArray(element.policy)) {
                def.policy = CamelDefinitionYamlStep.readPolicyDefinition(element.policy[0]);
            }
            else {
                def.policy = CamelDefinitionYamlStep.readPolicyDefinition(element.policy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.validate) !== undefined) {
            if (Array.isArray(element.validate)) {
                def.validate = CamelDefinitionYamlStep.readValidateDefinition(element.validate[0]);
            }
            else {
                def.validate = CamelDefinitionYamlStep.readValidateDefinition(element.validate);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.rollback) !== undefined) {
            if (Array.isArray(element.rollback)) {
                def.rollback = CamelDefinitionYamlStep.readRollbackDefinition(element.rollback[0]);
            }
            else {
                def.rollback = CamelDefinitionYamlStep.readRollbackDefinition(element.rollback);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.process) !== undefined) {
            if (Array.isArray(element.process)) {
                def.process = CamelDefinitionYamlStep.readProcessDefinition(element.process[0]);
            }
            else {
                def.process = CamelDefinitionYamlStep.readProcessDefinition(element.process);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.threads) !== undefined) {
            if (Array.isArray(element.threads)) {
                def.threads = CamelDefinitionYamlStep.readThreadsDefinition(element.threads[0]);
            }
            else {
                def.threads = CamelDefinitionYamlStep.readThreadsDefinition(element.threads);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.setBody) !== undefined) {
            if (Array.isArray(element.setBody)) {
                def.setBody = CamelDefinitionYamlStep.readSetBodyDefinition(element.setBody[0]);
            }
            else {
                def.setBody = CamelDefinitionYamlStep.readSetBodyDefinition(element.setBody);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.sample) !== undefined) {
            if (Array.isArray(element.sample)) {
                def.sample = CamelDefinitionYamlStep.readSamplingDefinition(element.sample[0]);
            }
            else {
                def.sample = CamelDefinitionYamlStep.readSamplingDefinition(element.sample);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.throwException) !== undefined) {
            if (Array.isArray(element.throwException)) {
                def.throwException = CamelDefinitionYamlStep.readThrowExceptionDefinition(element.throwException[0]);
            }
            else {
                def.throwException = CamelDefinitionYamlStep.readThrowExceptionDefinition(element.throwException);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.dynamicRouter) !== undefined) {
            if (Array.isArray(element.dynamicRouter)) {
                def.dynamicRouter = CamelDefinitionYamlStep.readDynamicRouterDefinition(element.dynamicRouter[0]);
            }
            else {
                def.dynamicRouter = CamelDefinitionYamlStep.readDynamicRouterDefinition(element.dynamicRouter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.multicast) !== undefined) {
            if (Array.isArray(element.multicast)) {
                def.multicast = CamelDefinitionYamlStep.readMulticastDefinition(element.multicast[0]);
            }
            else {
                def.multicast = CamelDefinitionYamlStep.readMulticastDefinition(element.multicast);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.filter) !== undefined) {
            if (Array.isArray(element.filter)) {
                def.filter = CamelDefinitionYamlStep.readFilterDefinition(element.filter[0]);
            }
            else {
                def.filter = CamelDefinitionYamlStep.readFilterDefinition(element.filter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pipeline) !== undefined) {
            if (Array.isArray(element.pipeline)) {
                def.pipeline = CamelDefinitionYamlStep.readPipelineDefinition(element.pipeline[0]);
            }
            else {
                def.pipeline = CamelDefinitionYamlStep.readPipelineDefinition(element.pipeline);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.unmarshal) !== undefined) {
            if (Array.isArray(element.unmarshal)) {
                def.unmarshal = CamelDefinitionYamlStep.readUnmarshalDefinition(element.unmarshal[0]);
            }
            else {
                def.unmarshal = CamelDefinitionYamlStep.readUnmarshalDefinition(element.unmarshal);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.wireTap) !== undefined) {
            if (Array.isArray(element.wireTap)) {
                def.wireTap = CamelDefinitionYamlStep.readWireTapDefinition(element.wireTap[0]);
            }
            else {
                def.wireTap = CamelDefinitionYamlStep.readWireTapDefinition(element.wireTap);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.step) !== undefined) {
            if (Array.isArray(element.step)) {
                def.step = CamelDefinitionYamlStep.readStepDefinition(element.step[0]);
            }
            else {
                def.step = CamelDefinitionYamlStep.readStepDefinition(element.step);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.choice) !== undefined) {
            if (Array.isArray(element.choice)) {
                def.choice = CamelDefinitionYamlStep.readChoiceDefinition(element.choice[0]);
            }
            else {
                def.choice = CamelDefinitionYamlStep.readChoiceDefinition(element.choice);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readBeansDeserializer = function (element) {
        var def = element ? new CamelDefinition_1.BeansDeserializer(__assign({}, element)) : new CamelDefinition_1.BeansDeserializer();
        return def;
    };
    CamelDefinitionYamlStep.readErrorHandlerBuilderDeserializer = function (element) {
        var def = element ? new CamelDefinition_1.ErrorHandlerBuilderDeserializer(__assign({}, element)) : new CamelDefinition_1.ErrorHandlerBuilderDeserializer();
        if ((element === null || element === void 0 ? void 0 : element.deadLetterChannel) !== undefined) {
            if (Array.isArray(element.deadLetterChannel)) {
                def.deadLetterChannel = CamelDefinitionYamlStep.readDeadLetterChannelDefinition(element.deadLetterChannel[0]);
            }
            else {
                def.deadLetterChannel = CamelDefinitionYamlStep.readDeadLetterChannelDefinition(element.deadLetterChannel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.noErrorHandler) !== undefined) {
            if (Array.isArray(element.noErrorHandler)) {
                def.noErrorHandler = CamelDefinitionYamlStep.readNoErrorHandlerDefinition(element.noErrorHandler[0]);
            }
            else {
                def.noErrorHandler = CamelDefinitionYamlStep.readNoErrorHandlerDefinition(element.noErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jtaTransactionErrorHandler) !== undefined) {
            if (Array.isArray(element.jtaTransactionErrorHandler)) {
                def.jtaTransactionErrorHandler = CamelDefinitionYamlStep.readJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler[0]);
            }
            else {
                def.jtaTransactionErrorHandler = CamelDefinitionYamlStep.readJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultErrorHandler) !== undefined) {
            if (Array.isArray(element.defaultErrorHandler)) {
                def.defaultErrorHandler = CamelDefinitionYamlStep.readDefaultErrorHandlerDefinition(element.defaultErrorHandler[0]);
            }
            else {
                def.defaultErrorHandler = CamelDefinitionYamlStep.readDefaultErrorHandlerDefinition(element.defaultErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.springTransactionErrorHandler) !== undefined) {
            if (Array.isArray(element.springTransactionErrorHandler)) {
                def.springTransactionErrorHandler = CamelDefinitionYamlStep.readSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler[0]);
            }
            else {
                def.springTransactionErrorHandler = CamelDefinitionYamlStep.readSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.refErrorHandler) !== undefined) {
            if (Array.isArray(element.refErrorHandler)) {
                def.refErrorHandler = CamelDefinitionYamlStep.readRefErrorHandlerDefinition(element.refErrorHandler[0]);
            }
            else {
                def.refErrorHandler = CamelDefinitionYamlStep.readRefErrorHandlerDefinition(element.refErrorHandler);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readNamedBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.NamedBeanDefinition(__assign({}, element)) : new CamelDefinition_1.NamedBeanDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readOutputAwareFromDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputAwareFromDefinition(__assign({}, element)) : new CamelDefinition_1.OutputAwareFromDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readAggregateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.AggregateDefinition(__assign({}, element)) : new CamelDefinition_1.AggregateDefinition();
        if ((element === null || element === void 0 ? void 0 : element.completionTimeoutExpression) !== undefined) {
            if (Array.isArray(element.completionTimeoutExpression)) {
                def.completionTimeoutExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionTimeoutExpression[0]);
            }
            else {
                def.completionTimeoutExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionTimeoutExpression);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            if (Array.isArray(element.correlationExpression)) {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression[0]);
            }
            else {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.completionPredicate) !== undefined) {
            if (Array.isArray(element.completionPredicate)) {
                def.completionPredicate = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionPredicate[0]);
            }
            else {
                def.completionPredicate = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionPredicate);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.optimisticLockRetryPolicy) !== undefined) {
            if (Array.isArray(element.optimisticLockRetryPolicy)) {
                def.optimisticLockRetryPolicy = CamelDefinitionYamlStep.readOptimisticLockRetryPolicyDefinition(element.optimisticLockRetryPolicy[0]);
            }
            else {
                def.optimisticLockRetryPolicy = CamelDefinitionYamlStep.readOptimisticLockRetryPolicyDefinition(element.optimisticLockRetryPolicy);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        if ((element === null || element === void 0 ? void 0 : element.completionSizeExpression) !== undefined) {
            if (Array.isArray(element.completionSizeExpression)) {
                def.completionSizeExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionSizeExpression[0]);
            }
            else {
                def.completionSizeExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.completionSizeExpression);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BeanDefinition(__assign({}, element)) : new CamelDefinition_1.BeanDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readCatchDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CatchDefinition(__assign({}, element)) : new CamelDefinition_1.CatchDefinition();
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            if (Array.isArray(element.onWhen)) {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen[0]);
            }
            else {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readChoiceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ChoiceDefinition(__assign({}, element)) : new CamelDefinition_1.ChoiceDefinition();
        if ((element === null || element === void 0 ? void 0 : element.otherwise) !== undefined) {
            if (Array.isArray(element.otherwise)) {
                def.otherwise = CamelDefinitionYamlStep.readOtherwiseDefinition(element.otherwise[0]);
            }
            else {
                def.otherwise = CamelDefinitionYamlStep.readOtherwiseDefinition(element.otherwise);
            }
        }
        def.when = element && (element === null || element === void 0 ? void 0 : element.when) ? element === null || element === void 0 ? void 0 : element.when.map(function (x) { return CamelDefinitionYamlStep.readWhenDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCircuitBreakerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CircuitBreakerDefinition(__assign({}, element)) : new CamelDefinition_1.CircuitBreakerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.faultToleranceConfiguration) !== undefined) {
            if (Array.isArray(element.faultToleranceConfiguration)) {
                def.faultToleranceConfiguration = CamelDefinitionYamlStep.readFaultToleranceConfigurationDefinition(element.faultToleranceConfiguration[0]);
            }
            else {
                def.faultToleranceConfiguration = CamelDefinitionYamlStep.readFaultToleranceConfigurationDefinition(element.faultToleranceConfiguration);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.resilience4jConfiguration) !== undefined) {
            if (Array.isArray(element.resilience4jConfiguration)) {
                def.resilience4jConfiguration = CamelDefinitionYamlStep.readResilience4jConfigurationDefinition(element.resilience4jConfiguration[0]);
            }
            else {
                def.resilience4jConfiguration = CamelDefinitionYamlStep.readResilience4jConfigurationDefinition(element.resilience4jConfiguration);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.onFallback) !== undefined) {
            if (Array.isArray(element.onFallback)) {
                def.onFallback = CamelDefinitionYamlStep.readOnFallbackDefinition(element.onFallback[0]);
            }
            else {
                def.onFallback = CamelDefinitionYamlStep.readOnFallbackDefinition(element.onFallback);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readClaimCheckDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ClaimCheckDefinition(__assign({}, element)) : new CamelDefinition_1.ClaimCheckDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readContextScanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ContextScanDefinition(__assign({}, element)) : new CamelDefinition_1.ContextScanDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readConvertBodyDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { type: element };
        var def = element ? new CamelDefinition_1.ConvertBodyDefinition(__assign({}, element)) : new CamelDefinition_1.ConvertBodyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readDataFormatDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readDelayDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DelayDefinition(__assign({}, element)) : new CamelDefinition_1.DelayDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readDescriptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DescriptionDefinition(__assign({}, element)) : new CamelDefinition_1.DescriptionDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readDynamicRouterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DynamicRouterDefinition(__assign({}, element)) : new CamelDefinition_1.DynamicRouterDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readEnrichDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EnrichDefinition(__assign({}, element)) : new CamelDefinition_1.EnrichDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.ErrorHandlerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.deadLetterChannel) !== undefined) {
            if (Array.isArray(element.deadLetterChannel)) {
                def.deadLetterChannel = CamelDefinitionYamlStep.readDeadLetterChannelDefinition(element.deadLetterChannel[0]);
            }
            else {
                def.deadLetterChannel = CamelDefinitionYamlStep.readDeadLetterChannelDefinition(element.deadLetterChannel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.noErrorHandler) !== undefined) {
            if (Array.isArray(element.noErrorHandler)) {
                def.noErrorHandler = CamelDefinitionYamlStep.readNoErrorHandlerDefinition(element.noErrorHandler[0]);
            }
            else {
                def.noErrorHandler = CamelDefinitionYamlStep.readNoErrorHandlerDefinition(element.noErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jtaTransactionErrorHandler) !== undefined) {
            if (Array.isArray(element.jtaTransactionErrorHandler)) {
                def.jtaTransactionErrorHandler = CamelDefinitionYamlStep.readJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler[0]);
            }
            else {
                def.jtaTransactionErrorHandler = CamelDefinitionYamlStep.readJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultErrorHandler) !== undefined) {
            if (Array.isArray(element.defaultErrorHandler)) {
                def.defaultErrorHandler = CamelDefinitionYamlStep.readDefaultErrorHandlerDefinition(element.defaultErrorHandler[0]);
            }
            else {
                def.defaultErrorHandler = CamelDefinitionYamlStep.readDefaultErrorHandlerDefinition(element.defaultErrorHandler);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.springTransactionErrorHandler) !== undefined) {
            if (Array.isArray(element.springTransactionErrorHandler)) {
                def.springTransactionErrorHandler = CamelDefinitionYamlStep.readSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler[0]);
            }
            else {
                def.springTransactionErrorHandler = CamelDefinitionYamlStep.readSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readExpressionSubElementDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ExpressionSubElementDefinition(__assign({}, element)) : new CamelDefinition_1.ExpressionSubElementDefinition();
        if ((element === null || element === void 0 ? void 0 : element.ognl) !== undefined) {
            if (Array.isArray(element.ognl)) {
                def.ognl = CamelDefinitionYamlStep.readOgnlExpression(element.ognl[0]);
            }
            else {
                def.ognl = CamelDefinitionYamlStep.readOgnlExpression(element.ognl);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.python) !== undefined) {
            if (Array.isArray(element.python)) {
                def.python = CamelDefinitionYamlStep.readPythonExpression(element.python[0]);
            }
            else {
                def.python = CamelDefinitionYamlStep.readPythonExpression(element.python);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.constant) !== undefined) {
            if (Array.isArray(element.constant)) {
                def.constant = CamelDefinitionYamlStep.readConstantExpression(element.constant[0]);
            }
            else {
                def.constant = CamelDefinitionYamlStep.readConstantExpression(element.constant);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mvel) !== undefined) {
            if (Array.isArray(element.mvel)) {
                def.mvel = CamelDefinitionYamlStep.readMvelExpression(element.mvel[0]);
            }
            else {
                def.mvel = CamelDefinitionYamlStep.readMvelExpression(element.mvel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.method) !== undefined) {
            if (Array.isArray(element.method)) {
                def.method = CamelDefinitionYamlStep.readMethodCallExpression(element.method[0]);
            }
            else {
                def.method = CamelDefinitionYamlStep.readMethodCallExpression(element.method);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xquery) !== undefined) {
            if (Array.isArray(element.xquery)) {
                def.xquery = CamelDefinitionYamlStep.readXQueryExpression(element.xquery[0]);
            }
            else {
                def.xquery = CamelDefinitionYamlStep.readXQueryExpression(element.xquery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.datasonnet) !== undefined) {
            if (Array.isArray(element.datasonnet)) {
                def.datasonnet = CamelDefinitionYamlStep.readDatasonnetExpression(element.datasonnet[0]);
            }
            else {
                def.datasonnet = CamelDefinitionYamlStep.readDatasonnetExpression(element.datasonnet);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jq) !== undefined) {
            if (Array.isArray(element.jq)) {
                def.jq = CamelDefinitionYamlStep.readJqExpression(element.jq[0]);
            }
            else {
                def.jq = CamelDefinitionYamlStep.readJqExpression(element.jq);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.js) !== undefined) {
            if (Array.isArray(element.js)) {
                def.js = CamelDefinitionYamlStep.readJavaScriptExpression(element.js[0]);
            }
            else {
                def.js = CamelDefinitionYamlStep.readJavaScriptExpression(element.js);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.language) !== undefined) {
            if (Array.isArray(element.language)) {
                def.language = CamelDefinitionYamlStep.readLanguageExpression(element.language[0]);
            }
            else {
                def.language = CamelDefinitionYamlStep.readLanguageExpression(element.language);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.simple) !== undefined) {
            if (Array.isArray(element.simple)) {
                def.simple = CamelDefinitionYamlStep.readSimpleExpression(element.simple[0]);
            }
            else {
                def.simple = CamelDefinitionYamlStep.readSimpleExpression(element.simple);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7terser) !== undefined) {
            if (Array.isArray(element.hl7terser)) {
                def.hl7terser = CamelDefinitionYamlStep.readHl7TerserExpression(element.hl7terser[0]);
            }
            else {
                def.hl7terser = CamelDefinitionYamlStep.readHl7TerserExpression(element.hl7terser);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tokenize) !== undefined) {
            if (Array.isArray(element.tokenize)) {
                def.tokenize = CamelDefinitionYamlStep.readTokenizerExpression(element.tokenize[0]);
            }
            else {
                def.tokenize = CamelDefinitionYamlStep.readTokenizerExpression(element.tokenize);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.spel) !== undefined) {
            if (Array.isArray(element.spel)) {
                def.spel = CamelDefinitionYamlStep.readSpELExpression(element.spel[0]);
            }
            else {
                def.spel = CamelDefinitionYamlStep.readSpELExpression(element.spel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ref) !== undefined) {
            if (Array.isArray(element.ref)) {
                def.ref = CamelDefinitionYamlStep.readRefExpression(element.ref[0]);
            }
            else {
                def.ref = CamelDefinitionYamlStep.readRefExpression(element.ref);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xpath) !== undefined) {
            if (Array.isArray(element.xpath)) {
                def.xpath = CamelDefinitionYamlStep.readXPathExpression(element.xpath[0]);
            }
            else {
                def.xpath = CamelDefinitionYamlStep.readXPathExpression(element.xpath);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.groovy) !== undefined) {
            if (Array.isArray(element.groovy)) {
                def.groovy = CamelDefinitionYamlStep.readGroovyExpression(element.groovy[0]);
            }
            else {
                def.groovy = CamelDefinitionYamlStep.readGroovyExpression(element.groovy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csimple) !== undefined) {
            if (Array.isArray(element.csimple)) {
                def.csimple = CamelDefinitionYamlStep.readCSimpleExpression(element.csimple[0]);
            }
            else {
                def.csimple = CamelDefinitionYamlStep.readCSimpleExpression(element.csimple);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.exchangeProperty) !== undefined) {
            if (Array.isArray(element.exchangeProperty)) {
                def.exchangeProperty = CamelDefinitionYamlStep.readExchangePropertyExpression(element.exchangeProperty[0]);
            }
            else {
                def.exchangeProperty = CamelDefinitionYamlStep.readExchangePropertyExpression(element.exchangeProperty);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonpath) !== undefined) {
            if (Array.isArray(element.jsonpath)) {
                def.jsonpath = CamelDefinitionYamlStep.readJsonPathExpression(element.jsonpath[0]);
            }
            else {
                def.jsonpath = CamelDefinitionYamlStep.readJsonPathExpression(element.jsonpath);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.header) !== undefined) {
            if (Array.isArray(element.header)) {
                def.header = CamelDefinitionYamlStep.readHeaderExpression(element.header[0]);
            }
            else {
                def.header = CamelDefinitionYamlStep.readHeaderExpression(element.header);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.joor) !== undefined) {
            if (Array.isArray(element.joor)) {
                def.joor = CamelDefinitionYamlStep.readJoorExpression(element.joor[0]);
            }
            else {
                def.joor = CamelDefinitionYamlStep.readJoorExpression(element.joor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xtokenize) !== undefined) {
            if (Array.isArray(element.xtokenize)) {
                def.xtokenize = CamelDefinitionYamlStep.readXMLTokenizerExpression(element.xtokenize[0]);
            }
            else {
                def.xtokenize = CamelDefinitionYamlStep.readXMLTokenizerExpression(element.xtokenize);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readFaultToleranceConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FaultToleranceConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.FaultToleranceConfigurationDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readFilterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FilterDefinition(__assign({}, element)) : new CamelDefinition_1.FilterDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readFinallyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FinallyDefinition(__assign({}, element)) : new CamelDefinition_1.FinallyDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readFromDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.FromDefinition(__assign({}, element)) : new CamelDefinition_1.FromDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readGlobalOptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GlobalOptionDefinition(__assign({}, element)) : new CamelDefinition_1.GlobalOptionDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readGlobalOptionsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GlobalOptionsDefinition(__assign({}, element)) : new CamelDefinition_1.GlobalOptionsDefinition();
        def.globalOption = element && (element === null || element === void 0 ? void 0 : element.globalOption) ? element === null || element === void 0 ? void 0 : element.globalOption.map(function (x) { return CamelDefinitionYamlStep.readGlobalOptionDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readIdempotentConsumerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.IdempotentConsumerDefinition(__assign({}, element)) : new CamelDefinition_1.IdempotentConsumerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readInOnlyDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.InOnlyDefinition(__assign({}, element)) : new CamelDefinition_1.InOnlyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readInOutDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.InOutDefinition(__assign({}, element)) : new CamelDefinition_1.InOutDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readInputTypeDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InputTypeDefinition(__assign({}, element)) : new CamelDefinition_1.InputTypeDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readInterceptDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InterceptDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readInterceptFromDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InterceptFromDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptFromDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readInterceptSendToEndpointDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.InterceptSendToEndpointDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptSendToEndpointDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readKameletDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { name: element };
        var def = element ? new CamelDefinition_1.KameletDefinition(__assign({}, element)) : new CamelDefinition_1.KameletDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readLoadBalanceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.LoadBalanceDefinition(__assign({}, element)) : new CamelDefinition_1.LoadBalanceDefinition();
        if ((element === null || element === void 0 ? void 0 : element.random) !== undefined) {
            if (Array.isArray(element.random)) {
                def.random = CamelDefinitionYamlStep.readRandomLoadBalancerDefinition(element.random[0]);
            }
            else {
                def.random = CamelDefinitionYamlStep.readRandomLoadBalancerDefinition(element.random);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customLoadBalancer) !== undefined) {
            if (Array.isArray(element.customLoadBalancer)) {
                def.customLoadBalancer = CamelDefinitionYamlStep.readCustomLoadBalancerDefinition(element.customLoadBalancer[0]);
            }
            else {
                def.customLoadBalancer = CamelDefinitionYamlStep.readCustomLoadBalancerDefinition(element.customLoadBalancer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.failover) !== undefined) {
            if (Array.isArray(element.failover)) {
                def.failover = CamelDefinitionYamlStep.readFailoverLoadBalancerDefinition(element.failover[0]);
            }
            else {
                def.failover = CamelDefinitionYamlStep.readFailoverLoadBalancerDefinition(element.failover);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.sticky) !== undefined) {
            if (Array.isArray(element.sticky)) {
                def.sticky = CamelDefinitionYamlStep.readStickyLoadBalancerDefinition(element.sticky[0]);
            }
            else {
                def.sticky = CamelDefinitionYamlStep.readStickyLoadBalancerDefinition(element.sticky);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.topic) !== undefined) {
            if (Array.isArray(element.topic)) {
                def.topic = CamelDefinitionYamlStep.readTopicLoadBalancerDefinition(element.topic[0]);
            }
            else {
                def.topic = CamelDefinitionYamlStep.readTopicLoadBalancerDefinition(element.topic);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        if ((element === null || element === void 0 ? void 0 : element.weighted) !== undefined) {
            if (Array.isArray(element.weighted)) {
                def.weighted = CamelDefinitionYamlStep.readWeightedLoadBalancerDefinition(element.weighted[0]);
            }
            else {
                def.weighted = CamelDefinitionYamlStep.readWeightedLoadBalancerDefinition(element.weighted);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.roundRobin) !== undefined) {
            if (Array.isArray(element.roundRobin)) {
                def.roundRobin = CamelDefinitionYamlStep.readRoundRobinLoadBalancerDefinition(element.roundRobin[0]);
            }
            else {
                def.roundRobin = CamelDefinitionYamlStep.readRoundRobinLoadBalancerDefinition(element.roundRobin);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readLogDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { message: element };
        var def = element ? new CamelDefinition_1.LogDefinition(__assign({}, element)) : new CamelDefinition_1.LogDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readLoopDefinition = function (element) {
        var def = element ? new CamelDefinition_1.LoopDefinition(__assign({}, element)) : new CamelDefinition_1.LoopDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readMarshalDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MarshalDefinition(__assign({}, element)) : new CamelDefinition_1.MarshalDefinition();
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            if (Array.isArray(element.univocityCsv)) {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv[0]);
            }
            else {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            if (Array.isArray(element.protobuf)) {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf[0]);
            }
            else {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            if (Array.isArray(element.tarFile)) {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile[0]);
            }
            else {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            if (Array.isArray(element.tidyMarkup)) {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup[0]);
            }
            else {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            if (Array.isArray(element.csv)) {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv[0]);
            }
            else {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            if (Array.isArray(element.base64)) {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64[0]);
            }
            else {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            if (Array.isArray(element.zipDeflater)) {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater[0]);
            }
            else {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            if (Array.isArray(element.bindy)) {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy[0]);
            }
            else {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            if (Array.isArray(element.syslog)) {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog[0]);
            }
            else {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            if (Array.isArray(element.zipFile)) {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile[0]);
            }
            else {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            if (Array.isArray(element.jaxb)) {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb[0]);
            }
            else {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            if (Array.isArray(element.rss)) {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss[0]);
            }
            else {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            if (Array.isArray(element.mimeMultipart)) {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart[0]);
            }
            else {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            if (Array.isArray(element.asn1)) {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1[0]);
            }
            else {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            if (Array.isArray(element.pgp)) {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp[0]);
            }
            else {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            if (Array.isArray(element.thrift)) {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift[0]);
            }
            else {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            if (Array.isArray(element.json)) {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json[0]);
            }
            else {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            if (Array.isArray(element.lzf)) {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf[0]);
            }
            else {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            if (Array.isArray(element.fhirXml)) {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml[0]);
            }
            else {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            if (Array.isArray(element.barcode)) {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode[0]);
            }
            else {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            if (Array.isArray(element.avro)) {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro[0]);
            }
            else {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            if (Array.isArray(element.yaml)) {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml[0]);
            }
            else {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            if (Array.isArray(element.fhirJson)) {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson[0]);
            }
            else {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            if (Array.isArray(element.any23)) {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23[0]);
            }
            else {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            if (Array.isArray(element.custom)) {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom[0]);
            }
            else {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            if (Array.isArray(element.flatpack)) {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack[0]);
            }
            else {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            if (Array.isArray(element.swiftMx)) {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx[0]);
            }
            else {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            if (Array.isArray(element.cbor)) {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor[0]);
            }
            else {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            if (Array.isArray(element.crypto)) {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto[0]);
            }
            else {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            if (Array.isArray(element.swiftMt)) {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt[0]);
            }
            else {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            if (Array.isArray(element.univocityTsv)) {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv[0]);
            }
            else {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            if (Array.isArray(element.hl7)) {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7[0]);
            }
            else {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            if (Array.isArray(element.jsonApi)) {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi[0]);
            }
            else {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            if (Array.isArray(element.xmlSecurity)) {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity[0]);
            }
            else {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            if (Array.isArray(element.ical)) {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical[0]);
            }
            else {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            if (Array.isArray(element.univocityFixed)) {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed[0]);
            }
            else {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            if (Array.isArray(element.jacksonXml)) {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml[0]);
            }
            else {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            if (Array.isArray(element.grok)) {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok[0]);
            }
            else {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            if (Array.isArray(element.xstream)) {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream[0]);
            }
            else {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            if (Array.isArray(element.gzipDeflater)) {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater[0]);
            }
            else {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            if (Array.isArray(element.soap)) {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap[0]);
            }
            else {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readMulticastDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MulticastDefinition(__assign({}, element)) : new CamelDefinition_1.MulticastDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOnCompletionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnCompletionDefinition(__assign({}, element)) : new CamelDefinition_1.OnCompletionDefinition();
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            if (Array.isArray(element.onWhen)) {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen[0]);
            }
            else {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOnExceptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnExceptionDefinition(__assign({}, element)) : new CamelDefinition_1.OnExceptionDefinition();
        if ((element === null || element === void 0 ? void 0 : element.retryWhile) !== undefined) {
            if (Array.isArray(element.retryWhile)) {
                def.retryWhile = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.retryWhile[0]);
            }
            else {
                def.retryWhile = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.retryWhile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            if (Array.isArray(element.redeliveryPolicy)) {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy[0]);
            }
            else {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.handled) !== undefined) {
            if (Array.isArray(element.handled)) {
                def.handled = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.handled[0]);
            }
            else {
                def.handled = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.handled);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            if (Array.isArray(element.onWhen)) {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen[0]);
            }
            else {
                def.onWhen = CamelDefinitionYamlStep.readWhenDefinition(element.onWhen);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.continued) !== undefined) {
            if (Array.isArray(element.continued)) {
                def.continued = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.continued[0]);
            }
            else {
                def.continued = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.continued);
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOnFallbackDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnFallbackDefinition(__assign({}, element)) : new CamelDefinition_1.OnFallbackDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOptimisticLockRetryPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OptimisticLockRetryPolicyDefinition(__assign({}, element)) : new CamelDefinition_1.OptimisticLockRetryPolicyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readOtherwiseDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OtherwiseDefinition(__assign({}, element)) : new CamelDefinition_1.OtherwiseDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOutputDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputDefinition(__assign({}, element)) : new CamelDefinition_1.OutputDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readOutputTypeDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputTypeDefinition(__assign({}, element)) : new CamelDefinition_1.OutputTypeDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPackageScanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PackageScanDefinition(__assign({}, element)) : new CamelDefinition_1.PackageScanDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPausableDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PausableDefinition(__assign({}, element)) : new CamelDefinition_1.PausableDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPipelineDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PipelineDefinition(__assign({}, element)) : new CamelDefinition_1.PipelineDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PolicyDefinition(__assign({}, element)) : new CamelDefinition_1.PolicyDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readPollEnrichDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PollEnrichDefinition(__assign({}, element)) : new CamelDefinition_1.PollEnrichDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readProcessDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ProcessDefinition(__assign({}, element)) : new CamelDefinition_1.ProcessDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PropertyDefinition(__assign({}, element)) : new CamelDefinition_1.PropertyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPropertyExpressionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PropertyExpressionDefinition(__assign({}, element)) : new CamelDefinition_1.PropertyExpressionDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readRecipientListDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RecipientListDefinition(__assign({}, element)) : new CamelDefinition_1.RecipientListDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readRedeliveryPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RedeliveryPolicyDefinition(__assign({}, element)) : new CamelDefinition_1.RedeliveryPolicyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRemoveHeaderDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { name: element };
        var def = element ? new CamelDefinition_1.RemoveHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.RemoveHeaderDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRemoveHeadersDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { pattern: element };
        var def = element ? new CamelDefinition_1.RemoveHeadersDefinition(__assign({}, element)) : new CamelDefinition_1.RemoveHeadersDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRemovePropertiesDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { pattern: element };
        var def = element ? new CamelDefinition_1.RemovePropertiesDefinition(__assign({}, element)) : new CamelDefinition_1.RemovePropertiesDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRemovePropertyDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { name: element };
        var def = element ? new CamelDefinition_1.RemovePropertyDefinition(__assign({}, element)) : new CamelDefinition_1.RemovePropertyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readResequenceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResequenceDefinition(__assign({}, element)) : new CamelDefinition_1.ResequenceDefinition();
        if ((element === null || element === void 0 ? void 0 : element.streamConfig) !== undefined) {
            if (Array.isArray(element.streamConfig)) {
                def.streamConfig = CamelDefinitionYamlStep.readStreamResequencerConfig(element.streamConfig[0]);
            }
            else {
                def.streamConfig = CamelDefinitionYamlStep.readStreamResequencerConfig(element.streamConfig);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        if ((element === null || element === void 0 ? void 0 : element.batchConfig) !== undefined) {
            if (Array.isArray(element.batchConfig)) {
                def.batchConfig = CamelDefinitionYamlStep.readBatchResequencerConfig(element.batchConfig[0]);
            }
            else {
                def.batchConfig = CamelDefinitionYamlStep.readBatchResequencerConfig(element.batchConfig);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readResilience4jConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.Resilience4jConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.Resilience4jConfigurationDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRestContextRefDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.RestContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RestContextRefDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readResumableDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResumableDefinition(__assign({}, element)) : new CamelDefinition_1.ResumableDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRollbackDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RollbackDefinition(__assign({}, element)) : new CamelDefinition_1.RollbackDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRouteBuilderDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.RouteBuilderDefinition(__assign({}, element)) : new CamelDefinition_1.RouteBuilderDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRouteConfigurationContextRefDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.RouteConfigurationContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RouteConfigurationContextRefDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRouteConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.RouteConfigurationDefinition();
        def.onCompletion = element && (element === null || element === void 0 ? void 0 : element.onCompletion) ? element === null || element === void 0 ? void 0 : element.onCompletion.map(function (x) { return CamelDefinitionYamlStep.readOnCompletionDefinition(x); }) : [];
        def.interceptSendToEndpoint = element && (element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint) ? element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint.map(function (x) { return CamelDefinitionYamlStep.readInterceptSendToEndpointDefinition(x); }) : [];
        def.intercept = element && (element === null || element === void 0 ? void 0 : element.intercept) ? element === null || element === void 0 ? void 0 : element.intercept.map(function (x) { return CamelDefinitionYamlStep.readInterceptDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.errorHandler) !== undefined) {
            if (Array.isArray(element.errorHandler)) {
                def.errorHandler = CamelDefinitionYamlStep.readErrorHandlerDefinition(element.errorHandler[0]);
            }
            else {
                def.errorHandler = CamelDefinitionYamlStep.readErrorHandlerDefinition(element.errorHandler);
            }
        }
        def.onException = element && (element === null || element === void 0 ? void 0 : element.onException) ? element === null || element === void 0 ? void 0 : element.onException.map(function (x) { return CamelDefinitionYamlStep.readOnExceptionDefinition(x); }) : [];
        def.interceptFrom = element && (element === null || element === void 0 ? void 0 : element.interceptFrom) ? element === null || element === void 0 ? void 0 : element.interceptFrom.map(function (x) { return CamelDefinitionYamlStep.readInterceptFromDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRouteContextRefDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.RouteContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RouteContextRefDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRouteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteDefinition(__assign({}, element)) : new CamelDefinition_1.RouteDefinition();
        if ((element === null || element === void 0 ? void 0 : element.from) !== undefined) {
            if (Array.isArray(element.from)) {
                def.from = CamelDefinitionYamlStep.readFromDefinition(element.from[0]);
            }
            else {
                def.from = CamelDefinitionYamlStep.readFromDefinition(element.from);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readRouteTemplateBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateBeanDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateBeanDefinition();
        def.property = element && (element === null || element === void 0 ? void 0 : element.property) ? element === null || element === void 0 ? void 0 : element.property.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRouteTemplateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateDefinition();
        if ((element === null || element === void 0 ? void 0 : element.route) !== undefined) {
            if (Array.isArray(element.route)) {
                def.route = CamelDefinitionYamlStep.readRouteDefinition(element.route[0]);
            }
            else {
                def.route = CamelDefinitionYamlStep.readRouteDefinition(element.route);
            }
        }
        def.beans = element && (element === null || element === void 0 ? void 0 : element.beans) ? element === null || element === void 0 ? void 0 : element.beans.map(function (x) { return CamelDefinitionYamlStep.readNamedBeanDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.from) !== undefined) {
            if (Array.isArray(element.from)) {
                def.from = CamelDefinitionYamlStep.readFromDefinition(element.from[0]);
            }
            else {
                def.from = CamelDefinitionYamlStep.readFromDefinition(element.from);
            }
        }
        def.parameters = element && (element === null || element === void 0 ? void 0 : element.parameters) ? element === null || element === void 0 ? void 0 : element.parameters.map(function (x) { return CamelDefinitionYamlStep.readRouteTemplateParameterDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRouteTemplateParameterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateParameterDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateParameterDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRoutingSlipDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RoutingSlipDefinition(__assign({}, element)) : new CamelDefinition_1.RoutingSlipDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSagaActionUriDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.SagaActionUriDefinition(__assign({}, element)) : new CamelDefinition_1.SagaActionUriDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readSagaDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SagaDefinition(__assign({}, element)) : new CamelDefinition_1.SagaDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        def.option = element && (element === null || element === void 0 ? void 0 : element.option) ? element === null || element === void 0 ? void 0 : element.option.map(function (x) { return CamelDefinitionYamlStep.readPropertyExpressionDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readSamplingDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SamplingDefinition(__assign({}, element)) : new CamelDefinition_1.SamplingDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readScriptDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ScriptDefinition(__assign({}, element)) : new CamelDefinition_1.ScriptDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSetBodyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetBodyDefinition(__assign({}, element)) : new CamelDefinition_1.SetBodyDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSetExchangePatternDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { pattern: element };
        var def = element ? new CamelDefinition_1.SetExchangePatternDefinition(__assign({}, element)) : new CamelDefinition_1.SetExchangePatternDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readSetHeaderDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.SetHeaderDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSetPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetPropertyDefinition(__assign({}, element)) : new CamelDefinition_1.SetPropertyDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSortDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SortDefinition(__assign({}, element)) : new CamelDefinition_1.SortDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readSplitDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SplitDefinition(__assign({}, element)) : new CamelDefinition_1.SplitDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readStepDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StepDefinition(__assign({}, element)) : new CamelDefinition_1.StepDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readStopDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StopDefinition(__assign({}, element)) : new CamelDefinition_1.StopDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readTemplatedRouteBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteBeanDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteBeanDefinition();
        def.property = element && (element === null || element === void 0 ? void 0 : element.property) ? element === null || element === void 0 ? void 0 : element.property.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readTemplatedRouteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteDefinition();
        def.beans = element && (element === null || element === void 0 ? void 0 : element.beans) ? element === null || element === void 0 ? void 0 : element.beans.map(function (x) { return CamelDefinitionYamlStep.readNamedBeanDefinition(x); }) : [];
        def.parameters = element && (element === null || element === void 0 ? void 0 : element.parameters) ? element === null || element === void 0 ? void 0 : element.parameters.map(function (x) { return CamelDefinitionYamlStep.readTemplatedRouteParameterDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readTemplatedRouteParameterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteParameterDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteParameterDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readThreadPoolProfileDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThreadPoolProfileDefinition(__assign({}, element)) : new CamelDefinition_1.ThreadPoolProfileDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readThreadsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThreadsDefinition(__assign({}, element)) : new CamelDefinition_1.ThreadsDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readThrottleDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThrottleDefinition(__assign({}, element)) : new CamelDefinition_1.ThrottleDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            if (Array.isArray(element.correlationExpression)) {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression[0]);
            }
            else {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readThrowExceptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThrowExceptionDefinition(__assign({}, element)) : new CamelDefinition_1.ThrowExceptionDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readToDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.ToDefinition(__assign({}, element)) : new CamelDefinition_1.ToDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readToDynamicDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { uri: element };
        var def = element ? new CamelDefinition_1.ToDynamicDefinition(__assign({}, element)) : new CamelDefinition_1.ToDynamicDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readTransactedDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransactedDefinition(__assign({}, element)) : new CamelDefinition_1.TransactedDefinition();
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readTransformDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransformDefinition(__assign({}, element)) : new CamelDefinition_1.TransformDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readTryDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TryDefinition(__assign({}, element)) : new CamelDefinition_1.TryDefinition();
        if ((element === null || element === void 0 ? void 0 : element.doFinally) !== undefined) {
            if (Array.isArray(element.doFinally)) {
                def.doFinally = CamelDefinitionYamlStep.readFinallyDefinition(element.doFinally[0]);
            }
            else {
                def.doFinally = CamelDefinitionYamlStep.readFinallyDefinition(element.doFinally);
            }
        }
        def.doCatch = element && (element === null || element === void 0 ? void 0 : element.doCatch) ? element === null || element === void 0 ? void 0 : element.doCatch.map(function (x) { return CamelDefinitionYamlStep.readCatchDefinition(x); }) : [];
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readUnmarshalDefinition = function (element) {
        var def = element ? new CamelDefinition_1.UnmarshalDefinition(__assign({}, element)) : new CamelDefinition_1.UnmarshalDefinition();
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            if (Array.isArray(element.univocityCsv)) {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv[0]);
            }
            else {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            if (Array.isArray(element.protobuf)) {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf[0]);
            }
            else {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            if (Array.isArray(element.tarFile)) {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile[0]);
            }
            else {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            if (Array.isArray(element.tidyMarkup)) {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup[0]);
            }
            else {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            if (Array.isArray(element.csv)) {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv[0]);
            }
            else {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            if (Array.isArray(element.base64)) {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64[0]);
            }
            else {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            if (Array.isArray(element.zipDeflater)) {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater[0]);
            }
            else {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            if (Array.isArray(element.bindy)) {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy[0]);
            }
            else {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            if (Array.isArray(element.syslog)) {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog[0]);
            }
            else {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            if (Array.isArray(element.zipFile)) {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile[0]);
            }
            else {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            if (Array.isArray(element.jaxb)) {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb[0]);
            }
            else {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            if (Array.isArray(element.rss)) {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss[0]);
            }
            else {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            if (Array.isArray(element.mimeMultipart)) {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart[0]);
            }
            else {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            if (Array.isArray(element.asn1)) {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1[0]);
            }
            else {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            if (Array.isArray(element.pgp)) {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp[0]);
            }
            else {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            if (Array.isArray(element.thrift)) {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift[0]);
            }
            else {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            if (Array.isArray(element.json)) {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json[0]);
            }
            else {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            if (Array.isArray(element.lzf)) {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf[0]);
            }
            else {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            if (Array.isArray(element.fhirXml)) {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml[0]);
            }
            else {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            if (Array.isArray(element.barcode)) {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode[0]);
            }
            else {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            if (Array.isArray(element.avro)) {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro[0]);
            }
            else {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            if (Array.isArray(element.yaml)) {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml[0]);
            }
            else {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            if (Array.isArray(element.fhirJson)) {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson[0]);
            }
            else {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            if (Array.isArray(element.any23)) {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23[0]);
            }
            else {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            if (Array.isArray(element.custom)) {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom[0]);
            }
            else {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            if (Array.isArray(element.flatpack)) {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack[0]);
            }
            else {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            if (Array.isArray(element.swiftMx)) {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx[0]);
            }
            else {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            if (Array.isArray(element.cbor)) {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor[0]);
            }
            else {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            if (Array.isArray(element.crypto)) {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto[0]);
            }
            else {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            if (Array.isArray(element.swiftMt)) {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt[0]);
            }
            else {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            if (Array.isArray(element.univocityTsv)) {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv[0]);
            }
            else {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            if (Array.isArray(element.hl7)) {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7[0]);
            }
            else {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            if (Array.isArray(element.jsonApi)) {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi[0]);
            }
            else {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            if (Array.isArray(element.xmlSecurity)) {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity[0]);
            }
            else {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            if (Array.isArray(element.ical)) {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical[0]);
            }
            else {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            if (Array.isArray(element.univocityFixed)) {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed[0]);
            }
            else {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            if (Array.isArray(element.jacksonXml)) {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml[0]);
            }
            else {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            if (Array.isArray(element.grok)) {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok[0]);
            }
            else {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            if (Array.isArray(element.xstream)) {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream[0]);
            }
            else {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            if (Array.isArray(element.gzipDeflater)) {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater[0]);
            }
            else {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            if (Array.isArray(element.soap)) {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap[0]);
            }
            else {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readValidateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValidateDefinition(__assign({}, element)) : new CamelDefinition_1.ValidateDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readValueDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValueDefinition(__assign({}, element)) : new CamelDefinition_1.ValueDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readWhenDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WhenDefinition(__assign({}, element)) : new CamelDefinition_1.WhenDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readWhenSkipSendToEndpointDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WhenSkipSendToEndpointDefinition(__assign({}, element)) : new CamelDefinition_1.WhenSkipSendToEndpointDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.steps = CamelDefinitionYamlStep.readSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionYamlStep.readWireTapDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WireTapDefinition(__assign({}, element)) : new CamelDefinition_1.WireTapDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.BlacklistServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.BlacklistServiceCallServiceFilterConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CachingServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.CachingServiceCallServiceDiscoveryConfiguration();
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            if (Array.isArray(element.dnsServiceDiscovery)) {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery[0]);
            }
            else {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            if (Array.isArray(element.kubernetesServiceDiscovery)) {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery[0]);
            }
            else {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            if (Array.isArray(element.consulServiceDiscovery)) {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery[0]);
            }
            else {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            if (Array.isArray(element.staticServiceDiscovery)) {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery[0]);
            }
            else {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            if (Array.isArray(element.combinedServiceDiscovery)) {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery[0]);
            }
            else {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
            }
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CombinedServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.CombinedServiceCallServiceDiscoveryConfiguration();
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            if (Array.isArray(element.cachingServiceDiscovery)) {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery[0]);
            }
            else {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            if (Array.isArray(element.dnsServiceDiscovery)) {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery[0]);
            }
            else {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            if (Array.isArray(element.kubernetesServiceDiscovery)) {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery[0]);
            }
            else {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            if (Array.isArray(element.consulServiceDiscovery)) {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery[0]);
            }
            else {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            if (Array.isArray(element.staticServiceDiscovery)) {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery[0]);
            }
            else {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
            }
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCombinedServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CombinedServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.CombinedServiceCallServiceFilterConfiguration();
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            if (Array.isArray(element.blacklistServiceFilter)) {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter[0]);
            }
            else {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            if (Array.isArray(element.passThroughServiceFilter)) {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter[0]);
            }
            else {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            if (Array.isArray(element.healthyServiceFilter)) {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter[0]);
            }
            else {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            if (Array.isArray(element.customServiceFilter)) {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter[0]);
            }
            else {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
            }
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ConsulServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ConsulServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CustomServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.CustomServiceCallServiceFilterConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readDefaultServiceCallServiceLoadBalancerConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.DefaultServiceCallServiceLoadBalancerConfiguration(__assign({}, element)) : new CamelDefinition_1.DefaultServiceCallServiceLoadBalancerConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.DnsServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.DnsServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.HealthyServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.HealthyServiceCallServiceFilterConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.KubernetesServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.KubernetesServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.PassThroughServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.PassThroughServiceCallServiceFilterConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.ServiceCallConfigurationDefinition();
        if ((element === null || element === void 0 ? void 0 : element.defaultLoadBalancer) !== undefined) {
            if (Array.isArray(element.defaultLoadBalancer)) {
                def.defaultLoadBalancer = CamelDefinitionYamlStep.readDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer[0]);
            }
            else {
                def.defaultLoadBalancer = CamelDefinitionYamlStep.readDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            if (Array.isArray(element.expression)) {
                def.expression = CamelDefinitionYamlStep.readServiceCallExpressionConfiguration(element.expression[0]);
            }
            else {
                def.expression = CamelDefinitionYamlStep.readServiceCallExpressionConfiguration(element.expression);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            if (Array.isArray(element.kubernetesServiceDiscovery)) {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery[0]);
            }
            else {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            if (Array.isArray(element.customServiceFilter)) {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter[0]);
            }
            else {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zookeeperServiceDiscovery) !== undefined) {
            if (Array.isArray(element.zookeeperServiceDiscovery)) {
                def.zookeeperServiceDiscovery = CamelDefinitionYamlStep.readZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery[0]);
            }
            else {
                def.zookeeperServiceDiscovery = CamelDefinitionYamlStep.readZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            if (Array.isArray(element.blacklistServiceFilter)) {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter[0]);
            }
            else {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            if (Array.isArray(element.passThroughServiceFilter)) {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter[0]);
            }
            else {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            if (Array.isArray(element.cachingServiceDiscovery)) {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery[0]);
            }
            else {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            if (Array.isArray(element.dnsServiceDiscovery)) {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery[0]);
            }
            else {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            if (Array.isArray(element.healthyServiceFilter)) {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter[0]);
            }
            else {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceFilter) !== undefined) {
            if (Array.isArray(element.combinedServiceFilter)) {
                def.combinedServiceFilter = CamelDefinitionYamlStep.readCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter[0]);
            }
            else {
                def.combinedServiceFilter = CamelDefinitionYamlStep.readCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            if (Array.isArray(element.consulServiceDiscovery)) {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery[0]);
            }
            else {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            if (Array.isArray(element.staticServiceDiscovery)) {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery[0]);
            }
            else {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            if (Array.isArray(element.combinedServiceDiscovery)) {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery[0]);
            }
            else {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { name: element };
        var def = element ? new CamelDefinition_1.ServiceCallDefinition(__assign({}, element)) : new CamelDefinition_1.ServiceCallDefinition();
        if ((element === null || element === void 0 ? void 0 : element.defaultLoadBalancer) !== undefined) {
            if (Array.isArray(element.defaultLoadBalancer)) {
                def.defaultLoadBalancer = CamelDefinitionYamlStep.readDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer[0]);
            }
            else {
                def.defaultLoadBalancer = CamelDefinitionYamlStep.readDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            if (Array.isArray(element.expression)) {
                def.expression = CamelDefinitionYamlStep.readServiceCallExpressionConfiguration(element.expression[0]);
            }
            else {
                def.expression = CamelDefinitionYamlStep.readServiceCallExpressionConfiguration(element.expression);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            if (Array.isArray(element.kubernetesServiceDiscovery)) {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery[0]);
            }
            else {
                def.kubernetesServiceDiscovery = CamelDefinitionYamlStep.readKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            if (Array.isArray(element.customServiceFilter)) {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter[0]);
            }
            else {
                def.customServiceFilter = CamelDefinitionYamlStep.readCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zookeeperServiceDiscovery) !== undefined) {
            if (Array.isArray(element.zookeeperServiceDiscovery)) {
                def.zookeeperServiceDiscovery = CamelDefinitionYamlStep.readZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery[0]);
            }
            else {
                def.zookeeperServiceDiscovery = CamelDefinitionYamlStep.readZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            if (Array.isArray(element.blacklistServiceFilter)) {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter[0]);
            }
            else {
                def.blacklistServiceFilter = CamelDefinitionYamlStep.readBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            if (Array.isArray(element.passThroughServiceFilter)) {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter[0]);
            }
            else {
                def.passThroughServiceFilter = CamelDefinitionYamlStep.readPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            if (Array.isArray(element.cachingServiceDiscovery)) {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery[0]);
            }
            else {
                def.cachingServiceDiscovery = CamelDefinitionYamlStep.readCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            if (Array.isArray(element.dnsServiceDiscovery)) {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery[0]);
            }
            else {
                def.dnsServiceDiscovery = CamelDefinitionYamlStep.readDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            if (Array.isArray(element.healthyServiceFilter)) {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter[0]);
            }
            else {
                def.healthyServiceFilter = CamelDefinitionYamlStep.readHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceFilter) !== undefined) {
            if (Array.isArray(element.combinedServiceFilter)) {
                def.combinedServiceFilter = CamelDefinitionYamlStep.readCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter[0]);
            }
            else {
                def.combinedServiceFilter = CamelDefinitionYamlStep.readCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            if (Array.isArray(element.consulServiceDiscovery)) {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery[0]);
            }
            else {
                def.consulServiceDiscovery = CamelDefinitionYamlStep.readConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            if (Array.isArray(element.staticServiceDiscovery)) {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery[0]);
            }
            else {
                def.staticServiceDiscovery = CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            if (Array.isArray(element.combinedServiceDiscovery)) {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery[0]);
            }
            else {
                def.combinedServiceDiscovery = CamelDefinitionYamlStep.readCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallExpressionConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallExpressionConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallExpressionConfiguration();
        if ((element === null || element === void 0 ? void 0 : element.expressionType) !== undefined) {
            def.expressionType = CamelDefinitionYamlStep.readExpressionDefinition(element.expressionType);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expressionType = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallServiceChooserConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceChooserConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceChooserConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceFilterConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readServiceCallServiceLoadBalancerConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceLoadBalancerConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceLoadBalancerConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readStaticServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.StaticServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.StaticServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readZooKeeperServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ZooKeeperServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ZooKeeperServiceCallServiceDiscoveryConfiguration();
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readBatchResequencerConfig = function (element) {
        var def = element ? new CamelDefinition_1.BatchResequencerConfig(__assign({}, element)) : new CamelDefinition_1.BatchResequencerConfig();
        return def;
    };
    CamelDefinitionYamlStep.readStreamResequencerConfig = function (element) {
        var def = element ? new CamelDefinition_1.StreamResequencerConfig(__assign({}, element)) : new CamelDefinition_1.StreamResequencerConfig();
        return def;
    };
    CamelDefinitionYamlStep.readASN1DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ASN1DataFormat(__assign({}, element)) : new CamelDefinition_1.ASN1DataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readAny23DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.Any23DataFormat(__assign({}, element)) : new CamelDefinition_1.Any23DataFormat();
        def.configuration = element && (element === null || element === void 0 ? void 0 : element.configuration) ? element === null || element === void 0 ? void 0 : element.configuration.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readAvroDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.AvroDataFormat(__assign({}, element)) : new CamelDefinition_1.AvroDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readBarcodeDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.BarcodeDataFormat(__assign({}, element)) : new CamelDefinition_1.BarcodeDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readBase64DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.Base64DataFormat(__assign({}, element)) : new CamelDefinition_1.Base64DataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readBindyDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.BindyDataFormat(__assign({}, element)) : new CamelDefinition_1.BindyDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readCBORDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CBORDataFormat(__assign({}, element)) : new CamelDefinition_1.CBORDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readCryptoDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CryptoDataFormat(__assign({}, element)) : new CamelDefinition_1.CryptoDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readCsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CsvDataFormat(__assign({}, element)) : new CamelDefinition_1.CsvDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readCustomDataFormat = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.CustomDataFormat(__assign({}, element)) : new CamelDefinition_1.CustomDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readDataFormatsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatsDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatsDefinition();
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            if (Array.isArray(element.univocityCsv)) {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv[0]);
            }
            else {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            if (Array.isArray(element.protobuf)) {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf[0]);
            }
            else {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            if (Array.isArray(element.tarFile)) {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile[0]);
            }
            else {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            if (Array.isArray(element.tidyMarkup)) {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup[0]);
            }
            else {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            if (Array.isArray(element.csv)) {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv[0]);
            }
            else {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            if (Array.isArray(element.base64)) {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64[0]);
            }
            else {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            if (Array.isArray(element.zipDeflater)) {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater[0]);
            }
            else {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            if (Array.isArray(element.bindy)) {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy[0]);
            }
            else {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            if (Array.isArray(element.syslog)) {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog[0]);
            }
            else {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            if (Array.isArray(element.zipFile)) {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile[0]);
            }
            else {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            if (Array.isArray(element.jaxb)) {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb[0]);
            }
            else {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            if (Array.isArray(element.rss)) {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss[0]);
            }
            else {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            if (Array.isArray(element.mimeMultipart)) {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart[0]);
            }
            else {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            if (Array.isArray(element.asn1)) {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1[0]);
            }
            else {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            if (Array.isArray(element.pgp)) {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp[0]);
            }
            else {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            if (Array.isArray(element.thrift)) {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift[0]);
            }
            else {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            if (Array.isArray(element.json)) {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json[0]);
            }
            else {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            if (Array.isArray(element.lzf)) {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf[0]);
            }
            else {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            if (Array.isArray(element.fhirXml)) {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml[0]);
            }
            else {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            if (Array.isArray(element.barcode)) {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode[0]);
            }
            else {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            if (Array.isArray(element.avro)) {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro[0]);
            }
            else {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            if (Array.isArray(element.yaml)) {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml[0]);
            }
            else {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            if (Array.isArray(element.fhirJson)) {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson[0]);
            }
            else {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            if (Array.isArray(element.any23)) {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23[0]);
            }
            else {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            if (Array.isArray(element.custom)) {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom[0]);
            }
            else {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            if (Array.isArray(element.flatpack)) {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack[0]);
            }
            else {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            if (Array.isArray(element.swiftMx)) {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx[0]);
            }
            else {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            if (Array.isArray(element.cbor)) {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor[0]);
            }
            else {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            if (Array.isArray(element.crypto)) {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto[0]);
            }
            else {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            if (Array.isArray(element.swiftMt)) {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt[0]);
            }
            else {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            if (Array.isArray(element.univocityTsv)) {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv[0]);
            }
            else {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            if (Array.isArray(element.hl7)) {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7[0]);
            }
            else {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            if (Array.isArray(element.jsonApi)) {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi[0]);
            }
            else {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            if (Array.isArray(element.xmlSecurity)) {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity[0]);
            }
            else {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            if (Array.isArray(element.ical)) {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical[0]);
            }
            else {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            if (Array.isArray(element.univocityFixed)) {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed[0]);
            }
            else {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            if (Array.isArray(element.jacksonXml)) {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml[0]);
            }
            else {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            if (Array.isArray(element.grok)) {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok[0]);
            }
            else {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            if (Array.isArray(element.xstream)) {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream[0]);
            }
            else {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            if (Array.isArray(element.gzipDeflater)) {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater[0]);
            }
            else {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            if (Array.isArray(element.soap)) {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap[0]);
            }
            else {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readFhirJsonDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FhirJsonDataFormat(__assign({}, element)) : new CamelDefinition_1.FhirJsonDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readFhirXmlDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FhirXmlDataFormat(__assign({}, element)) : new CamelDefinition_1.FhirXmlDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readFlatpackDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FlatpackDataFormat(__assign({}, element)) : new CamelDefinition_1.FlatpackDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readGrokDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.GrokDataFormat(__assign({}, element)) : new CamelDefinition_1.GrokDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readGzipDeflaterDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.GzipDeflaterDataFormat(__assign({}, element)) : new CamelDefinition_1.GzipDeflaterDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readHL7DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.HL7DataFormat(__assign({}, element)) : new CamelDefinition_1.HL7DataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readIcalDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.IcalDataFormat(__assign({}, element)) : new CamelDefinition_1.IcalDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readJacksonXMLDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JacksonXMLDataFormat(__assign({}, element)) : new CamelDefinition_1.JacksonXMLDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readJaxbDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JaxbDataFormat(__assign({}, element)) : new CamelDefinition_1.JaxbDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readJsonApiDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JsonApiDataFormat(__assign({}, element)) : new CamelDefinition_1.JsonApiDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readJsonDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JsonDataFormat(__assign({}, element)) : new CamelDefinition_1.JsonDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readLZFDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.LZFDataFormat(__assign({}, element)) : new CamelDefinition_1.LZFDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readMimeMultipartDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.MimeMultipartDataFormat(__assign({}, element)) : new CamelDefinition_1.MimeMultipartDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readPGPDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.PGPDataFormat(__assign({}, element)) : new CamelDefinition_1.PGPDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readProtobufDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ProtobufDataFormat(__assign({}, element)) : new CamelDefinition_1.ProtobufDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readRssDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.RssDataFormat(__assign({}, element)) : new CamelDefinition_1.RssDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readSoapDataFormat = function (element) {
        if (element && typeof element === 'string')
            element = { contextPath: element };
        var def = element ? new CamelDefinition_1.SoapDataFormat(__assign({}, element)) : new CamelDefinition_1.SoapDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readSwiftMtDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SwiftMtDataFormat(__assign({}, element)) : new CamelDefinition_1.SwiftMtDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readSwiftMxDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SwiftMxDataFormat(__assign({}, element)) : new CamelDefinition_1.SwiftMxDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readSyslogDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SyslogDataFormat(__assign({}, element)) : new CamelDefinition_1.SyslogDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readTarFileDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.TarFileDataFormat(__assign({}, element)) : new CamelDefinition_1.TarFileDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readThriftDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ThriftDataFormat(__assign({}, element)) : new CamelDefinition_1.ThriftDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readTidyMarkupDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.TidyMarkupDataFormat(__assign({}, element)) : new CamelDefinition_1.TidyMarkupDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readUniVocityCsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityCsvDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityCsvDataFormat();
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionYamlStep.readUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readUniVocityFixedDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityFixedDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityFixedDataFormat();
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionYamlStep.readUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readUniVocityHeader = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityHeader(__assign({}, element)) : new CamelDefinition_1.UniVocityHeader();
        return def;
    };
    CamelDefinitionYamlStep.readUniVocityTsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityTsvDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityTsvDataFormat();
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionYamlStep.readUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readXMLSecurityDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.XMLSecurityDataFormat(__assign({}, element)) : new CamelDefinition_1.XMLSecurityDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readXStreamDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.XStreamDataFormat(__assign({}, element)) : new CamelDefinition_1.XStreamDataFormat();
        def.omitFields = element && (element === null || element === void 0 ? void 0 : element.omitFields) ? element === null || element === void 0 ? void 0 : element.omitFields.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        def.aliases = element && (element === null || element === void 0 ? void 0 : element.aliases) ? element === null || element === void 0 ? void 0 : element.aliases.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        def.implicitCollections = element && (element === null || element === void 0 ? void 0 : element.implicitCollections) ? element === null || element === void 0 ? void 0 : element.implicitCollections.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        def.converters = element && (element === null || element === void 0 ? void 0 : element.converters) ? element === null || element === void 0 ? void 0 : element.converters.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readYAMLDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.YAMLDataFormat(__assign({}, element)) : new CamelDefinition_1.YAMLDataFormat();
        def.typeFilter = element && (element === null || element === void 0 ? void 0 : element.typeFilter) ? element === null || element === void 0 ? void 0 : element.typeFilter.map(function (x) { return CamelDefinitionYamlStep.readYAMLTypeFilterDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readYAMLTypeFilterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.YAMLTypeFilterDefinition(__assign({}, element)) : new CamelDefinition_1.YAMLTypeFilterDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readZipDeflaterDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ZipDeflaterDataFormat(__assign({}, element)) : new CamelDefinition_1.ZipDeflaterDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readZipFileDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ZipFileDataFormat(__assign({}, element)) : new CamelDefinition_1.ZipFileDataFormat();
        return def;
    };
    CamelDefinitionYamlStep.readDeadLetterChannelDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DeadLetterChannelDefinition(__assign({}, element)) : new CamelDefinition_1.DeadLetterChannelDefinition();
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            if (Array.isArray(element.redeliveryPolicy)) {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy[0]);
            }
            else {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readDefaultErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DefaultErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.DefaultErrorHandlerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            if (Array.isArray(element.redeliveryPolicy)) {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy[0]);
            }
            else {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readJtaTransactionErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.JtaTransactionErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.JtaTransactionErrorHandlerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            if (Array.isArray(element.redeliveryPolicy)) {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy[0]);
            }
            else {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readNoErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.NoErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.NoErrorHandlerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRefErrorHandlerDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.RefErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.RefErrorHandlerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readSpringTransactionErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SpringTransactionErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.SpringTransactionErrorHandlerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            if (Array.isArray(element.redeliveryPolicy)) {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy[0]);
            }
            else {
                def.redeliveryPolicy = CamelDefinitionYamlStep.readRedeliveryPolicyDefinition(element.redeliveryPolicy);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readCSimpleExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.CSimpleExpression(__assign({}, element)) : new CamelDefinition_1.CSimpleExpression();
        return def;
    };
    CamelDefinitionYamlStep.readConstantExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.ConstantExpression(__assign({}, element)) : new CamelDefinition_1.ConstantExpression();
        return def;
    };
    CamelDefinitionYamlStep.readDatasonnetExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.DatasonnetExpression(__assign({}, element)) : new CamelDefinition_1.DatasonnetExpression();
        return def;
    };
    CamelDefinitionYamlStep.readExchangePropertyExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.ExchangePropertyExpression(__assign({}, element)) : new CamelDefinition_1.ExchangePropertyExpression();
        return def;
    };
    CamelDefinitionYamlStep.readExpressionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ExpressionDefinition(__assign({}, element)) : new CamelDefinition_1.ExpressionDefinition();
        if ((element === null || element === void 0 ? void 0 : element.ognl) !== undefined) {
            if (Array.isArray(element.ognl)) {
                def.ognl = CamelDefinitionYamlStep.readOgnlExpression(element.ognl[0]);
            }
            else {
                def.ognl = CamelDefinitionYamlStep.readOgnlExpression(element.ognl);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.python) !== undefined) {
            if (Array.isArray(element.python)) {
                def.python = CamelDefinitionYamlStep.readPythonExpression(element.python[0]);
            }
            else {
                def.python = CamelDefinitionYamlStep.readPythonExpression(element.python);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.constant) !== undefined) {
            if (Array.isArray(element.constant)) {
                def.constant = CamelDefinitionYamlStep.readConstantExpression(element.constant[0]);
            }
            else {
                def.constant = CamelDefinitionYamlStep.readConstantExpression(element.constant);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mvel) !== undefined) {
            if (Array.isArray(element.mvel)) {
                def.mvel = CamelDefinitionYamlStep.readMvelExpression(element.mvel[0]);
            }
            else {
                def.mvel = CamelDefinitionYamlStep.readMvelExpression(element.mvel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.method) !== undefined) {
            if (Array.isArray(element.method)) {
                def.method = CamelDefinitionYamlStep.readMethodCallExpression(element.method[0]);
            }
            else {
                def.method = CamelDefinitionYamlStep.readMethodCallExpression(element.method);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xquery) !== undefined) {
            if (Array.isArray(element.xquery)) {
                def.xquery = CamelDefinitionYamlStep.readXQueryExpression(element.xquery[0]);
            }
            else {
                def.xquery = CamelDefinitionYamlStep.readXQueryExpression(element.xquery);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.datasonnet) !== undefined) {
            if (Array.isArray(element.datasonnet)) {
                def.datasonnet = CamelDefinitionYamlStep.readDatasonnetExpression(element.datasonnet[0]);
            }
            else {
                def.datasonnet = CamelDefinitionYamlStep.readDatasonnetExpression(element.datasonnet);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jq) !== undefined) {
            if (Array.isArray(element.jq)) {
                def.jq = CamelDefinitionYamlStep.readJqExpression(element.jq[0]);
            }
            else {
                def.jq = CamelDefinitionYamlStep.readJqExpression(element.jq);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.js) !== undefined) {
            if (Array.isArray(element.js)) {
                def.js = CamelDefinitionYamlStep.readJavaScriptExpression(element.js[0]);
            }
            else {
                def.js = CamelDefinitionYamlStep.readJavaScriptExpression(element.js);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.language) !== undefined) {
            if (Array.isArray(element.language)) {
                def.language = CamelDefinitionYamlStep.readLanguageExpression(element.language[0]);
            }
            else {
                def.language = CamelDefinitionYamlStep.readLanguageExpression(element.language);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.simple) !== undefined) {
            if (Array.isArray(element.simple)) {
                def.simple = CamelDefinitionYamlStep.readSimpleExpression(element.simple[0]);
            }
            else {
                def.simple = CamelDefinitionYamlStep.readSimpleExpression(element.simple);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7terser) !== undefined) {
            if (Array.isArray(element.hl7terser)) {
                def.hl7terser = CamelDefinitionYamlStep.readHl7TerserExpression(element.hl7terser[0]);
            }
            else {
                def.hl7terser = CamelDefinitionYamlStep.readHl7TerserExpression(element.hl7terser);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tokenize) !== undefined) {
            if (Array.isArray(element.tokenize)) {
                def.tokenize = CamelDefinitionYamlStep.readTokenizerExpression(element.tokenize[0]);
            }
            else {
                def.tokenize = CamelDefinitionYamlStep.readTokenizerExpression(element.tokenize);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.spel) !== undefined) {
            if (Array.isArray(element.spel)) {
                def.spel = CamelDefinitionYamlStep.readSpELExpression(element.spel[0]);
            }
            else {
                def.spel = CamelDefinitionYamlStep.readSpELExpression(element.spel);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ref) !== undefined) {
            if (Array.isArray(element.ref)) {
                def.ref = CamelDefinitionYamlStep.readRefExpression(element.ref[0]);
            }
            else {
                def.ref = CamelDefinitionYamlStep.readRefExpression(element.ref);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xpath) !== undefined) {
            if (Array.isArray(element.xpath)) {
                def.xpath = CamelDefinitionYamlStep.readXPathExpression(element.xpath[0]);
            }
            else {
                def.xpath = CamelDefinitionYamlStep.readXPathExpression(element.xpath);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.groovy) !== undefined) {
            if (Array.isArray(element.groovy)) {
                def.groovy = CamelDefinitionYamlStep.readGroovyExpression(element.groovy[0]);
            }
            else {
                def.groovy = CamelDefinitionYamlStep.readGroovyExpression(element.groovy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csimple) !== undefined) {
            if (Array.isArray(element.csimple)) {
                def.csimple = CamelDefinitionYamlStep.readCSimpleExpression(element.csimple[0]);
            }
            else {
                def.csimple = CamelDefinitionYamlStep.readCSimpleExpression(element.csimple);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.exchangeProperty) !== undefined) {
            if (Array.isArray(element.exchangeProperty)) {
                def.exchangeProperty = CamelDefinitionYamlStep.readExchangePropertyExpression(element.exchangeProperty[0]);
            }
            else {
                def.exchangeProperty = CamelDefinitionYamlStep.readExchangePropertyExpression(element.exchangeProperty);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonpath) !== undefined) {
            if (Array.isArray(element.jsonpath)) {
                def.jsonpath = CamelDefinitionYamlStep.readJsonPathExpression(element.jsonpath[0]);
            }
            else {
                def.jsonpath = CamelDefinitionYamlStep.readJsonPathExpression(element.jsonpath);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.header) !== undefined) {
            if (Array.isArray(element.header)) {
                def.header = CamelDefinitionYamlStep.readHeaderExpression(element.header[0]);
            }
            else {
                def.header = CamelDefinitionYamlStep.readHeaderExpression(element.header);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.joor) !== undefined) {
            if (Array.isArray(element.joor)) {
                def.joor = CamelDefinitionYamlStep.readJoorExpression(element.joor[0]);
            }
            else {
                def.joor = CamelDefinitionYamlStep.readJoorExpression(element.joor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xtokenize) !== undefined) {
            if (Array.isArray(element.xtokenize)) {
                def.xtokenize = CamelDefinitionYamlStep.readXMLTokenizerExpression(element.xtokenize[0]);
            }
            else {
                def.xtokenize = CamelDefinitionYamlStep.readXMLTokenizerExpression(element.xtokenize);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readGroovyExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.GroovyExpression(__assign({}, element)) : new CamelDefinition_1.GroovyExpression();
        return def;
    };
    CamelDefinitionYamlStep.readHeaderExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.HeaderExpression(__assign({}, element)) : new CamelDefinition_1.HeaderExpression();
        return def;
    };
    CamelDefinitionYamlStep.readHl7TerserExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.Hl7TerserExpression(__assign({}, element)) : new CamelDefinition_1.Hl7TerserExpression();
        return def;
    };
    CamelDefinitionYamlStep.readJavaScriptExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.JavaScriptExpression(__assign({}, element)) : new CamelDefinition_1.JavaScriptExpression();
        return def;
    };
    CamelDefinitionYamlStep.readJoorExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.JoorExpression(__assign({}, element)) : new CamelDefinition_1.JoorExpression();
        return def;
    };
    CamelDefinitionYamlStep.readJqExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.JqExpression(__assign({}, element)) : new CamelDefinition_1.JqExpression();
        return def;
    };
    CamelDefinitionYamlStep.readJsonPathExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.JsonPathExpression(__assign({}, element)) : new CamelDefinition_1.JsonPathExpression();
        return def;
    };
    CamelDefinitionYamlStep.readLanguageExpression = function (element) {
        var def = element ? new CamelDefinition_1.LanguageExpression(__assign({}, element)) : new CamelDefinition_1.LanguageExpression();
        return def;
    };
    CamelDefinitionYamlStep.readMethodCallExpression = function (element) {
        var def = element ? new CamelDefinition_1.MethodCallExpression(__assign({}, element)) : new CamelDefinition_1.MethodCallExpression();
        return def;
    };
    CamelDefinitionYamlStep.readMvelExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.MvelExpression(__assign({}, element)) : new CamelDefinition_1.MvelExpression();
        return def;
    };
    CamelDefinitionYamlStep.readOgnlExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.OgnlExpression(__assign({}, element)) : new CamelDefinition_1.OgnlExpression();
        return def;
    };
    CamelDefinitionYamlStep.readPythonExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.PythonExpression(__assign({}, element)) : new CamelDefinition_1.PythonExpression();
        return def;
    };
    CamelDefinitionYamlStep.readRefExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.RefExpression(__assign({}, element)) : new CamelDefinition_1.RefExpression();
        return def;
    };
    CamelDefinitionYamlStep.readSimpleExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.SimpleExpression(__assign({}, element)) : new CamelDefinition_1.SimpleExpression();
        return def;
    };
    CamelDefinitionYamlStep.readSpELExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.SpELExpression(__assign({}, element)) : new CamelDefinition_1.SpELExpression();
        return def;
    };
    CamelDefinitionYamlStep.readTokenizerExpression = function (element) {
        if (element && typeof element === 'string')
            element = { token: element };
        var def = element ? new CamelDefinition_1.TokenizerExpression(__assign({}, element)) : new CamelDefinition_1.TokenizerExpression();
        return def;
    };
    CamelDefinitionYamlStep.readXMLTokenizerExpression = function (element) {
        var def = element ? new CamelDefinition_1.XMLTokenizerExpression(__assign({}, element)) : new CamelDefinition_1.XMLTokenizerExpression();
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readXPathExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.XPathExpression(__assign({}, element)) : new CamelDefinition_1.XPathExpression();
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readXQueryExpression = function (element) {
        if (element && typeof element === 'string')
            element = { expression: element };
        var def = element ? new CamelDefinition_1.XQueryExpression(__assign({}, element)) : new CamelDefinition_1.XQueryExpression();
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionYamlStep.readPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readCustomLoadBalancerDefinition = function (element) {
        if (element && typeof element === 'string')
            element = { ref: element };
        var def = element ? new CamelDefinition_1.CustomLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.CustomLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readFailoverLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FailoverLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.FailoverLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRandomLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RandomLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.RandomLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRoundRobinLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RoundRobinLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.RoundRobinLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readStickyLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StickyLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.StickyLoadBalancerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            if (Array.isArray(element.correlationExpression)) {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression[0]);
            }
            else {
                def.correlationExpression = CamelDefinitionYamlStep.readExpressionSubElementDefinition(element.correlationExpression);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readTopicLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TopicLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.TopicLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readWeightedLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WeightedLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.WeightedLoadBalancerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readApiKeyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ApiKeyDefinition(__assign({}, element)) : new CamelDefinition_1.ApiKeyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readBasicAuthDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BasicAuthDefinition(__assign({}, element)) : new CamelDefinition_1.BasicAuthDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readBearerTokenDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BearerTokenDefinition(__assign({}, element)) : new CamelDefinition_1.BearerTokenDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readDeleteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DeleteDefinition(__assign({}, element)) : new CamelDefinition_1.DeleteDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readGetDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GetDefinition(__assign({}, element)) : new CamelDefinition_1.GetDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readHeadDefinition = function (element) {
        var def = element ? new CamelDefinition_1.HeadDefinition(__assign({}, element)) : new CamelDefinition_1.HeadDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readMutualTLSDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MutualTLSDefinition(__assign({}, element)) : new CamelDefinition_1.MutualTLSDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readOAuth2Definition = function (element) {
        var def = element ? new CamelDefinition_1.OAuth2Definition(__assign({}, element)) : new CamelDefinition_1.OAuth2Definition();
        def.scopes = element && (element === null || element === void 0 ? void 0 : element.scopes) ? element === null || element === void 0 ? void 0 : element.scopes.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readOpenIdConnectDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OpenIdConnectDefinition(__assign({}, element)) : new CamelDefinition_1.OpenIdConnectDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readParamDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ParamDefinition(__assign({}, element)) : new CamelDefinition_1.ParamDefinition();
        def.allowableValues = element && (element === null || element === void 0 ? void 0 : element.allowableValues) ? element === null || element === void 0 ? void 0 : element.allowableValues.map(function (x) { return CamelDefinitionYamlStep.readValueDefinition(x); }) : [];
        def.examples = element && (element === null || element === void 0 ? void 0 : element.examples) ? element === null || element === void 0 ? void 0 : element.examples.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readPatchDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PatchDefinition(__assign({}, element)) : new CamelDefinition_1.PatchDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readPostDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PostDefinition(__assign({}, element)) : new CamelDefinition_1.PostDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readPutDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PutDefinition(__assign({}, element)) : new CamelDefinition_1.PutDefinition();
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionYamlStep.readParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionYamlStep.readResponseMessageDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readResponseHeaderDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResponseHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.ResponseHeaderDefinition();
        def.allowableValues = element && (element === null || element === void 0 ? void 0 : element.allowableValues) ? element === null || element === void 0 ? void 0 : element.allowableValues.map(function (x) { return CamelDefinitionYamlStep.readValueDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readResponseMessageDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResponseMessageDefinition(__assign({}, element)) : new CamelDefinition_1.ResponseMessageDefinition();
        def.examples = element && (element === null || element === void 0 ? void 0 : element.examples) ? element === null || element === void 0 ? void 0 : element.examples.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.header = element && (element === null || element === void 0 ? void 0 : element.header) ? element === null || element === void 0 ? void 0 : element.header.map(function (x) { return CamelDefinitionYamlStep.readResponseHeaderDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRestBindingDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestBindingDefinition(__assign({}, element)) : new CamelDefinition_1.RestBindingDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRestConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.RestConfigurationDefinition();
        def.corsHeaders = element && (element === null || element === void 0 ? void 0 : element.corsHeaders) ? element === null || element === void 0 ? void 0 : element.corsHeaders.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.dataFormatProperty = element && (element === null || element === void 0 ? void 0 : element.dataFormatProperty) ? element === null || element === void 0 ? void 0 : element.dataFormatProperty.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.consumerProperty = element && (element === null || element === void 0 ? void 0 : element.consumerProperty) ? element === null || element === void 0 ? void 0 : element.consumerProperty.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.apiProperty = element && (element === null || element === void 0 ? void 0 : element.apiProperty) ? element === null || element === void 0 ? void 0 : element.apiProperty.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.endpointProperty = element && (element === null || element === void 0 ? void 0 : element.endpointProperty) ? element === null || element === void 0 ? void 0 : element.endpointProperty.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        def.componentProperty = element && (element === null || element === void 0 ? void 0 : element.componentProperty) ? element === null || element === void 0 ? void 0 : element.componentProperty.map(function (x) { return CamelDefinitionYamlStep.readRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRestDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestDefinition(__assign({}, element)) : new CamelDefinition_1.RestDefinition();
        def.head = element && (element === null || element === void 0 ? void 0 : element.head) ? element === null || element === void 0 ? void 0 : element.head.map(function (x) { return CamelDefinitionYamlStep.readHeadDefinition(x); }) : [];
        def.patch = element && (element === null || element === void 0 ? void 0 : element.patch) ? element === null || element === void 0 ? void 0 : element.patch.map(function (x) { return CamelDefinitionYamlStep.readPatchDefinition(x); }) : [];
        def.post = element && (element === null || element === void 0 ? void 0 : element.post) ? element === null || element === void 0 ? void 0 : element.post.map(function (x) { return CamelDefinitionYamlStep.readPostDefinition(x); }) : [];
        def.get = element && (element === null || element === void 0 ? void 0 : element.get) ? element === null || element === void 0 ? void 0 : element.get.map(function (x) { return CamelDefinitionYamlStep.readGetDefinition(x); }) : [];
        def.securityRequirements = element && (element === null || element === void 0 ? void 0 : element.securityRequirements) ? element === null || element === void 0 ? void 0 : element.securityRequirements.map(function (x) { return CamelDefinitionYamlStep.readSecurityDefinition(x); }) : [];
        def.delete = element && (element === null || element === void 0 ? void 0 : element.delete) ? element === null || element === void 0 ? void 0 : element.delete.map(function (x) { return CamelDefinitionYamlStep.readDeleteDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.securityDefinitions) !== undefined) {
            if (Array.isArray(element.securityDefinitions)) {
                def.securityDefinitions = CamelDefinitionYamlStep.readRestSecuritiesDefinition(element.securityDefinitions[0]);
            }
            else {
                def.securityDefinitions = CamelDefinitionYamlStep.readRestSecuritiesDefinition(element.securityDefinitions);
            }
        }
        def.put = element && (element === null || element === void 0 ? void 0 : element.put) ? element === null || element === void 0 ? void 0 : element.put.map(function (x) { return CamelDefinitionYamlStep.readPutDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readRestPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestPropertyDefinition(__assign({}, element)) : new CamelDefinition_1.RestPropertyDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readRestSecuritiesDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestSecuritiesDefinition(__assign({}, element)) : new CamelDefinition_1.RestSecuritiesDefinition();
        if ((element === null || element === void 0 ? void 0 : element.openIdConnect) !== undefined) {
            if (Array.isArray(element.openIdConnect)) {
                def.openIdConnect = CamelDefinitionYamlStep.readOpenIdConnectDefinition(element.openIdConnect[0]);
            }
            else {
                def.openIdConnect = CamelDefinitionYamlStep.readOpenIdConnectDefinition(element.openIdConnect);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.apiKey) !== undefined) {
            if (Array.isArray(element.apiKey)) {
                def.apiKey = CamelDefinitionYamlStep.readApiKeyDefinition(element.apiKey[0]);
            }
            else {
                def.apiKey = CamelDefinitionYamlStep.readApiKeyDefinition(element.apiKey);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.basicAuth) !== undefined) {
            if (Array.isArray(element.basicAuth)) {
                def.basicAuth = CamelDefinitionYamlStep.readBasicAuthDefinition(element.basicAuth[0]);
            }
            else {
                def.basicAuth = CamelDefinitionYamlStep.readBasicAuthDefinition(element.basicAuth);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mutualTls) !== undefined) {
            if (Array.isArray(element.mutualTls)) {
                def.mutualTls = CamelDefinitionYamlStep.readMutualTLSDefinition(element.mutualTls[0]);
            }
            else {
                def.mutualTls = CamelDefinitionYamlStep.readMutualTLSDefinition(element.mutualTls);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bearer) !== undefined) {
            if (Array.isArray(element.bearer)) {
                def.bearer = CamelDefinitionYamlStep.readBearerTokenDefinition(element.bearer[0]);
            }
            else {
                def.bearer = CamelDefinitionYamlStep.readBearerTokenDefinition(element.bearer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.oauth2) !== undefined) {
            if (Array.isArray(element.oauth2)) {
                def.oauth2 = CamelDefinitionYamlStep.readOAuth2Definition(element.oauth2[0]);
            }
            else {
                def.oauth2 = CamelDefinitionYamlStep.readOAuth2Definition(element.oauth2);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readRestsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestsDefinition(__assign({}, element)) : new CamelDefinition_1.RestsDefinition();
        def.rest = element && (element === null || element === void 0 ? void 0 : element.rest) ? element === null || element === void 0 ? void 0 : element.rest.map(function (x) { return CamelDefinitionYamlStep.readRestDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionYamlStep.readSecurityDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SecurityDefinition(__assign({}, element)) : new CamelDefinition_1.SecurityDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readCustomTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CustomTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.CustomTransformerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readDataFormatTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatTransformerDefinition();
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            if (Array.isArray(element.univocityCsv)) {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv[0]);
            }
            else {
                def.univocityCsv = CamelDefinitionYamlStep.readUniVocityCsvDataFormat(element.univocityCsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            if (Array.isArray(element.protobuf)) {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf[0]);
            }
            else {
                def.protobuf = CamelDefinitionYamlStep.readProtobufDataFormat(element.protobuf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            if (Array.isArray(element.tarFile)) {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile[0]);
            }
            else {
                def.tarFile = CamelDefinitionYamlStep.readTarFileDataFormat(element.tarFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            if (Array.isArray(element.tidyMarkup)) {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup[0]);
            }
            else {
                def.tidyMarkup = CamelDefinitionYamlStep.readTidyMarkupDataFormat(element.tidyMarkup);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            if (Array.isArray(element.csv)) {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv[0]);
            }
            else {
                def.csv = CamelDefinitionYamlStep.readCsvDataFormat(element.csv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            if (Array.isArray(element.base64)) {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64[0]);
            }
            else {
                def.base64 = CamelDefinitionYamlStep.readBase64DataFormat(element.base64);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            if (Array.isArray(element.zipDeflater)) {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater[0]);
            }
            else {
                def.zipDeflater = CamelDefinitionYamlStep.readZipDeflaterDataFormat(element.zipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            if (Array.isArray(element.bindy)) {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy[0]);
            }
            else {
                def.bindy = CamelDefinitionYamlStep.readBindyDataFormat(element.bindy);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            if (Array.isArray(element.syslog)) {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog[0]);
            }
            else {
                def.syslog = CamelDefinitionYamlStep.readSyslogDataFormat(element.syslog);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            if (Array.isArray(element.zipFile)) {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile[0]);
            }
            else {
                def.zipFile = CamelDefinitionYamlStep.readZipFileDataFormat(element.zipFile);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            if (Array.isArray(element.jaxb)) {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb[0]);
            }
            else {
                def.jaxb = CamelDefinitionYamlStep.readJaxbDataFormat(element.jaxb);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            if (Array.isArray(element.rss)) {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss[0]);
            }
            else {
                def.rss = CamelDefinitionYamlStep.readRssDataFormat(element.rss);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            if (Array.isArray(element.mimeMultipart)) {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart[0]);
            }
            else {
                def.mimeMultipart = CamelDefinitionYamlStep.readMimeMultipartDataFormat(element.mimeMultipart);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            if (Array.isArray(element.asn1)) {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1[0]);
            }
            else {
                def.asn1 = CamelDefinitionYamlStep.readASN1DataFormat(element.asn1);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            if (Array.isArray(element.pgp)) {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp[0]);
            }
            else {
                def.pgp = CamelDefinitionYamlStep.readPGPDataFormat(element.pgp);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            if (Array.isArray(element.thrift)) {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift[0]);
            }
            else {
                def.thrift = CamelDefinitionYamlStep.readThriftDataFormat(element.thrift);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            if (Array.isArray(element.json)) {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json[0]);
            }
            else {
                def.json = CamelDefinitionYamlStep.readJsonDataFormat(element.json);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            if (Array.isArray(element.lzf)) {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf[0]);
            }
            else {
                def.lzf = CamelDefinitionYamlStep.readLZFDataFormat(element.lzf);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            if (Array.isArray(element.fhirXml)) {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml[0]);
            }
            else {
                def.fhirXml = CamelDefinitionYamlStep.readFhirXmlDataFormat(element.fhirXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            if (Array.isArray(element.barcode)) {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode[0]);
            }
            else {
                def.barcode = CamelDefinitionYamlStep.readBarcodeDataFormat(element.barcode);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            if (Array.isArray(element.avro)) {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro[0]);
            }
            else {
                def.avro = CamelDefinitionYamlStep.readAvroDataFormat(element.avro);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            if (Array.isArray(element.yaml)) {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml[0]);
            }
            else {
                def.yaml = CamelDefinitionYamlStep.readYAMLDataFormat(element.yaml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            if (Array.isArray(element.fhirJson)) {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson[0]);
            }
            else {
                def.fhirJson = CamelDefinitionYamlStep.readFhirJsonDataFormat(element.fhirJson);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            if (Array.isArray(element.any23)) {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23[0]);
            }
            else {
                def.any23 = CamelDefinitionYamlStep.readAny23DataFormat(element.any23);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            if (Array.isArray(element.custom)) {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom[0]);
            }
            else {
                def.custom = CamelDefinitionYamlStep.readCustomDataFormat(element.custom);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            if (Array.isArray(element.flatpack)) {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack[0]);
            }
            else {
                def.flatpack = CamelDefinitionYamlStep.readFlatpackDataFormat(element.flatpack);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            if (Array.isArray(element.swiftMx)) {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx[0]);
            }
            else {
                def.swiftMx = CamelDefinitionYamlStep.readSwiftMxDataFormat(element.swiftMx);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            if (Array.isArray(element.cbor)) {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor[0]);
            }
            else {
                def.cbor = CamelDefinitionYamlStep.readCBORDataFormat(element.cbor);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            if (Array.isArray(element.crypto)) {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto[0]);
            }
            else {
                def.crypto = CamelDefinitionYamlStep.readCryptoDataFormat(element.crypto);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            if (Array.isArray(element.swiftMt)) {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt[0]);
            }
            else {
                def.swiftMt = CamelDefinitionYamlStep.readSwiftMtDataFormat(element.swiftMt);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            if (Array.isArray(element.univocityTsv)) {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv[0]);
            }
            else {
                def.univocityTsv = CamelDefinitionYamlStep.readUniVocityTsvDataFormat(element.univocityTsv);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            if (Array.isArray(element.hl7)) {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7[0]);
            }
            else {
                def.hl7 = CamelDefinitionYamlStep.readHL7DataFormat(element.hl7);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            if (Array.isArray(element.jsonApi)) {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi[0]);
            }
            else {
                def.jsonApi = CamelDefinitionYamlStep.readJsonApiDataFormat(element.jsonApi);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            if (Array.isArray(element.xmlSecurity)) {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity[0]);
            }
            else {
                def.xmlSecurity = CamelDefinitionYamlStep.readXMLSecurityDataFormat(element.xmlSecurity);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            if (Array.isArray(element.ical)) {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical[0]);
            }
            else {
                def.ical = CamelDefinitionYamlStep.readIcalDataFormat(element.ical);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            if (Array.isArray(element.univocityFixed)) {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed[0]);
            }
            else {
                def.univocityFixed = CamelDefinitionYamlStep.readUniVocityFixedDataFormat(element.univocityFixed);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            if (Array.isArray(element.jacksonXml)) {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml[0]);
            }
            else {
                def.jacksonXml = CamelDefinitionYamlStep.readJacksonXMLDataFormat(element.jacksonXml);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            if (Array.isArray(element.grok)) {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok[0]);
            }
            else {
                def.grok = CamelDefinitionYamlStep.readGrokDataFormat(element.grok);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            if (Array.isArray(element.xstream)) {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream[0]);
            }
            else {
                def.xstream = CamelDefinitionYamlStep.readXStreamDataFormat(element.xstream);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            if (Array.isArray(element.gzipDeflater)) {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater[0]);
            }
            else {
                def.gzipDeflater = CamelDefinitionYamlStep.readGzipDeflaterDataFormat(element.gzipDeflater);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            if (Array.isArray(element.soap)) {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap[0]);
            }
            else {
                def.soap = CamelDefinitionYamlStep.readSoapDataFormat(element.soap);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readEndpointTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EndpointTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.EndpointTransformerDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readTransformersDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransformersDefinition(__assign({}, element)) : new CamelDefinition_1.TransformersDefinition();
        if ((element === null || element === void 0 ? void 0 : element.endpointTransformer) !== undefined) {
            if (Array.isArray(element.endpointTransformer)) {
                def.endpointTransformer = CamelDefinitionYamlStep.readEndpointTransformerDefinition(element.endpointTransformer[0]);
            }
            else {
                def.endpointTransformer = CamelDefinitionYamlStep.readEndpointTransformerDefinition(element.endpointTransformer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customTransformer) !== undefined) {
            if (Array.isArray(element.customTransformer)) {
                def.customTransformer = CamelDefinitionYamlStep.readCustomTransformerDefinition(element.customTransformer[0]);
            }
            else {
                def.customTransformer = CamelDefinitionYamlStep.readCustomTransformerDefinition(element.customTransformer);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.dataFormatTransformer) !== undefined) {
            if (Array.isArray(element.dataFormatTransformer)) {
                def.dataFormatTransformer = CamelDefinitionYamlStep.readDataFormatTransformerDefinition(element.dataFormatTransformer[0]);
            }
            else {
                def.dataFormatTransformer = CamelDefinitionYamlStep.readDataFormatTransformerDefinition(element.dataFormatTransformer);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readCustomValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CustomValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.CustomValidatorDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readEndpointValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EndpointValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.EndpointValidatorDefinition();
        return def;
    };
    CamelDefinitionYamlStep.readPredicateValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PredicateValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.PredicateValidatorDefinition();
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionYamlStep.readExpressionDefinition(element.expression);
        }
        else {
            var languageName = Object.keys(element).filter(function (key) { return CamelMetadata_1.CamelMetadataApi.hasLanguage(key); })[0] || undefined;
            if (languageName) {
                var exp = {};
                exp[languageName] = element[languageName];
                def.expression = CamelDefinitionYamlStep.readExpressionDefinition(exp);
                delete def[languageName];
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readValidatorsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValidatorsDefinition(__assign({}, element)) : new CamelDefinition_1.ValidatorsDefinition();
        if ((element === null || element === void 0 ? void 0 : element.predicateValidator) !== undefined) {
            if (Array.isArray(element.predicateValidator)) {
                def.predicateValidator = CamelDefinitionYamlStep.readPredicateValidatorDefinition(element.predicateValidator[0]);
            }
            else {
                def.predicateValidator = CamelDefinitionYamlStep.readPredicateValidatorDefinition(element.predicateValidator);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.endpointValidator) !== undefined) {
            if (Array.isArray(element.endpointValidator)) {
                def.endpointValidator = CamelDefinitionYamlStep.readEndpointValidatorDefinition(element.endpointValidator[0]);
            }
            else {
                def.endpointValidator = CamelDefinitionYamlStep.readEndpointValidatorDefinition(element.endpointValidator);
            }
        }
        if ((element === null || element === void 0 ? void 0 : element.customValidator) !== undefined) {
            if (Array.isArray(element.customValidator)) {
                def.customValidator = CamelDefinitionYamlStep.readCustomValidatorDefinition(element.customValidator[0]);
            }
            else {
                def.customValidator = CamelDefinitionYamlStep.readCustomValidatorDefinition(element.customValidator);
            }
        }
        return def;
    };
    CamelDefinitionYamlStep.readStep = function (body, clone) {
        if (clone === void 0) { clone = false; }
        var name = Object.getOwnPropertyNames(body)[0];
        var newBody = CamelUtil_1.CamelUtil.camelizeBody(name, body[name], clone);
        switch (name) {
            case 'setProperty': return CamelDefinitionYamlStep.readSetPropertyDefinition(newBody);
            case 'to': return CamelDefinitionYamlStep.readToDefinition(newBody);
            case 'doCatch': return CamelDefinitionYamlStep.readCatchDefinition(newBody);
            case 'inOut': return CamelDefinitionYamlStep.readInOutDefinition(newBody);
            case 'onFallback': return CamelDefinitionYamlStep.readOnFallbackDefinition(newBody);
            case 'loadBalance': return CamelDefinitionYamlStep.readLoadBalanceDefinition(newBody);
            case 'process': return CamelDefinitionYamlStep.readProcessDefinition(newBody);
            case 'bean': return CamelDefinitionYamlStep.readBeanDefinition(newBody);
            case 'transacted': return CamelDefinitionYamlStep.readTransactedDefinition(newBody);
            case 'pollEnrich': return CamelDefinitionYamlStep.readPollEnrichDefinition(newBody);
            case 'filter': return CamelDefinitionYamlStep.readFilterDefinition(newBody);
            case 'pausable': return CamelDefinitionYamlStep.readPausableDefinition(newBody);
            case 'setBody': return CamelDefinitionYamlStep.readSetBodyDefinition(newBody);
            case 'claimCheck': return CamelDefinitionYamlStep.readClaimCheckDefinition(newBody);
            case 'step': return CamelDefinitionYamlStep.readStepDefinition(newBody);
            case 'aggregate': return CamelDefinitionYamlStep.readAggregateDefinition(newBody);
            case 'when': return CamelDefinitionYamlStep.readWhenDefinition(newBody);
            case 'loop': return CamelDefinitionYamlStep.readLoopDefinition(newBody);
            case 'stop': return CamelDefinitionYamlStep.readStopDefinition(newBody);
            case 'resumable': return CamelDefinitionYamlStep.readResumableDefinition(newBody);
            case 'removeProperty': return CamelDefinitionYamlStep.readRemovePropertyDefinition(newBody);
            case 'split': return CamelDefinitionYamlStep.readSplitDefinition(newBody);
            case 'multicast': return CamelDefinitionYamlStep.readMulticastDefinition(newBody);
            case 'otherwise': return CamelDefinitionYamlStep.readOtherwiseDefinition(newBody);
            case 'removeProperties': return CamelDefinitionYamlStep.readRemovePropertiesDefinition(newBody);
            case 'saga': return CamelDefinitionYamlStep.readSagaDefinition(newBody);
            case 'removeHeaders': return CamelDefinitionYamlStep.readRemoveHeadersDefinition(newBody);
            case 'setExchangePattern': return CamelDefinitionYamlStep.readSetExchangePatternDefinition(newBody);
            case 'intercept': return CamelDefinitionYamlStep.readInterceptDefinition(newBody);
            case 'transform': return CamelDefinitionYamlStep.readTransformDefinition(newBody);
            case 'routingSlip': return CamelDefinitionYamlStep.readRoutingSlipDefinition(newBody);
            case 'doTry': return CamelDefinitionYamlStep.readTryDefinition(newBody);
            case 'delay': return CamelDefinitionYamlStep.readDelayDefinition(newBody);
            case 'script': return CamelDefinitionYamlStep.readScriptDefinition(newBody);
            case 'inOnly': return CamelDefinitionYamlStep.readInOnlyDefinition(newBody);
            case 'enrich': return CamelDefinitionYamlStep.readEnrichDefinition(newBody);
            case 'onCompletion': return CamelDefinitionYamlStep.readOnCompletionDefinition(newBody);
            case 'wireTap': return CamelDefinitionYamlStep.readWireTapDefinition(newBody);
            case 'kamelet': return CamelDefinitionYamlStep.readKameletDefinition(newBody);
            case 'interceptFrom': return CamelDefinitionYamlStep.readInterceptFromDefinition(newBody);
            case 'doFinally': return CamelDefinitionYamlStep.readFinallyDefinition(newBody);
            case 'idempotentConsumer': return CamelDefinitionYamlStep.readIdempotentConsumerDefinition(newBody);
            case 'removeHeader': return CamelDefinitionYamlStep.readRemoveHeaderDefinition(newBody);
            case 'circuitBreaker': return CamelDefinitionYamlStep.readCircuitBreakerDefinition(newBody);
            case 'rollback': return CamelDefinitionYamlStep.readRollbackDefinition(newBody);
            case 'dynamicRouter': return CamelDefinitionYamlStep.readDynamicRouterDefinition(newBody);
            case 'resequence': return CamelDefinitionYamlStep.readResequenceDefinition(newBody);
            case 'log': return CamelDefinitionYamlStep.readLogDefinition(newBody);
            case 'throttle': return CamelDefinitionYamlStep.readThrottleDefinition(newBody);
            case 'recipientList': return CamelDefinitionYamlStep.readRecipientListDefinition(newBody);
            case 'marshal': return CamelDefinitionYamlStep.readMarshalDefinition(newBody);
            case 'policy': return CamelDefinitionYamlStep.readPolicyDefinition(newBody);
            case 'serviceCall': return CamelDefinitionYamlStep.readServiceCallDefinition(newBody);
            case 'unmarshal': return CamelDefinitionYamlStep.readUnmarshalDefinition(newBody);
            case 'threads': return CamelDefinitionYamlStep.readThreadsDefinition(newBody);
            case 'toD': return CamelDefinitionYamlStep.readToDynamicDefinition(newBody);
            case 'convertBodyTo': return CamelDefinitionYamlStep.readConvertBodyDefinition(newBody);
            case 'pipeline': return CamelDefinitionYamlStep.readPipelineDefinition(newBody);
            case 'throwException': return CamelDefinitionYamlStep.readThrowExceptionDefinition(newBody);
            case 'validate': return CamelDefinitionYamlStep.readValidateDefinition(newBody);
            case 'interceptSendToEndpoint': return CamelDefinitionYamlStep.readInterceptSendToEndpointDefinition(newBody);
            case 'choice': return CamelDefinitionYamlStep.readChoiceDefinition(newBody);
            case 'whenSkipSendToEndpoint': return CamelDefinitionYamlStep.readWhenSkipSendToEndpointDefinition(newBody);
            case 'sample': return CamelDefinitionYamlStep.readSamplingDefinition(newBody);
            case 'setHeader': return CamelDefinitionYamlStep.readSetHeaderDefinition(newBody);
            case 'sort': return CamelDefinitionYamlStep.readSortDefinition(newBody);
            case 'toD': return CamelDefinitionYamlStep.readToDynamicDefinition(newBody);
            default: return new IntegrationDefinition_1.CamelElement('');
        }
    };
    CamelDefinitionYamlStep.readSteps = function (elements) {
        var result = [];
        if (elements !== undefined) {
            elements.forEach(function (e) {
                result.push(CamelDefinitionYamlStep.readStep(e));
            });
        }
        return result;
    };
    return CamelDefinitionYamlStep;
}());
exports.CamelDefinitionYamlStep = CamelDefinitionYamlStep;
