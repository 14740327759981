"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CamelDefinitionApi = void 0;
/**
 * Generated by karavan build tools - do NOT edit this file!
 */
var IntegrationDefinition_1 = require("../model/IntegrationDefinition");
var CamelDefinition_1 = require("../model/CamelDefinition");
var CamelUtil_1 = require("./CamelUtil");
var CamelDefinitionApi = /** @class */ (function () {
    function CamelDefinitionApi() {
    }
    CamelDefinitionApi.createProcessorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ProcessorDefinition(__assign({}, element)) : new CamelDefinition_1.ProcessorDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.aggregate) !== undefined) {
            def.aggregate = CamelDefinitionApi.createAggregateDefinition(element.aggregate);
        }
        if ((element === null || element === void 0 ? void 0 : element.bean) !== undefined) {
            def.bean = CamelDefinitionApi.createBeanDefinition(element.bean);
        }
        if ((element === null || element === void 0 ? void 0 : element.doCatch) !== undefined) {
            def.doCatch = CamelDefinitionApi.createCatchDefinition(element.doCatch);
        }
        if ((element === null || element === void 0 ? void 0 : element.choice) !== undefined) {
            def.choice = CamelDefinitionApi.createChoiceDefinition(element.choice);
        }
        if ((element === null || element === void 0 ? void 0 : element.circuitBreaker) !== undefined) {
            def.circuitBreaker = CamelDefinitionApi.createCircuitBreakerDefinition(element.circuitBreaker);
        }
        if ((element === null || element === void 0 ? void 0 : element.claimCheck) !== undefined) {
            def.claimCheck = CamelDefinitionApi.createClaimCheckDefinition(element.claimCheck);
        }
        if ((element === null || element === void 0 ? void 0 : element.convertBodyTo) !== undefined) {
            def.convertBodyTo = CamelDefinitionApi.createConvertBodyDefinition(element.convertBodyTo);
        }
        if ((element === null || element === void 0 ? void 0 : element.delay) !== undefined) {
            def.delay = CamelDefinitionApi.createDelayDefinition(element.delay);
        }
        if ((element === null || element === void 0 ? void 0 : element.dynamicRouter) !== undefined) {
            def.dynamicRouter = CamelDefinitionApi.createDynamicRouterDefinition(element.dynamicRouter);
        }
        if ((element === null || element === void 0 ? void 0 : element.enrich) !== undefined) {
            def.enrich = CamelDefinitionApi.createEnrichDefinition(element.enrich);
        }
        if ((element === null || element === void 0 ? void 0 : element.filter) !== undefined) {
            def.filter = CamelDefinitionApi.createFilterDefinition(element.filter);
        }
        if ((element === null || element === void 0 ? void 0 : element.doFinally) !== undefined) {
            def.doFinally = CamelDefinitionApi.createFinallyDefinition(element.doFinally);
        }
        if ((element === null || element === void 0 ? void 0 : element.idempotentConsumer) !== undefined) {
            def.idempotentConsumer = CamelDefinitionApi.createIdempotentConsumerDefinition(element.idempotentConsumer);
        }
        if ((element === null || element === void 0 ? void 0 : element.inOnly) !== undefined) {
            def.inOnly = CamelDefinitionApi.createInOnlyDefinition(element.inOnly);
        }
        if ((element === null || element === void 0 ? void 0 : element.inOut) !== undefined) {
            def.inOut = CamelDefinitionApi.createInOutDefinition(element.inOut);
        }
        if ((element === null || element === void 0 ? void 0 : element.intercept) !== undefined) {
            def.intercept = CamelDefinitionApi.createInterceptDefinition(element.intercept);
        }
        if ((element === null || element === void 0 ? void 0 : element.interceptFrom) !== undefined) {
            def.interceptFrom = CamelDefinitionApi.createInterceptFromDefinition(element.interceptFrom);
        }
        if ((element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint) !== undefined) {
            def.interceptSendToEndpoint = CamelDefinitionApi.createInterceptSendToEndpointDefinition(element.interceptSendToEndpoint);
        }
        if ((element === null || element === void 0 ? void 0 : element.kamelet) !== undefined) {
            def.kamelet = CamelDefinitionApi.createKameletDefinition(element.kamelet);
        }
        if ((element === null || element === void 0 ? void 0 : element.loadBalance) !== undefined) {
            def.loadBalance = CamelDefinitionApi.createLoadBalanceDefinition(element.loadBalance);
        }
        if ((element === null || element === void 0 ? void 0 : element.log) !== undefined) {
            def.log = CamelDefinitionApi.createLogDefinition(element.log);
        }
        if ((element === null || element === void 0 ? void 0 : element.loop) !== undefined) {
            def.loop = CamelDefinitionApi.createLoopDefinition(element.loop);
        }
        if ((element === null || element === void 0 ? void 0 : element.marshal) !== undefined) {
            def.marshal = CamelDefinitionApi.createMarshalDefinition(element.marshal);
        }
        if ((element === null || element === void 0 ? void 0 : element.multicast) !== undefined) {
            def.multicast = CamelDefinitionApi.createMulticastDefinition(element.multicast);
        }
        if ((element === null || element === void 0 ? void 0 : element.onCompletion) !== undefined) {
            def.onCompletion = CamelDefinitionApi.createOnCompletionDefinition(element.onCompletion);
        }
        if ((element === null || element === void 0 ? void 0 : element.onFallback) !== undefined) {
            def.onFallback = CamelDefinitionApi.createOnFallbackDefinition(element.onFallback);
        }
        if ((element === null || element === void 0 ? void 0 : element.otherwise) !== undefined) {
            def.otherwise = CamelDefinitionApi.createOtherwiseDefinition(element.otherwise);
        }
        if ((element === null || element === void 0 ? void 0 : element.pausable) !== undefined) {
            def.pausable = CamelDefinitionApi.createPausableDefinition(element.pausable);
        }
        if ((element === null || element === void 0 ? void 0 : element.pipeline) !== undefined) {
            def.pipeline = CamelDefinitionApi.createPipelineDefinition(element.pipeline);
        }
        if ((element === null || element === void 0 ? void 0 : element.policy) !== undefined) {
            def.policy = CamelDefinitionApi.createPolicyDefinition(element.policy);
        }
        if ((element === null || element === void 0 ? void 0 : element.pollEnrich) !== undefined) {
            def.pollEnrich = CamelDefinitionApi.createPollEnrichDefinition(element.pollEnrich);
        }
        if ((element === null || element === void 0 ? void 0 : element.process) !== undefined) {
            def.process = CamelDefinitionApi.createProcessDefinition(element.process);
        }
        if ((element === null || element === void 0 ? void 0 : element.recipientList) !== undefined) {
            def.recipientList = CamelDefinitionApi.createRecipientListDefinition(element.recipientList);
        }
        if ((element === null || element === void 0 ? void 0 : element.removeHeader) !== undefined) {
            def.removeHeader = CamelDefinitionApi.createRemoveHeaderDefinition(element.removeHeader);
        }
        if ((element === null || element === void 0 ? void 0 : element.removeHeaders) !== undefined) {
            def.removeHeaders = CamelDefinitionApi.createRemoveHeadersDefinition(element.removeHeaders);
        }
        if ((element === null || element === void 0 ? void 0 : element.removeProperties) !== undefined) {
            def.removeProperties = CamelDefinitionApi.createRemovePropertiesDefinition(element.removeProperties);
        }
        if ((element === null || element === void 0 ? void 0 : element.removeProperty) !== undefined) {
            def.removeProperty = CamelDefinitionApi.createRemovePropertyDefinition(element.removeProperty);
        }
        if ((element === null || element === void 0 ? void 0 : element.resequence) !== undefined) {
            def.resequence = CamelDefinitionApi.createResequenceDefinition(element.resequence);
        }
        if ((element === null || element === void 0 ? void 0 : element.resumable) !== undefined) {
            def.resumable = CamelDefinitionApi.createResumableDefinition(element.resumable);
        }
        if ((element === null || element === void 0 ? void 0 : element.rollback) !== undefined) {
            def.rollback = CamelDefinitionApi.createRollbackDefinition(element.rollback);
        }
        if ((element === null || element === void 0 ? void 0 : element.routingSlip) !== undefined) {
            def.routingSlip = CamelDefinitionApi.createRoutingSlipDefinition(element.routingSlip);
        }
        if ((element === null || element === void 0 ? void 0 : element.saga) !== undefined) {
            def.saga = CamelDefinitionApi.createSagaDefinition(element.saga);
        }
        if ((element === null || element === void 0 ? void 0 : element.sample) !== undefined) {
            def.sample = CamelDefinitionApi.createSamplingDefinition(element.sample);
        }
        if ((element === null || element === void 0 ? void 0 : element.script) !== undefined) {
            def.script = CamelDefinitionApi.createScriptDefinition(element.script);
        }
        if ((element === null || element === void 0 ? void 0 : element.setBody) !== undefined) {
            def.setBody = CamelDefinitionApi.createSetBodyDefinition(element.setBody);
        }
        if ((element === null || element === void 0 ? void 0 : element.setExchangePattern) !== undefined) {
            def.setExchangePattern = CamelDefinitionApi.createSetExchangePatternDefinition(element.setExchangePattern);
        }
        if ((element === null || element === void 0 ? void 0 : element.setHeader) !== undefined) {
            def.setHeader = CamelDefinitionApi.createSetHeaderDefinition(element.setHeader);
        }
        if ((element === null || element === void 0 ? void 0 : element.setProperty) !== undefined) {
            def.setProperty = CamelDefinitionApi.createSetPropertyDefinition(element.setProperty);
        }
        if ((element === null || element === void 0 ? void 0 : element.sort) !== undefined) {
            def.sort = CamelDefinitionApi.createSortDefinition(element.sort);
        }
        if ((element === null || element === void 0 ? void 0 : element.split) !== undefined) {
            def.split = CamelDefinitionApi.createSplitDefinition(element.split);
        }
        if ((element === null || element === void 0 ? void 0 : element.step) !== undefined) {
            def.step = CamelDefinitionApi.createStepDefinition(element.step);
        }
        if ((element === null || element === void 0 ? void 0 : element.stop) !== undefined) {
            def.stop = CamelDefinitionApi.createStopDefinition(element.stop);
        }
        if ((element === null || element === void 0 ? void 0 : element.threads) !== undefined) {
            def.threads = CamelDefinitionApi.createThreadsDefinition(element.threads);
        }
        if ((element === null || element === void 0 ? void 0 : element.throttle) !== undefined) {
            def.throttle = CamelDefinitionApi.createThrottleDefinition(element.throttle);
        }
        if ((element === null || element === void 0 ? void 0 : element.throwException) !== undefined) {
            def.throwException = CamelDefinitionApi.createThrowExceptionDefinition(element.throwException);
        }
        if ((element === null || element === void 0 ? void 0 : element.transacted) !== undefined) {
            def.transacted = CamelDefinitionApi.createTransactedDefinition(element.transacted);
        }
        if ((element === null || element === void 0 ? void 0 : element.transform) !== undefined) {
            def.transform = CamelDefinitionApi.createTransformDefinition(element.transform);
        }
        if ((element === null || element === void 0 ? void 0 : element.doTry) !== undefined) {
            def.doTry = CamelDefinitionApi.createTryDefinition(element.doTry);
        }
        if ((element === null || element === void 0 ? void 0 : element.unmarshal) !== undefined) {
            def.unmarshal = CamelDefinitionApi.createUnmarshalDefinition(element.unmarshal);
        }
        if ((element === null || element === void 0 ? void 0 : element.validate) !== undefined) {
            def.validate = CamelDefinitionApi.createValidateDefinition(element.validate);
        }
        if ((element === null || element === void 0 ? void 0 : element.when) !== undefined) {
            def.when = CamelDefinitionApi.createWhenDefinition(element.when);
        }
        if ((element === null || element === void 0 ? void 0 : element.whenSkipSendToEndpoint) !== undefined) {
            def.whenSkipSendToEndpoint = CamelDefinitionApi.createWhenSkipSendToEndpointDefinition(element.whenSkipSendToEndpoint);
        }
        if ((element === null || element === void 0 ? void 0 : element.wireTap) !== undefined) {
            def.wireTap = CamelDefinitionApi.createWireTapDefinition(element.wireTap);
        }
        if ((element === null || element === void 0 ? void 0 : element.serviceCall) !== undefined) {
            def.serviceCall = CamelDefinitionApi.createServiceCallDefinition(element.serviceCall);
        }
        return def;
    };
    CamelDefinitionApi.createBeansDeserializer = function (element) {
        var def = element ? new CamelDefinition_1.BeansDeserializer(__assign({}, element)) : new CamelDefinition_1.BeansDeserializer();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createErrorHandlerBuilderDeserializer = function (element) {
        var def = element ? new CamelDefinition_1.ErrorHandlerBuilderDeserializer(__assign({}, element)) : new CamelDefinition_1.ErrorHandlerBuilderDeserializer();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.deadLetterChannel) !== undefined) {
            def.deadLetterChannel = CamelDefinitionApi.createDeadLetterChannelDefinition(element.deadLetterChannel);
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultErrorHandler) !== undefined) {
            def.defaultErrorHandler = CamelDefinitionApi.createDefaultErrorHandlerDefinition(element.defaultErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.jtaTransactionErrorHandler) !== undefined) {
            def.jtaTransactionErrorHandler = CamelDefinitionApi.createJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.noErrorHandler) !== undefined) {
            def.noErrorHandler = CamelDefinitionApi.createNoErrorHandlerDefinition(element.noErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.refErrorHandler) !== undefined) {
            def.refErrorHandler = CamelDefinitionApi.createRefErrorHandlerDefinition(element.refErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.springTransactionErrorHandler) !== undefined) {
            def.springTransactionErrorHandler = CamelDefinitionApi.createSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler);
        }
        return def;
    };
    CamelDefinitionApi.createNamedBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.NamedBeanDefinition(__assign({}, element)) : new CamelDefinition_1.NamedBeanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createOutputAwareFromDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputAwareFromDefinition(__assign({}, element)) : new CamelDefinition_1.OutputAwareFromDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createAggregateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.AggregateDefinition(__assign({}, element)) : new CamelDefinition_1.AggregateDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.completionPredicate) !== undefined) {
            def.completionPredicate = CamelDefinitionApi.createExpressionSubElementDefinition(element.completionPredicate);
        }
        if ((element === null || element === void 0 ? void 0 : element.completionSizeExpression) !== undefined) {
            def.completionSizeExpression = CamelDefinitionApi.createExpressionSubElementDefinition(element.completionSizeExpression);
        }
        if ((element === null || element === void 0 ? void 0 : element.completionTimeoutExpression) !== undefined) {
            def.completionTimeoutExpression = CamelDefinitionApi.createExpressionSubElementDefinition(element.completionTimeoutExpression);
        }
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            def.correlationExpression = CamelDefinitionApi.createExpressionSubElementDefinition(element.correlationExpression);
        }
        if ((element === null || element === void 0 ? void 0 : element.optimisticLockRetryPolicy) !== undefined) {
            def.optimisticLockRetryPolicy = CamelDefinitionApi.createOptimisticLockRetryPolicyDefinition(element.optimisticLockRetryPolicy);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BeanDefinition(__assign({}, element)) : new CamelDefinition_1.BeanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCatchDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CatchDefinition(__assign({}, element)) : new CamelDefinition_1.CatchDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            def.onWhen = CamelDefinitionApi.createWhenDefinition(element.onWhen);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createChoiceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ChoiceDefinition(__assign({}, element)) : new CamelDefinition_1.ChoiceDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.otherwise) !== undefined) {
            def.otherwise = CamelDefinitionApi.createOtherwiseDefinition(element.otherwise);
        }
        def.when = element && (element === null || element === void 0 ? void 0 : element.when) ? element === null || element === void 0 ? void 0 : element.when.map(function (x) { return CamelDefinitionApi.createWhenDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createCircuitBreakerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CircuitBreakerDefinition(__assign({}, element)) : new CamelDefinition_1.CircuitBreakerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.faultToleranceConfiguration) !== undefined) {
            def.faultToleranceConfiguration = CamelDefinitionApi.createFaultToleranceConfigurationDefinition(element.faultToleranceConfiguration);
        }
        if ((element === null || element === void 0 ? void 0 : element.onFallback) !== undefined) {
            def.onFallback = CamelDefinitionApi.createOnFallbackDefinition(element.onFallback);
        }
        if ((element === null || element === void 0 ? void 0 : element.resilience4jConfiguration) !== undefined) {
            def.resilience4jConfiguration = CamelDefinitionApi.createResilience4jConfigurationDefinition(element.resilience4jConfiguration);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createClaimCheckDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ClaimCheckDefinition(__assign({}, element)) : new CamelDefinition_1.ClaimCheckDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createContextScanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ContextScanDefinition(__assign({}, element)) : new CamelDefinition_1.ContextScanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createConvertBodyDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { type: element };
        }
        var def = element ? new CamelDefinition_1.ConvertBodyDefinition(__assign({}, element)) : new CamelDefinition_1.ConvertBodyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDataFormatDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDelayDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DelayDefinition(__assign({}, element)) : new CamelDefinition_1.DelayDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createDescriptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DescriptionDefinition(__assign({}, element)) : new CamelDefinition_1.DescriptionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDynamicRouterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DynamicRouterDefinition(__assign({}, element)) : new CamelDefinition_1.DynamicRouterDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createEnrichDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EnrichDefinition(__assign({}, element)) : new CamelDefinition_1.EnrichDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.ErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.deadLetterChannel) !== undefined) {
            def.deadLetterChannel = CamelDefinitionApi.createDeadLetterChannelDefinition(element.deadLetterChannel);
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultErrorHandler) !== undefined) {
            def.defaultErrorHandler = CamelDefinitionApi.createDefaultErrorHandlerDefinition(element.defaultErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.jtaTransactionErrorHandler) !== undefined) {
            def.jtaTransactionErrorHandler = CamelDefinitionApi.createJtaTransactionErrorHandlerDefinition(element.jtaTransactionErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.noErrorHandler) !== undefined) {
            def.noErrorHandler = CamelDefinitionApi.createNoErrorHandlerDefinition(element.noErrorHandler);
        }
        if ((element === null || element === void 0 ? void 0 : element.springTransactionErrorHandler) !== undefined) {
            def.springTransactionErrorHandler = CamelDefinitionApi.createSpringTransactionErrorHandlerDefinition(element.springTransactionErrorHandler);
        }
        return def;
    };
    CamelDefinitionApi.createExpressionSubElementDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ExpressionSubElementDefinition(__assign({}, element)) : new CamelDefinition_1.ExpressionSubElementDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.constant) !== undefined) {
            def.constant = CamelDefinitionApi.createConstantExpression(element.constant);
        }
        if ((element === null || element === void 0 ? void 0 : element.csimple) !== undefined) {
            def.csimple = CamelDefinitionApi.createCSimpleExpression(element.csimple);
        }
        if ((element === null || element === void 0 ? void 0 : element.datasonnet) !== undefined) {
            def.datasonnet = CamelDefinitionApi.createDatasonnetExpression(element.datasonnet);
        }
        if ((element === null || element === void 0 ? void 0 : element.exchangeProperty) !== undefined) {
            def.exchangeProperty = CamelDefinitionApi.createExchangePropertyExpression(element.exchangeProperty);
        }
        if ((element === null || element === void 0 ? void 0 : element.groovy) !== undefined) {
            def.groovy = CamelDefinitionApi.createGroovyExpression(element.groovy);
        }
        if ((element === null || element === void 0 ? void 0 : element.header) !== undefined) {
            def.header = CamelDefinitionApi.createHeaderExpression(element.header);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7terser) !== undefined) {
            def.hl7terser = CamelDefinitionApi.createHl7TerserExpression(element.hl7terser);
        }
        if ((element === null || element === void 0 ? void 0 : element.joor) !== undefined) {
            def.joor = CamelDefinitionApi.createJoorExpression(element.joor);
        }
        if ((element === null || element === void 0 ? void 0 : element.jq) !== undefined) {
            def.jq = CamelDefinitionApi.createJqExpression(element.jq);
        }
        if ((element === null || element === void 0 ? void 0 : element.js) !== undefined) {
            def.js = CamelDefinitionApi.createJavaScriptExpression(element.js);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonpath) !== undefined) {
            def.jsonpath = CamelDefinitionApi.createJsonPathExpression(element.jsonpath);
        }
        if ((element === null || element === void 0 ? void 0 : element.language) !== undefined) {
            def.language = CamelDefinitionApi.createLanguageExpression(element.language);
        }
        if ((element === null || element === void 0 ? void 0 : element.method) !== undefined) {
            def.method = CamelDefinitionApi.createMethodCallExpression(element.method);
        }
        if ((element === null || element === void 0 ? void 0 : element.mvel) !== undefined) {
            def.mvel = CamelDefinitionApi.createMvelExpression(element.mvel);
        }
        if ((element === null || element === void 0 ? void 0 : element.ognl) !== undefined) {
            def.ognl = CamelDefinitionApi.createOgnlExpression(element.ognl);
        }
        if ((element === null || element === void 0 ? void 0 : element.python) !== undefined) {
            def.python = CamelDefinitionApi.createPythonExpression(element.python);
        }
        if ((element === null || element === void 0 ? void 0 : element.ref) !== undefined) {
            def.ref = CamelDefinitionApi.createRefExpression(element.ref);
        }
        if ((element === null || element === void 0 ? void 0 : element.simple) !== undefined) {
            def.simple = CamelDefinitionApi.createSimpleExpression(element.simple);
        }
        if ((element === null || element === void 0 ? void 0 : element.spel) !== undefined) {
            def.spel = CamelDefinitionApi.createSpELExpression(element.spel);
        }
        if ((element === null || element === void 0 ? void 0 : element.tokenize) !== undefined) {
            def.tokenize = CamelDefinitionApi.createTokenizerExpression(element.tokenize);
        }
        if ((element === null || element === void 0 ? void 0 : element.xpath) !== undefined) {
            def.xpath = CamelDefinitionApi.createXPathExpression(element.xpath);
        }
        if ((element === null || element === void 0 ? void 0 : element.xquery) !== undefined) {
            def.xquery = CamelDefinitionApi.createXQueryExpression(element.xquery);
        }
        if ((element === null || element === void 0 ? void 0 : element.xtokenize) !== undefined) {
            def.xtokenize = CamelDefinitionApi.createXMLTokenizerExpression(element.xtokenize);
        }
        return def;
    };
    CamelDefinitionApi.createFaultToleranceConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FaultToleranceConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.FaultToleranceConfigurationDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createFilterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FilterDefinition(__assign({}, element)) : new CamelDefinition_1.FilterDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createFinallyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FinallyDefinition(__assign({}, element)) : new CamelDefinition_1.FinallyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createFromDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.FromDefinition(__assign({}, element)) : new CamelDefinition_1.FromDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createGlobalOptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GlobalOptionDefinition(__assign({}, element)) : new CamelDefinition_1.GlobalOptionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createGlobalOptionsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GlobalOptionsDefinition(__assign({}, element)) : new CamelDefinition_1.GlobalOptionsDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.globalOption = element && (element === null || element === void 0 ? void 0 : element.globalOption) ? element === null || element === void 0 ? void 0 : element.globalOption.map(function (x) { return CamelDefinitionApi.createGlobalOptionDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createIdempotentConsumerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.IdempotentConsumerDefinition(__assign({}, element)) : new CamelDefinition_1.IdempotentConsumerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createInOnlyDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.InOnlyDefinition(__assign({}, element)) : new CamelDefinition_1.InOnlyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createInOutDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.InOutDefinition(__assign({}, element)) : new CamelDefinition_1.InOutDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createInputTypeDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InputTypeDefinition(__assign({}, element)) : new CamelDefinition_1.InputTypeDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createInterceptDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InterceptDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createInterceptFromDefinition = function (element) {
        var def = element ? new CamelDefinition_1.InterceptFromDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptFromDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createInterceptSendToEndpointDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.InterceptSendToEndpointDefinition(__assign({}, element)) : new CamelDefinition_1.InterceptSendToEndpointDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createKameletDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { name: element };
        }
        var def = element ? new CamelDefinition_1.KameletDefinition(__assign({}, element)) : new CamelDefinition_1.KameletDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createLoadBalanceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.LoadBalanceDefinition(__assign({}, element)) : new CamelDefinition_1.LoadBalanceDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.customLoadBalancer) !== undefined) {
            def.customLoadBalancer = CamelDefinitionApi.createCustomLoadBalancerDefinition(element.customLoadBalancer);
        }
        if ((element === null || element === void 0 ? void 0 : element.failover) !== undefined) {
            def.failover = CamelDefinitionApi.createFailoverLoadBalancerDefinition(element.failover);
        }
        if ((element === null || element === void 0 ? void 0 : element.random) !== undefined) {
            def.random = CamelDefinitionApi.createRandomLoadBalancerDefinition(element.random);
        }
        if ((element === null || element === void 0 ? void 0 : element.roundRobin) !== undefined) {
            def.roundRobin = CamelDefinitionApi.createRoundRobinLoadBalancerDefinition(element.roundRobin);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        if ((element === null || element === void 0 ? void 0 : element.sticky) !== undefined) {
            def.sticky = CamelDefinitionApi.createStickyLoadBalancerDefinition(element.sticky);
        }
        if ((element === null || element === void 0 ? void 0 : element.topic) !== undefined) {
            def.topic = CamelDefinitionApi.createTopicLoadBalancerDefinition(element.topic);
        }
        if ((element === null || element === void 0 ? void 0 : element.weighted) !== undefined) {
            def.weighted = CamelDefinitionApi.createWeightedLoadBalancerDefinition(element.weighted);
        }
        return def;
    };
    CamelDefinitionApi.createLogDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { message: element };
        }
        var def = element ? new CamelDefinition_1.LogDefinition(__assign({}, element)) : new CamelDefinition_1.LogDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createLoopDefinition = function (element) {
        var def = element ? new CamelDefinition_1.LoopDefinition(__assign({}, element)) : new CamelDefinition_1.LoopDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createMarshalDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MarshalDefinition(__assign({}, element)) : new CamelDefinition_1.MarshalDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            def.any23 = CamelDefinitionApi.createAny23DataFormat(element.any23);
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            def.asn1 = CamelDefinitionApi.createASN1DataFormat(element.asn1);
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            def.avro = CamelDefinitionApi.createAvroDataFormat(element.avro);
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            def.barcode = CamelDefinitionApi.createBarcodeDataFormat(element.barcode);
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            def.base64 = CamelDefinitionApi.createBase64DataFormat(element.base64);
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            def.bindy = CamelDefinitionApi.createBindyDataFormat(element.bindy);
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            def.cbor = CamelDefinitionApi.createCBORDataFormat(element.cbor);
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            def.crypto = CamelDefinitionApi.createCryptoDataFormat(element.crypto);
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            def.csv = CamelDefinitionApi.createCsvDataFormat(element.csv);
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            def.custom = CamelDefinitionApi.createCustomDataFormat(element.custom);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            def.fhirJson = CamelDefinitionApi.createFhirJsonDataFormat(element.fhirJson);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            def.fhirXml = CamelDefinitionApi.createFhirXmlDataFormat(element.fhirXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            def.flatpack = CamelDefinitionApi.createFlatpackDataFormat(element.flatpack);
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            def.grok = CamelDefinitionApi.createGrokDataFormat(element.grok);
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            def.gzipDeflater = CamelDefinitionApi.createGzipDeflaterDataFormat(element.gzipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            def.hl7 = CamelDefinitionApi.createHL7DataFormat(element.hl7);
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            def.ical = CamelDefinitionApi.createIcalDataFormat(element.ical);
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            def.jacksonXml = CamelDefinitionApi.createJacksonXMLDataFormat(element.jacksonXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            def.jaxb = CamelDefinitionApi.createJaxbDataFormat(element.jaxb);
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            def.json = CamelDefinitionApi.createJsonDataFormat(element.json);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            def.jsonApi = CamelDefinitionApi.createJsonApiDataFormat(element.jsonApi);
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            def.lzf = CamelDefinitionApi.createLZFDataFormat(element.lzf);
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            def.mimeMultipart = CamelDefinitionApi.createMimeMultipartDataFormat(element.mimeMultipart);
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            def.pgp = CamelDefinitionApi.createPGPDataFormat(element.pgp);
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            def.protobuf = CamelDefinitionApi.createProtobufDataFormat(element.protobuf);
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            def.rss = CamelDefinitionApi.createRssDataFormat(element.rss);
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            def.soap = CamelDefinitionApi.createSoapDataFormat(element.soap);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            def.swiftMt = CamelDefinitionApi.createSwiftMtDataFormat(element.swiftMt);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            def.swiftMx = CamelDefinitionApi.createSwiftMxDataFormat(element.swiftMx);
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            def.syslog = CamelDefinitionApi.createSyslogDataFormat(element.syslog);
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            def.tarFile = CamelDefinitionApi.createTarFileDataFormat(element.tarFile);
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            def.thrift = CamelDefinitionApi.createThriftDataFormat(element.thrift);
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            def.tidyMarkup = CamelDefinitionApi.createTidyMarkupDataFormat(element.tidyMarkup);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            def.univocityCsv = CamelDefinitionApi.createUniVocityCsvDataFormat(element.univocityCsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            def.univocityFixed = CamelDefinitionApi.createUniVocityFixedDataFormat(element.univocityFixed);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            def.univocityTsv = CamelDefinitionApi.createUniVocityTsvDataFormat(element.univocityTsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            def.xmlSecurity = CamelDefinitionApi.createXMLSecurityDataFormat(element.xmlSecurity);
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            def.xstream = CamelDefinitionApi.createXStreamDataFormat(element.xstream);
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            def.yaml = CamelDefinitionApi.createYAMLDataFormat(element.yaml);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            def.zipDeflater = CamelDefinitionApi.createZipDeflaterDataFormat(element.zipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            def.zipFile = CamelDefinitionApi.createZipFileDataFormat(element.zipFile);
        }
        return def;
    };
    CamelDefinitionApi.createMulticastDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MulticastDefinition(__assign({}, element)) : new CamelDefinition_1.MulticastDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOnCompletionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnCompletionDefinition(__assign({}, element)) : new CamelDefinition_1.OnCompletionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            def.onWhen = CamelDefinitionApi.createWhenDefinition(element.onWhen);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOnExceptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnExceptionDefinition(__assign({}, element)) : new CamelDefinition_1.OnExceptionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.continued) !== undefined) {
            def.continued = CamelDefinitionApi.createExpressionSubElementDefinition(element.continued);
        }
        if ((element === null || element === void 0 ? void 0 : element.handled) !== undefined) {
            def.handled = CamelDefinitionApi.createExpressionSubElementDefinition(element.handled);
        }
        if ((element === null || element === void 0 ? void 0 : element.onWhen) !== undefined) {
            def.onWhen = CamelDefinitionApi.createWhenDefinition(element.onWhen);
        }
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            def.redeliveryPolicy = CamelDefinitionApi.createRedeliveryPolicyDefinition(element.redeliveryPolicy);
        }
        if ((element === null || element === void 0 ? void 0 : element.retryWhile) !== undefined) {
            def.retryWhile = CamelDefinitionApi.createExpressionSubElementDefinition(element.retryWhile);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOnFallbackDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OnFallbackDefinition(__assign({}, element)) : new CamelDefinition_1.OnFallbackDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOptimisticLockRetryPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OptimisticLockRetryPolicyDefinition(__assign({}, element)) : new CamelDefinition_1.OptimisticLockRetryPolicyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createOtherwiseDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OtherwiseDefinition(__assign({}, element)) : new CamelDefinition_1.OtherwiseDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOutputDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputDefinition(__assign({}, element)) : new CamelDefinition_1.OutputDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createOutputTypeDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OutputTypeDefinition(__assign({}, element)) : new CamelDefinition_1.OutputTypeDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPackageScanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PackageScanDefinition(__assign({}, element)) : new CamelDefinition_1.PackageScanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPausableDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PausableDefinition(__assign({}, element)) : new CamelDefinition_1.PausableDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPipelineDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PipelineDefinition(__assign({}, element)) : new CamelDefinition_1.PipelineDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PolicyDefinition(__assign({}, element)) : new CamelDefinition_1.PolicyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createPollEnrichDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PollEnrichDefinition(__assign({}, element)) : new CamelDefinition_1.PollEnrichDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createProcessDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ProcessDefinition(__assign({}, element)) : new CamelDefinition_1.ProcessDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PropertyDefinition(__assign({}, element)) : new CamelDefinition_1.PropertyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPropertyExpressionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PropertyExpressionDefinition(__assign({}, element)) : new CamelDefinition_1.PropertyExpressionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createRecipientListDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RecipientListDefinition(__assign({}, element)) : new CamelDefinition_1.RecipientListDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createRedeliveryPolicyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RedeliveryPolicyDefinition(__assign({}, element)) : new CamelDefinition_1.RedeliveryPolicyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRemoveHeaderDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { name: element };
        }
        var def = element ? new CamelDefinition_1.RemoveHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.RemoveHeaderDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRemoveHeadersDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { pattern: element };
        }
        var def = element ? new CamelDefinition_1.RemoveHeadersDefinition(__assign({}, element)) : new CamelDefinition_1.RemoveHeadersDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRemovePropertiesDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { pattern: element };
        }
        var def = element ? new CamelDefinition_1.RemovePropertiesDefinition(__assign({}, element)) : new CamelDefinition_1.RemovePropertiesDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRemovePropertyDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { name: element };
        }
        var def = element ? new CamelDefinition_1.RemovePropertyDefinition(__assign({}, element)) : new CamelDefinition_1.RemovePropertyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createResequenceDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResequenceDefinition(__assign({}, element)) : new CamelDefinition_1.ResequenceDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.batchConfig) !== undefined) {
            def.batchConfig = CamelDefinitionApi.createBatchResequencerConfig(element.batchConfig);
        }
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        if ((element === null || element === void 0 ? void 0 : element.streamConfig) !== undefined) {
            def.streamConfig = CamelDefinitionApi.createStreamResequencerConfig(element.streamConfig);
        }
        return def;
    };
    CamelDefinitionApi.createResilience4jConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.Resilience4jConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.Resilience4jConfigurationDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRestContextRefDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.RestContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RestContextRefDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createResumableDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResumableDefinition(__assign({}, element)) : new CamelDefinition_1.ResumableDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRollbackDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RollbackDefinition(__assign({}, element)) : new CamelDefinition_1.RollbackDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRouteBuilderDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.RouteBuilderDefinition(__assign({}, element)) : new CamelDefinition_1.RouteBuilderDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRouteConfigurationContextRefDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.RouteConfigurationContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RouteConfigurationContextRefDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRouteConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.RouteConfigurationDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.errorHandler) !== undefined) {
            def.errorHandler = CamelDefinitionApi.createErrorHandlerDefinition(element.errorHandler);
        }
        def.intercept = element && (element === null || element === void 0 ? void 0 : element.intercept) ? element === null || element === void 0 ? void 0 : element.intercept.map(function (x) { return CamelDefinitionApi.createInterceptDefinition(x); }) : [];
        def.interceptFrom = element && (element === null || element === void 0 ? void 0 : element.interceptFrom) ? element === null || element === void 0 ? void 0 : element.interceptFrom.map(function (x) { return CamelDefinitionApi.createInterceptFromDefinition(x); }) : [];
        def.interceptSendToEndpoint = element && (element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint) ? element === null || element === void 0 ? void 0 : element.interceptSendToEndpoint.map(function (x) { return CamelDefinitionApi.createInterceptSendToEndpointDefinition(x); }) : [];
        def.onCompletion = element && (element === null || element === void 0 ? void 0 : element.onCompletion) ? element === null || element === void 0 ? void 0 : element.onCompletion.map(function (x) { return CamelDefinitionApi.createOnCompletionDefinition(x); }) : [];
        def.onException = element && (element === null || element === void 0 ? void 0 : element.onException) ? element === null || element === void 0 ? void 0 : element.onException.map(function (x) { return CamelDefinitionApi.createOnExceptionDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createRouteContextRefDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.RouteContextRefDefinition(__assign({}, element)) : new CamelDefinition_1.RouteContextRefDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRouteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteDefinition(__assign({}, element)) : new CamelDefinition_1.RouteDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.from) !== undefined) {
            def.from = CamelDefinitionApi.createFromDefinition(element.from);
        }
        return def;
    };
    CamelDefinitionApi.createRouteTemplateBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateBeanDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateBeanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.property = element && (element === null || element === void 0 ? void 0 : element.property) ? element === null || element === void 0 ? void 0 : element.property.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createRouteTemplateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.beans = element && (element === null || element === void 0 ? void 0 : element.beans) ? element === null || element === void 0 ? void 0 : element.beans.map(function (x) { return CamelDefinitionApi.createNamedBeanDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.from) !== undefined) {
            def.from = CamelDefinitionApi.createFromDefinition(element.from);
        }
        def.parameters = element && (element === null || element === void 0 ? void 0 : element.parameters) ? element === null || element === void 0 ? void 0 : element.parameters.map(function (x) { return CamelDefinitionApi.createRouteTemplateParameterDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.route) !== undefined) {
            def.route = CamelDefinitionApi.createRouteDefinition(element.route);
        }
        return def;
    };
    CamelDefinitionApi.createRouteTemplateParameterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RouteTemplateParameterDefinition(__assign({}, element)) : new CamelDefinition_1.RouteTemplateParameterDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRoutingSlipDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RoutingSlipDefinition(__assign({}, element)) : new CamelDefinition_1.RoutingSlipDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSagaActionUriDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.SagaActionUriDefinition(__assign({}, element)) : new CamelDefinition_1.SagaActionUriDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSagaDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SagaDefinition(__assign({}, element)) : new CamelDefinition_1.SagaDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.option = element && (element === null || element === void 0 ? void 0 : element.option) ? element === null || element === void 0 ? void 0 : element.option.map(function (x) { return CamelDefinitionApi.createPropertyExpressionDefinition(x); }) : [];
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createSamplingDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SamplingDefinition(__assign({}, element)) : new CamelDefinition_1.SamplingDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createScriptDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ScriptDefinition(__assign({}, element)) : new CamelDefinition_1.ScriptDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSetBodyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetBodyDefinition(__assign({}, element)) : new CamelDefinition_1.SetBodyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSetExchangePatternDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { pattern: element };
        }
        var def = element ? new CamelDefinition_1.SetExchangePatternDefinition(__assign({}, element)) : new CamelDefinition_1.SetExchangePatternDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSetHeaderDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.SetHeaderDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSetPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SetPropertyDefinition(__assign({}, element)) : new CamelDefinition_1.SetPropertyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSortDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SortDefinition(__assign({}, element)) : new CamelDefinition_1.SortDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createSplitDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SplitDefinition(__assign({}, element)) : new CamelDefinition_1.SplitDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createStepDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StepDefinition(__assign({}, element)) : new CamelDefinition_1.StepDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createStopDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StopDefinition(__assign({}, element)) : new CamelDefinition_1.StopDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTemplatedRouteBeanDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteBeanDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteBeanDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.property = element && (element === null || element === void 0 ? void 0 : element.property) ? element === null || element === void 0 ? void 0 : element.property.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createTemplatedRouteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.beans = element && (element === null || element === void 0 ? void 0 : element.beans) ? element === null || element === void 0 ? void 0 : element.beans.map(function (x) { return CamelDefinitionApi.createNamedBeanDefinition(x); }) : [];
        def.parameters = element && (element === null || element === void 0 ? void 0 : element.parameters) ? element === null || element === void 0 ? void 0 : element.parameters.map(function (x) { return CamelDefinitionApi.createTemplatedRouteParameterDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createTemplatedRouteParameterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TemplatedRouteParameterDefinition(__assign({}, element)) : new CamelDefinition_1.TemplatedRouteParameterDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createThreadPoolProfileDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThreadPoolProfileDefinition(__assign({}, element)) : new CamelDefinition_1.ThreadPoolProfileDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createThreadsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThreadsDefinition(__assign({}, element)) : new CamelDefinition_1.ThreadsDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createThrottleDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThrottleDefinition(__assign({}, element)) : new CamelDefinition_1.ThrottleDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            def.correlationExpression = CamelDefinitionApi.createExpressionSubElementDefinition(element.correlationExpression);
        }
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createThrowExceptionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ThrowExceptionDefinition(__assign({}, element)) : new CamelDefinition_1.ThrowExceptionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createToDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.ToDefinition(__assign({}, element)) : new CamelDefinition_1.ToDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createToDynamicDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { uri: element };
        }
        var def = element ? new CamelDefinition_1.ToDynamicDefinition(__assign({}, element)) : new CamelDefinition_1.ToDynamicDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTransactedDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransactedDefinition(__assign({}, element)) : new CamelDefinition_1.TransactedDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createTransformDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransformDefinition(__assign({}, element)) : new CamelDefinition_1.TransformDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createTryDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TryDefinition(__assign({}, element)) : new CamelDefinition_1.TryDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.doCatch = element && (element === null || element === void 0 ? void 0 : element.doCatch) ? element === null || element === void 0 ? void 0 : element.doCatch.map(function (x) { return CamelDefinitionApi.createCatchDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.doFinally) !== undefined) {
            def.doFinally = CamelDefinitionApi.createFinallyDefinition(element.doFinally);
        }
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createUnmarshalDefinition = function (element) {
        var def = element ? new CamelDefinition_1.UnmarshalDefinition(__assign({}, element)) : new CamelDefinition_1.UnmarshalDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            def.any23 = CamelDefinitionApi.createAny23DataFormat(element.any23);
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            def.asn1 = CamelDefinitionApi.createASN1DataFormat(element.asn1);
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            def.avro = CamelDefinitionApi.createAvroDataFormat(element.avro);
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            def.barcode = CamelDefinitionApi.createBarcodeDataFormat(element.barcode);
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            def.base64 = CamelDefinitionApi.createBase64DataFormat(element.base64);
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            def.bindy = CamelDefinitionApi.createBindyDataFormat(element.bindy);
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            def.cbor = CamelDefinitionApi.createCBORDataFormat(element.cbor);
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            def.crypto = CamelDefinitionApi.createCryptoDataFormat(element.crypto);
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            def.csv = CamelDefinitionApi.createCsvDataFormat(element.csv);
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            def.custom = CamelDefinitionApi.createCustomDataFormat(element.custom);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            def.fhirJson = CamelDefinitionApi.createFhirJsonDataFormat(element.fhirJson);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            def.fhirXml = CamelDefinitionApi.createFhirXmlDataFormat(element.fhirXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            def.flatpack = CamelDefinitionApi.createFlatpackDataFormat(element.flatpack);
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            def.grok = CamelDefinitionApi.createGrokDataFormat(element.grok);
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            def.gzipDeflater = CamelDefinitionApi.createGzipDeflaterDataFormat(element.gzipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            def.hl7 = CamelDefinitionApi.createHL7DataFormat(element.hl7);
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            def.ical = CamelDefinitionApi.createIcalDataFormat(element.ical);
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            def.jacksonXml = CamelDefinitionApi.createJacksonXMLDataFormat(element.jacksonXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            def.jaxb = CamelDefinitionApi.createJaxbDataFormat(element.jaxb);
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            def.json = CamelDefinitionApi.createJsonDataFormat(element.json);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            def.jsonApi = CamelDefinitionApi.createJsonApiDataFormat(element.jsonApi);
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            def.lzf = CamelDefinitionApi.createLZFDataFormat(element.lzf);
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            def.mimeMultipart = CamelDefinitionApi.createMimeMultipartDataFormat(element.mimeMultipart);
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            def.pgp = CamelDefinitionApi.createPGPDataFormat(element.pgp);
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            def.protobuf = CamelDefinitionApi.createProtobufDataFormat(element.protobuf);
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            def.rss = CamelDefinitionApi.createRssDataFormat(element.rss);
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            def.soap = CamelDefinitionApi.createSoapDataFormat(element.soap);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            def.swiftMt = CamelDefinitionApi.createSwiftMtDataFormat(element.swiftMt);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            def.swiftMx = CamelDefinitionApi.createSwiftMxDataFormat(element.swiftMx);
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            def.syslog = CamelDefinitionApi.createSyslogDataFormat(element.syslog);
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            def.tarFile = CamelDefinitionApi.createTarFileDataFormat(element.tarFile);
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            def.thrift = CamelDefinitionApi.createThriftDataFormat(element.thrift);
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            def.tidyMarkup = CamelDefinitionApi.createTidyMarkupDataFormat(element.tidyMarkup);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            def.univocityCsv = CamelDefinitionApi.createUniVocityCsvDataFormat(element.univocityCsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            def.univocityFixed = CamelDefinitionApi.createUniVocityFixedDataFormat(element.univocityFixed);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            def.univocityTsv = CamelDefinitionApi.createUniVocityTsvDataFormat(element.univocityTsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            def.xmlSecurity = CamelDefinitionApi.createXMLSecurityDataFormat(element.xmlSecurity);
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            def.xstream = CamelDefinitionApi.createXStreamDataFormat(element.xstream);
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            def.yaml = CamelDefinitionApi.createYAMLDataFormat(element.yaml);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            def.zipDeflater = CamelDefinitionApi.createZipDeflaterDataFormat(element.zipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            def.zipFile = CamelDefinitionApi.createZipFileDataFormat(element.zipFile);
        }
        return def;
    };
    CamelDefinitionApi.createValidateDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValidateDefinition(__assign({}, element)) : new CamelDefinition_1.ValidateDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createValueDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValueDefinition(__assign({}, element)) : new CamelDefinition_1.ValueDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createWhenDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WhenDefinition(__assign({}, element)) : new CamelDefinition_1.WhenDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createWhenSkipSendToEndpointDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WhenSkipSendToEndpointDefinition(__assign({}, element)) : new CamelDefinition_1.WhenSkipSendToEndpointDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        def.steps = CamelDefinitionApi.createSteps(element === null || element === void 0 ? void 0 : element.steps);
        return def;
    };
    CamelDefinitionApi.createWireTapDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WireTapDefinition(__assign({}, element)) : new CamelDefinition_1.WireTapDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBlacklistServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.BlacklistServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.BlacklistServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createCachingServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CachingServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.CachingServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            def.combinedServiceDiscovery = CamelDefinitionApi.createCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            def.consulServiceDiscovery = CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            def.dnsServiceDiscovery = CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            def.kubernetesServiceDiscovery = CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            def.staticServiceDiscovery = CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
        }
        return def;
    };
    CamelDefinitionApi.createCombinedServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CombinedServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.CombinedServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            def.cachingServiceDiscovery = CamelDefinitionApi.createCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            def.consulServiceDiscovery = CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            def.dnsServiceDiscovery = CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            def.kubernetesServiceDiscovery = CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            def.staticServiceDiscovery = CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
        }
        return def;
    };
    CamelDefinitionApi.createCombinedServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CombinedServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.CombinedServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            def.blacklistServiceFilter = CamelDefinitionApi.createBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            def.customServiceFilter = CamelDefinitionApi.createCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            def.healthyServiceFilter = CamelDefinitionApi.createHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            def.passThroughServiceFilter = CamelDefinitionApi.createPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
        }
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ConsulServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ConsulServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createCustomServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.CustomServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.CustomServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createDefaultServiceCallServiceLoadBalancerConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.DefaultServiceCallServiceLoadBalancerConfiguration(__assign({}, element)) : new CamelDefinition_1.DefaultServiceCallServiceLoadBalancerConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.DnsServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.DnsServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createHealthyServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.HealthyServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.HealthyServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.KubernetesServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.KubernetesServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createPassThroughServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.PassThroughServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.PassThroughServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createServiceCallConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.ServiceCallConfigurationDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            def.blacklistServiceFilter = CamelDefinitionApi.createBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            def.cachingServiceDiscovery = CamelDefinitionApi.createCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            def.combinedServiceDiscovery = CamelDefinitionApi.createCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceFilter) !== undefined) {
            def.combinedServiceFilter = CamelDefinitionApi.createCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            def.consulServiceDiscovery = CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            def.customServiceFilter = CamelDefinitionApi.createCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultLoadBalancer) !== undefined) {
            def.defaultLoadBalancer = CamelDefinitionApi.createDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer);
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            def.dnsServiceDiscovery = CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionApi.createServiceCallExpressionConfiguration(element.expression);
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            def.healthyServiceFilter = CamelDefinitionApi.createHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            def.kubernetesServiceDiscovery = CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            def.passThroughServiceFilter = CamelDefinitionApi.createPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            def.staticServiceDiscovery = CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.zookeeperServiceDiscovery) !== undefined) {
            def.zookeeperServiceDiscovery = CamelDefinitionApi.createZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery);
        }
        return def;
    };
    CamelDefinitionApi.createServiceCallDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { name: element };
        }
        var def = element ? new CamelDefinition_1.ServiceCallDefinition(__assign({}, element)) : new CamelDefinition_1.ServiceCallDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.blacklistServiceFilter) !== undefined) {
            def.blacklistServiceFilter = CamelDefinitionApi.createBlacklistServiceCallServiceFilterConfiguration(element.blacklistServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.cachingServiceDiscovery) !== undefined) {
            def.cachingServiceDiscovery = CamelDefinitionApi.createCachingServiceCallServiceDiscoveryConfiguration(element.cachingServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceDiscovery) !== undefined) {
            def.combinedServiceDiscovery = CamelDefinitionApi.createCombinedServiceCallServiceDiscoveryConfiguration(element.combinedServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.combinedServiceFilter) !== undefined) {
            def.combinedServiceFilter = CamelDefinitionApi.createCombinedServiceCallServiceFilterConfiguration(element.combinedServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.consulServiceDiscovery) !== undefined) {
            def.consulServiceDiscovery = CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration(element.consulServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.customServiceFilter) !== undefined) {
            def.customServiceFilter = CamelDefinitionApi.createCustomServiceCallServiceFilterConfiguration(element.customServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.defaultLoadBalancer) !== undefined) {
            def.defaultLoadBalancer = CamelDefinitionApi.createDefaultServiceCallServiceLoadBalancerConfiguration(element.defaultLoadBalancer);
        }
        if ((element === null || element === void 0 ? void 0 : element.dnsServiceDiscovery) !== undefined) {
            def.dnsServiceDiscovery = CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration(element.dnsServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.expression) !== undefined) {
            def.expression = CamelDefinitionApi.createServiceCallExpressionConfiguration(element.expression);
        }
        if ((element === null || element === void 0 ? void 0 : element.healthyServiceFilter) !== undefined) {
            def.healthyServiceFilter = CamelDefinitionApi.createHealthyServiceCallServiceFilterConfiguration(element.healthyServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.kubernetesServiceDiscovery) !== undefined) {
            def.kubernetesServiceDiscovery = CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration(element.kubernetesServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.passThroughServiceFilter) !== undefined) {
            def.passThroughServiceFilter = CamelDefinitionApi.createPassThroughServiceCallServiceFilterConfiguration(element.passThroughServiceFilter);
        }
        if ((element === null || element === void 0 ? void 0 : element.staticServiceDiscovery) !== undefined) {
            def.staticServiceDiscovery = CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration(element.staticServiceDiscovery);
        }
        if ((element === null || element === void 0 ? void 0 : element.zookeeperServiceDiscovery) !== undefined) {
            def.zookeeperServiceDiscovery = CamelDefinitionApi.createZooKeeperServiceCallServiceDiscoveryConfiguration(element.zookeeperServiceDiscovery);
        }
        return def;
    };
    CamelDefinitionApi.createServiceCallExpressionConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallExpressionConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallExpressionConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expressionType = CamelDefinitionApi.createExpressionDefinition(element.expressionType);
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createServiceCallServiceChooserConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceChooserConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceChooserConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createServiceCallServiceFilterConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceFilterConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceFilterConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createServiceCallServiceLoadBalancerConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ServiceCallServiceLoadBalancerConfiguration(__assign({}, element)) : new CamelDefinition_1.ServiceCallServiceLoadBalancerConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.StaticServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.StaticServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createZooKeeperServiceCallServiceDiscoveryConfiguration = function (element) {
        var def = element ? new CamelDefinition_1.ZooKeeperServiceCallServiceDiscoveryConfiguration(__assign({}, element)) : new CamelDefinition_1.ZooKeeperServiceCallServiceDiscoveryConfiguration();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.properties = element && (element === null || element === void 0 ? void 0 : element.properties) ? element === null || element === void 0 ? void 0 : element.properties.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createBatchResequencerConfig = function (element) {
        var def = element ? new CamelDefinition_1.BatchResequencerConfig(__assign({}, element)) : new CamelDefinition_1.BatchResequencerConfig();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createStreamResequencerConfig = function (element) {
        var def = element ? new CamelDefinition_1.StreamResequencerConfig(__assign({}, element)) : new CamelDefinition_1.StreamResequencerConfig();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createASN1DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ASN1DataFormat(__assign({}, element)) : new CamelDefinition_1.ASN1DataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createAny23DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.Any23DataFormat(__assign({}, element)) : new CamelDefinition_1.Any23DataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.configuration = element && (element === null || element === void 0 ? void 0 : element.configuration) ? element === null || element === void 0 ? void 0 : element.configuration.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createAvroDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.AvroDataFormat(__assign({}, element)) : new CamelDefinition_1.AvroDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBarcodeDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.BarcodeDataFormat(__assign({}, element)) : new CamelDefinition_1.BarcodeDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBase64DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.Base64DataFormat(__assign({}, element)) : new CamelDefinition_1.Base64DataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBindyDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.BindyDataFormat(__assign({}, element)) : new CamelDefinition_1.BindyDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCBORDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CBORDataFormat(__assign({}, element)) : new CamelDefinition_1.CBORDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCryptoDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CryptoDataFormat(__assign({}, element)) : new CamelDefinition_1.CryptoDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.CsvDataFormat(__assign({}, element)) : new CamelDefinition_1.CsvDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCustomDataFormat = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.CustomDataFormat(__assign({}, element)) : new CamelDefinition_1.CustomDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDataFormatsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatsDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatsDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            def.any23 = CamelDefinitionApi.createAny23DataFormat(element.any23);
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            def.asn1 = CamelDefinitionApi.createASN1DataFormat(element.asn1);
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            def.avro = CamelDefinitionApi.createAvroDataFormat(element.avro);
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            def.barcode = CamelDefinitionApi.createBarcodeDataFormat(element.barcode);
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            def.base64 = CamelDefinitionApi.createBase64DataFormat(element.base64);
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            def.bindy = CamelDefinitionApi.createBindyDataFormat(element.bindy);
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            def.cbor = CamelDefinitionApi.createCBORDataFormat(element.cbor);
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            def.crypto = CamelDefinitionApi.createCryptoDataFormat(element.crypto);
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            def.csv = CamelDefinitionApi.createCsvDataFormat(element.csv);
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            def.custom = CamelDefinitionApi.createCustomDataFormat(element.custom);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            def.fhirJson = CamelDefinitionApi.createFhirJsonDataFormat(element.fhirJson);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            def.fhirXml = CamelDefinitionApi.createFhirXmlDataFormat(element.fhirXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            def.flatpack = CamelDefinitionApi.createFlatpackDataFormat(element.flatpack);
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            def.grok = CamelDefinitionApi.createGrokDataFormat(element.grok);
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            def.gzipDeflater = CamelDefinitionApi.createGzipDeflaterDataFormat(element.gzipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            def.hl7 = CamelDefinitionApi.createHL7DataFormat(element.hl7);
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            def.ical = CamelDefinitionApi.createIcalDataFormat(element.ical);
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            def.jacksonXml = CamelDefinitionApi.createJacksonXMLDataFormat(element.jacksonXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            def.jaxb = CamelDefinitionApi.createJaxbDataFormat(element.jaxb);
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            def.json = CamelDefinitionApi.createJsonDataFormat(element.json);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            def.jsonApi = CamelDefinitionApi.createJsonApiDataFormat(element.jsonApi);
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            def.lzf = CamelDefinitionApi.createLZFDataFormat(element.lzf);
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            def.mimeMultipart = CamelDefinitionApi.createMimeMultipartDataFormat(element.mimeMultipart);
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            def.pgp = CamelDefinitionApi.createPGPDataFormat(element.pgp);
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            def.protobuf = CamelDefinitionApi.createProtobufDataFormat(element.protobuf);
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            def.rss = CamelDefinitionApi.createRssDataFormat(element.rss);
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            def.soap = CamelDefinitionApi.createSoapDataFormat(element.soap);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            def.swiftMt = CamelDefinitionApi.createSwiftMtDataFormat(element.swiftMt);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            def.swiftMx = CamelDefinitionApi.createSwiftMxDataFormat(element.swiftMx);
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            def.syslog = CamelDefinitionApi.createSyslogDataFormat(element.syslog);
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            def.tarFile = CamelDefinitionApi.createTarFileDataFormat(element.tarFile);
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            def.thrift = CamelDefinitionApi.createThriftDataFormat(element.thrift);
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            def.tidyMarkup = CamelDefinitionApi.createTidyMarkupDataFormat(element.tidyMarkup);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            def.univocityCsv = CamelDefinitionApi.createUniVocityCsvDataFormat(element.univocityCsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            def.univocityFixed = CamelDefinitionApi.createUniVocityFixedDataFormat(element.univocityFixed);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            def.univocityTsv = CamelDefinitionApi.createUniVocityTsvDataFormat(element.univocityTsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            def.xmlSecurity = CamelDefinitionApi.createXMLSecurityDataFormat(element.xmlSecurity);
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            def.xstream = CamelDefinitionApi.createXStreamDataFormat(element.xstream);
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            def.yaml = CamelDefinitionApi.createYAMLDataFormat(element.yaml);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            def.zipDeflater = CamelDefinitionApi.createZipDeflaterDataFormat(element.zipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            def.zipFile = CamelDefinitionApi.createZipFileDataFormat(element.zipFile);
        }
        return def;
    };
    CamelDefinitionApi.createFhirJsonDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FhirJsonDataFormat(__assign({}, element)) : new CamelDefinition_1.FhirJsonDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createFhirXmlDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FhirXmlDataFormat(__assign({}, element)) : new CamelDefinition_1.FhirXmlDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createFlatpackDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.FlatpackDataFormat(__assign({}, element)) : new CamelDefinition_1.FlatpackDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createGrokDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.GrokDataFormat(__assign({}, element)) : new CamelDefinition_1.GrokDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createGzipDeflaterDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.GzipDeflaterDataFormat(__assign({}, element)) : new CamelDefinition_1.GzipDeflaterDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createHL7DataFormat = function (element) {
        var def = element ? new CamelDefinition_1.HL7DataFormat(__assign({}, element)) : new CamelDefinition_1.HL7DataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createIcalDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.IcalDataFormat(__assign({}, element)) : new CamelDefinition_1.IcalDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJacksonXMLDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JacksonXMLDataFormat(__assign({}, element)) : new CamelDefinition_1.JacksonXMLDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJaxbDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JaxbDataFormat(__assign({}, element)) : new CamelDefinition_1.JaxbDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJsonApiDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JsonApiDataFormat(__assign({}, element)) : new CamelDefinition_1.JsonApiDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJsonDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.JsonDataFormat(__assign({}, element)) : new CamelDefinition_1.JsonDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createLZFDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.LZFDataFormat(__assign({}, element)) : new CamelDefinition_1.LZFDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createMimeMultipartDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.MimeMultipartDataFormat(__assign({}, element)) : new CamelDefinition_1.MimeMultipartDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPGPDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.PGPDataFormat(__assign({}, element)) : new CamelDefinition_1.PGPDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createProtobufDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ProtobufDataFormat(__assign({}, element)) : new CamelDefinition_1.ProtobufDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRssDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.RssDataFormat(__assign({}, element)) : new CamelDefinition_1.RssDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSoapDataFormat = function (element) {
        if (element && typeof element === 'string') {
            element = { contextPath: element };
        }
        var def = element ? new CamelDefinition_1.SoapDataFormat(__assign({}, element)) : new CamelDefinition_1.SoapDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSwiftMtDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SwiftMtDataFormat(__assign({}, element)) : new CamelDefinition_1.SwiftMtDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSwiftMxDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SwiftMxDataFormat(__assign({}, element)) : new CamelDefinition_1.SwiftMxDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSyslogDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.SyslogDataFormat(__assign({}, element)) : new CamelDefinition_1.SyslogDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTarFileDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.TarFileDataFormat(__assign({}, element)) : new CamelDefinition_1.TarFileDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createThriftDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ThriftDataFormat(__assign({}, element)) : new CamelDefinition_1.ThriftDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTidyMarkupDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.TidyMarkupDataFormat(__assign({}, element)) : new CamelDefinition_1.TidyMarkupDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createUniVocityCsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityCsvDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityCsvDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionApi.createUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createUniVocityFixedDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityFixedDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityFixedDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionApi.createUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createUniVocityHeader = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityHeader(__assign({}, element)) : new CamelDefinition_1.UniVocityHeader();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createUniVocityTsvDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.UniVocityTsvDataFormat(__assign({}, element)) : new CamelDefinition_1.UniVocityTsvDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.univocityHeader = element && (element === null || element === void 0 ? void 0 : element.univocityHeader) ? element === null || element === void 0 ? void 0 : element.univocityHeader.map(function (x) { return CamelDefinitionApi.createUniVocityHeader(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createXMLSecurityDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.XMLSecurityDataFormat(__assign({}, element)) : new CamelDefinition_1.XMLSecurityDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createXStreamDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.XStreamDataFormat(__assign({}, element)) : new CamelDefinition_1.XStreamDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.aliases = element && (element === null || element === void 0 ? void 0 : element.aliases) ? element === null || element === void 0 ? void 0 : element.aliases.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        def.converters = element && (element === null || element === void 0 ? void 0 : element.converters) ? element === null || element === void 0 ? void 0 : element.converters.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        def.implicitCollections = element && (element === null || element === void 0 ? void 0 : element.implicitCollections) ? element === null || element === void 0 ? void 0 : element.implicitCollections.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        def.omitFields = element && (element === null || element === void 0 ? void 0 : element.omitFields) ? element === null || element === void 0 ? void 0 : element.omitFields.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createYAMLDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.YAMLDataFormat(__assign({}, element)) : new CamelDefinition_1.YAMLDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.typeFilter = element && (element === null || element === void 0 ? void 0 : element.typeFilter) ? element === null || element === void 0 ? void 0 : element.typeFilter.map(function (x) { return CamelDefinitionApi.createYAMLTypeFilterDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createYAMLTypeFilterDefinition = function (element) {
        var def = element ? new CamelDefinition_1.YAMLTypeFilterDefinition(__assign({}, element)) : new CamelDefinition_1.YAMLTypeFilterDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createZipDeflaterDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ZipDeflaterDataFormat(__assign({}, element)) : new CamelDefinition_1.ZipDeflaterDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createZipFileDataFormat = function (element) {
        var def = element ? new CamelDefinition_1.ZipFileDataFormat(__assign({}, element)) : new CamelDefinition_1.ZipFileDataFormat();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDeadLetterChannelDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DeadLetterChannelDefinition(__assign({}, element)) : new CamelDefinition_1.DeadLetterChannelDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            def.redeliveryPolicy = CamelDefinitionApi.createRedeliveryPolicyDefinition(element.redeliveryPolicy);
        }
        return def;
    };
    CamelDefinitionApi.createDefaultErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DefaultErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.DefaultErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            def.redeliveryPolicy = CamelDefinitionApi.createRedeliveryPolicyDefinition(element.redeliveryPolicy);
        }
        return def;
    };
    CamelDefinitionApi.createJtaTransactionErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.JtaTransactionErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.JtaTransactionErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            def.redeliveryPolicy = CamelDefinitionApi.createRedeliveryPolicyDefinition(element.redeliveryPolicy);
        }
        return def;
    };
    CamelDefinitionApi.createNoErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.NoErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.NoErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRefErrorHandlerDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.RefErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.RefErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSpringTransactionErrorHandlerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SpringTransactionErrorHandlerDefinition(__assign({}, element)) : new CamelDefinition_1.SpringTransactionErrorHandlerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.redeliveryPolicy) !== undefined) {
            def.redeliveryPolicy = CamelDefinitionApi.createRedeliveryPolicyDefinition(element.redeliveryPolicy);
        }
        return def;
    };
    CamelDefinitionApi.createCSimpleExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.CSimpleExpression(__assign({}, element)) : new CamelDefinition_1.CSimpleExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createConstantExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.ConstantExpression(__assign({}, element)) : new CamelDefinition_1.ConstantExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDatasonnetExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.DatasonnetExpression(__assign({}, element)) : new CamelDefinition_1.DatasonnetExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createExchangePropertyExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.ExchangePropertyExpression(__assign({}, element)) : new CamelDefinition_1.ExchangePropertyExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createExpressionDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ExpressionDefinition(__assign({}, element)) : new CamelDefinition_1.ExpressionDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.constant) !== undefined) {
            def.constant = CamelDefinitionApi.createConstantExpression(element.constant);
        }
        if ((element === null || element === void 0 ? void 0 : element.csimple) !== undefined) {
            def.csimple = CamelDefinitionApi.createCSimpleExpression(element.csimple);
        }
        if ((element === null || element === void 0 ? void 0 : element.datasonnet) !== undefined) {
            def.datasonnet = CamelDefinitionApi.createDatasonnetExpression(element.datasonnet);
        }
        if ((element === null || element === void 0 ? void 0 : element.exchangeProperty) !== undefined) {
            def.exchangeProperty = CamelDefinitionApi.createExchangePropertyExpression(element.exchangeProperty);
        }
        if ((element === null || element === void 0 ? void 0 : element.groovy) !== undefined) {
            def.groovy = CamelDefinitionApi.createGroovyExpression(element.groovy);
        }
        if ((element === null || element === void 0 ? void 0 : element.header) !== undefined) {
            def.header = CamelDefinitionApi.createHeaderExpression(element.header);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7terser) !== undefined) {
            def.hl7terser = CamelDefinitionApi.createHl7TerserExpression(element.hl7terser);
        }
        if ((element === null || element === void 0 ? void 0 : element.joor) !== undefined) {
            def.joor = CamelDefinitionApi.createJoorExpression(element.joor);
        }
        if ((element === null || element === void 0 ? void 0 : element.jq) !== undefined) {
            def.jq = CamelDefinitionApi.createJqExpression(element.jq);
        }
        if ((element === null || element === void 0 ? void 0 : element.js) !== undefined) {
            def.js = CamelDefinitionApi.createJavaScriptExpression(element.js);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonpath) !== undefined) {
            def.jsonpath = CamelDefinitionApi.createJsonPathExpression(element.jsonpath);
        }
        if ((element === null || element === void 0 ? void 0 : element.language) !== undefined) {
            def.language = CamelDefinitionApi.createLanguageExpression(element.language);
        }
        if ((element === null || element === void 0 ? void 0 : element.method) !== undefined) {
            def.method = CamelDefinitionApi.createMethodCallExpression(element.method);
        }
        if ((element === null || element === void 0 ? void 0 : element.mvel) !== undefined) {
            def.mvel = CamelDefinitionApi.createMvelExpression(element.mvel);
        }
        if ((element === null || element === void 0 ? void 0 : element.ognl) !== undefined) {
            def.ognl = CamelDefinitionApi.createOgnlExpression(element.ognl);
        }
        if ((element === null || element === void 0 ? void 0 : element.python) !== undefined) {
            def.python = CamelDefinitionApi.createPythonExpression(element.python);
        }
        if ((element === null || element === void 0 ? void 0 : element.ref) !== undefined) {
            def.ref = CamelDefinitionApi.createRefExpression(element.ref);
        }
        if ((element === null || element === void 0 ? void 0 : element.simple) !== undefined) {
            def.simple = CamelDefinitionApi.createSimpleExpression(element.simple);
        }
        if ((element === null || element === void 0 ? void 0 : element.spel) !== undefined) {
            def.spel = CamelDefinitionApi.createSpELExpression(element.spel);
        }
        if ((element === null || element === void 0 ? void 0 : element.tokenize) !== undefined) {
            def.tokenize = CamelDefinitionApi.createTokenizerExpression(element.tokenize);
        }
        if ((element === null || element === void 0 ? void 0 : element.xpath) !== undefined) {
            def.xpath = CamelDefinitionApi.createXPathExpression(element.xpath);
        }
        if ((element === null || element === void 0 ? void 0 : element.xquery) !== undefined) {
            def.xquery = CamelDefinitionApi.createXQueryExpression(element.xquery);
        }
        if ((element === null || element === void 0 ? void 0 : element.xtokenize) !== undefined) {
            def.xtokenize = CamelDefinitionApi.createXMLTokenizerExpression(element.xtokenize);
        }
        return def;
    };
    CamelDefinitionApi.createGroovyExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.GroovyExpression(__assign({}, element)) : new CamelDefinition_1.GroovyExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createHeaderExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.HeaderExpression(__assign({}, element)) : new CamelDefinition_1.HeaderExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createHl7TerserExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.Hl7TerserExpression(__assign({}, element)) : new CamelDefinition_1.Hl7TerserExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJavaScriptExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.JavaScriptExpression(__assign({}, element)) : new CamelDefinition_1.JavaScriptExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJoorExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.JoorExpression(__assign({}, element)) : new CamelDefinition_1.JoorExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJqExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.JqExpression(__assign({}, element)) : new CamelDefinition_1.JqExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createJsonPathExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.JsonPathExpression(__assign({}, element)) : new CamelDefinition_1.JsonPathExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createLanguageExpression = function (element) {
        var def = element ? new CamelDefinition_1.LanguageExpression(__assign({}, element)) : new CamelDefinition_1.LanguageExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createMethodCallExpression = function (element) {
        var def = element ? new CamelDefinition_1.MethodCallExpression(__assign({}, element)) : new CamelDefinition_1.MethodCallExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createMvelExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.MvelExpression(__assign({}, element)) : new CamelDefinition_1.MvelExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createOgnlExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.OgnlExpression(__assign({}, element)) : new CamelDefinition_1.OgnlExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPythonExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.PythonExpression(__assign({}, element)) : new CamelDefinition_1.PythonExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRefExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.RefExpression(__assign({}, element)) : new CamelDefinition_1.RefExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSimpleExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.SimpleExpression(__assign({}, element)) : new CamelDefinition_1.SimpleExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createSpELExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.SpELExpression(__assign({}, element)) : new CamelDefinition_1.SpELExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTokenizerExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { token: element };
        }
        var def = element ? new CamelDefinition_1.TokenizerExpression(__assign({}, element)) : new CamelDefinition_1.TokenizerExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createXMLTokenizerExpression = function (element) {
        var def = element ? new CamelDefinition_1.XMLTokenizerExpression(__assign({}, element)) : new CamelDefinition_1.XMLTokenizerExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createXPathExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.XPathExpression(__assign({}, element)) : new CamelDefinition_1.XPathExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createXQueryExpression = function (element) {
        if (element && typeof element === 'string') {
            element = { expression: element };
        }
        var def = element ? new CamelDefinition_1.XQueryExpression(__assign({}, element)) : new CamelDefinition_1.XQueryExpression();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.namespace = element && (element === null || element === void 0 ? void 0 : element.namespace) ? element === null || element === void 0 ? void 0 : element.namespace.map(function (x) { return CamelDefinitionApi.createPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createCustomLoadBalancerDefinition = function (element) {
        if (element && typeof element === 'string') {
            element = { ref: element };
        }
        var def = element ? new CamelDefinition_1.CustomLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.CustomLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createFailoverLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.FailoverLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.FailoverLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRandomLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RandomLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.RandomLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRoundRobinLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RoundRobinLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.RoundRobinLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createStickyLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.StickyLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.StickyLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.correlationExpression) !== undefined) {
            def.correlationExpression = CamelDefinitionApi.createExpressionSubElementDefinition(element.correlationExpression);
        }
        return def;
    };
    CamelDefinitionApi.createTopicLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TopicLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.TopicLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createWeightedLoadBalancerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.WeightedLoadBalancerDefinition(__assign({}, element)) : new CamelDefinition_1.WeightedLoadBalancerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createApiKeyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ApiKeyDefinition(__assign({}, element)) : new CamelDefinition_1.ApiKeyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBasicAuthDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BasicAuthDefinition(__assign({}, element)) : new CamelDefinition_1.BasicAuthDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createBearerTokenDefinition = function (element) {
        var def = element ? new CamelDefinition_1.BearerTokenDefinition(__assign({}, element)) : new CamelDefinition_1.BearerTokenDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDeleteDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DeleteDefinition(__assign({}, element)) : new CamelDefinition_1.DeleteDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createGetDefinition = function (element) {
        var def = element ? new CamelDefinition_1.GetDefinition(__assign({}, element)) : new CamelDefinition_1.GetDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createHeadDefinition = function (element) {
        var def = element ? new CamelDefinition_1.HeadDefinition(__assign({}, element)) : new CamelDefinition_1.HeadDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createMutualTLSDefinition = function (element) {
        var def = element ? new CamelDefinition_1.MutualTLSDefinition(__assign({}, element)) : new CamelDefinition_1.MutualTLSDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createOAuth2Definition = function (element) {
        var def = element ? new CamelDefinition_1.OAuth2Definition(__assign({}, element)) : new CamelDefinition_1.OAuth2Definition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.scopes = element && (element === null || element === void 0 ? void 0 : element.scopes) ? element === null || element === void 0 ? void 0 : element.scopes.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createOpenIdConnectDefinition = function (element) {
        var def = element ? new CamelDefinition_1.OpenIdConnectDefinition(__assign({}, element)) : new CamelDefinition_1.OpenIdConnectDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createParamDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ParamDefinition(__assign({}, element)) : new CamelDefinition_1.ParamDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.allowableValues = element && (element === null || element === void 0 ? void 0 : element.allowableValues) ? element === null || element === void 0 ? void 0 : element.allowableValues.map(function (x) { return CamelDefinitionApi.createValueDefinition(x); }) : [];
        def.examples = element && (element === null || element === void 0 ? void 0 : element.examples) ? element === null || element === void 0 ? void 0 : element.examples.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createPatchDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PatchDefinition(__assign({}, element)) : new CamelDefinition_1.PatchDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createPostDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PostDefinition(__assign({}, element)) : new CamelDefinition_1.PostDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createPutDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PutDefinition(__assign({}, element)) : new CamelDefinition_1.PutDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.param = element && (element === null || element === void 0 ? void 0 : element.param) ? element === null || element === void 0 ? void 0 : element.param.map(function (x) { return CamelDefinitionApi.createParamDefinition(x); }) : [];
        def.responseMessage = element && (element === null || element === void 0 ? void 0 : element.responseMessage) ? element === null || element === void 0 ? void 0 : element.responseMessage.map(function (x) { return CamelDefinitionApi.createResponseMessageDefinition(x); }) : [];
        def.security = element && (element === null || element === void 0 ? void 0 : element.security) ? element === null || element === void 0 ? void 0 : element.security.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createResponseHeaderDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResponseHeaderDefinition(__assign({}, element)) : new CamelDefinition_1.ResponseHeaderDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.allowableValues = element && (element === null || element === void 0 ? void 0 : element.allowableValues) ? element === null || element === void 0 ? void 0 : element.allowableValues.map(function (x) { return CamelDefinitionApi.createValueDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createResponseMessageDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ResponseMessageDefinition(__assign({}, element)) : new CamelDefinition_1.ResponseMessageDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.examples = element && (element === null || element === void 0 ? void 0 : element.examples) ? element === null || element === void 0 ? void 0 : element.examples.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.header = element && (element === null || element === void 0 ? void 0 : element.header) ? element === null || element === void 0 ? void 0 : element.header.map(function (x) { return CamelDefinitionApi.createResponseHeaderDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createRestBindingDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestBindingDefinition(__assign({}, element)) : new CamelDefinition_1.RestBindingDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRestConfigurationDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestConfigurationDefinition(__assign({}, element)) : new CamelDefinition_1.RestConfigurationDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.apiProperty = element && (element === null || element === void 0 ? void 0 : element.apiProperty) ? element === null || element === void 0 ? void 0 : element.apiProperty.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.componentProperty = element && (element === null || element === void 0 ? void 0 : element.componentProperty) ? element === null || element === void 0 ? void 0 : element.componentProperty.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.consumerProperty = element && (element === null || element === void 0 ? void 0 : element.consumerProperty) ? element === null || element === void 0 ? void 0 : element.consumerProperty.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.corsHeaders = element && (element === null || element === void 0 ? void 0 : element.corsHeaders) ? element === null || element === void 0 ? void 0 : element.corsHeaders.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.dataFormatProperty = element && (element === null || element === void 0 ? void 0 : element.dataFormatProperty) ? element === null || element === void 0 ? void 0 : element.dataFormatProperty.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        def.endpointProperty = element && (element === null || element === void 0 ? void 0 : element.endpointProperty) ? element === null || element === void 0 ? void 0 : element.endpointProperty.map(function (x) { return CamelDefinitionApi.createRestPropertyDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createRestDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestDefinition(__assign({}, element)) : new CamelDefinition_1.RestDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.delete = element && (element === null || element === void 0 ? void 0 : element.delete) ? element === null || element === void 0 ? void 0 : element.delete.map(function (x) { return CamelDefinitionApi.createDeleteDefinition(x); }) : [];
        def.get = element && (element === null || element === void 0 ? void 0 : element.get) ? element === null || element === void 0 ? void 0 : element.get.map(function (x) { return CamelDefinitionApi.createGetDefinition(x); }) : [];
        def.head = element && (element === null || element === void 0 ? void 0 : element.head) ? element === null || element === void 0 ? void 0 : element.head.map(function (x) { return CamelDefinitionApi.createHeadDefinition(x); }) : [];
        def.patch = element && (element === null || element === void 0 ? void 0 : element.patch) ? element === null || element === void 0 ? void 0 : element.patch.map(function (x) { return CamelDefinitionApi.createPatchDefinition(x); }) : [];
        def.post = element && (element === null || element === void 0 ? void 0 : element.post) ? element === null || element === void 0 ? void 0 : element.post.map(function (x) { return CamelDefinitionApi.createPostDefinition(x); }) : [];
        def.put = element && (element === null || element === void 0 ? void 0 : element.put) ? element === null || element === void 0 ? void 0 : element.put.map(function (x) { return CamelDefinitionApi.createPutDefinition(x); }) : [];
        if ((element === null || element === void 0 ? void 0 : element.securityDefinitions) !== undefined) {
            def.securityDefinitions = CamelDefinitionApi.createRestSecuritiesDefinition(element.securityDefinitions);
        }
        def.securityRequirements = element && (element === null || element === void 0 ? void 0 : element.securityRequirements) ? element === null || element === void 0 ? void 0 : element.securityRequirements.map(function (x) { return CamelDefinitionApi.createSecurityDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createRestPropertyDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestPropertyDefinition(__assign({}, element)) : new CamelDefinition_1.RestPropertyDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createRestSecuritiesDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestSecuritiesDefinition(__assign({}, element)) : new CamelDefinition_1.RestSecuritiesDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.apiKey) !== undefined) {
            def.apiKey = CamelDefinitionApi.createApiKeyDefinition(element.apiKey);
        }
        if ((element === null || element === void 0 ? void 0 : element.basicAuth) !== undefined) {
            def.basicAuth = CamelDefinitionApi.createBasicAuthDefinition(element.basicAuth);
        }
        if ((element === null || element === void 0 ? void 0 : element.bearer) !== undefined) {
            def.bearer = CamelDefinitionApi.createBearerTokenDefinition(element.bearer);
        }
        if ((element === null || element === void 0 ? void 0 : element.mutualTls) !== undefined) {
            def.mutualTls = CamelDefinitionApi.createMutualTLSDefinition(element.mutualTls);
        }
        if ((element === null || element === void 0 ? void 0 : element.oauth2) !== undefined) {
            def.oauth2 = CamelDefinitionApi.createOAuth2Definition(element.oauth2);
        }
        if ((element === null || element === void 0 ? void 0 : element.openIdConnect) !== undefined) {
            def.openIdConnect = CamelDefinitionApi.createOpenIdConnectDefinition(element.openIdConnect);
        }
        return def;
    };
    CamelDefinitionApi.createRestsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.RestsDefinition(__assign({}, element)) : new CamelDefinition_1.RestsDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.rest = element && (element === null || element === void 0 ? void 0 : element.rest) ? element === null || element === void 0 ? void 0 : element.rest.map(function (x) { return CamelDefinitionApi.createRestDefinition(x); }) : [];
        return def;
    };
    CamelDefinitionApi.createSecurityDefinition = function (element) {
        var def = element ? new CamelDefinition_1.SecurityDefinition(__assign({}, element)) : new CamelDefinition_1.SecurityDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createCustomTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CustomTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.CustomTransformerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createDataFormatTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.DataFormatTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.DataFormatTransformerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.any23) !== undefined) {
            def.any23 = CamelDefinitionApi.createAny23DataFormat(element.any23);
        }
        if ((element === null || element === void 0 ? void 0 : element.asn1) !== undefined) {
            def.asn1 = CamelDefinitionApi.createASN1DataFormat(element.asn1);
        }
        if ((element === null || element === void 0 ? void 0 : element.avro) !== undefined) {
            def.avro = CamelDefinitionApi.createAvroDataFormat(element.avro);
        }
        if ((element === null || element === void 0 ? void 0 : element.barcode) !== undefined) {
            def.barcode = CamelDefinitionApi.createBarcodeDataFormat(element.barcode);
        }
        if ((element === null || element === void 0 ? void 0 : element.base64) !== undefined) {
            def.base64 = CamelDefinitionApi.createBase64DataFormat(element.base64);
        }
        if ((element === null || element === void 0 ? void 0 : element.bindy) !== undefined) {
            def.bindy = CamelDefinitionApi.createBindyDataFormat(element.bindy);
        }
        if ((element === null || element === void 0 ? void 0 : element.cbor) !== undefined) {
            def.cbor = CamelDefinitionApi.createCBORDataFormat(element.cbor);
        }
        if ((element === null || element === void 0 ? void 0 : element.crypto) !== undefined) {
            def.crypto = CamelDefinitionApi.createCryptoDataFormat(element.crypto);
        }
        if ((element === null || element === void 0 ? void 0 : element.csv) !== undefined) {
            def.csv = CamelDefinitionApi.createCsvDataFormat(element.csv);
        }
        if ((element === null || element === void 0 ? void 0 : element.custom) !== undefined) {
            def.custom = CamelDefinitionApi.createCustomDataFormat(element.custom);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirJson) !== undefined) {
            def.fhirJson = CamelDefinitionApi.createFhirJsonDataFormat(element.fhirJson);
        }
        if ((element === null || element === void 0 ? void 0 : element.fhirXml) !== undefined) {
            def.fhirXml = CamelDefinitionApi.createFhirXmlDataFormat(element.fhirXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.flatpack) !== undefined) {
            def.flatpack = CamelDefinitionApi.createFlatpackDataFormat(element.flatpack);
        }
        if ((element === null || element === void 0 ? void 0 : element.grok) !== undefined) {
            def.grok = CamelDefinitionApi.createGrokDataFormat(element.grok);
        }
        if ((element === null || element === void 0 ? void 0 : element.gzipDeflater) !== undefined) {
            def.gzipDeflater = CamelDefinitionApi.createGzipDeflaterDataFormat(element.gzipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.hl7) !== undefined) {
            def.hl7 = CamelDefinitionApi.createHL7DataFormat(element.hl7);
        }
        if ((element === null || element === void 0 ? void 0 : element.ical) !== undefined) {
            def.ical = CamelDefinitionApi.createIcalDataFormat(element.ical);
        }
        if ((element === null || element === void 0 ? void 0 : element.jacksonXml) !== undefined) {
            def.jacksonXml = CamelDefinitionApi.createJacksonXMLDataFormat(element.jacksonXml);
        }
        if ((element === null || element === void 0 ? void 0 : element.jaxb) !== undefined) {
            def.jaxb = CamelDefinitionApi.createJaxbDataFormat(element.jaxb);
        }
        if ((element === null || element === void 0 ? void 0 : element.json) !== undefined) {
            def.json = CamelDefinitionApi.createJsonDataFormat(element.json);
        }
        if ((element === null || element === void 0 ? void 0 : element.jsonApi) !== undefined) {
            def.jsonApi = CamelDefinitionApi.createJsonApiDataFormat(element.jsonApi);
        }
        if ((element === null || element === void 0 ? void 0 : element.lzf) !== undefined) {
            def.lzf = CamelDefinitionApi.createLZFDataFormat(element.lzf);
        }
        if ((element === null || element === void 0 ? void 0 : element.mimeMultipart) !== undefined) {
            def.mimeMultipart = CamelDefinitionApi.createMimeMultipartDataFormat(element.mimeMultipart);
        }
        if ((element === null || element === void 0 ? void 0 : element.pgp) !== undefined) {
            def.pgp = CamelDefinitionApi.createPGPDataFormat(element.pgp);
        }
        if ((element === null || element === void 0 ? void 0 : element.protobuf) !== undefined) {
            def.protobuf = CamelDefinitionApi.createProtobufDataFormat(element.protobuf);
        }
        if ((element === null || element === void 0 ? void 0 : element.rss) !== undefined) {
            def.rss = CamelDefinitionApi.createRssDataFormat(element.rss);
        }
        if ((element === null || element === void 0 ? void 0 : element.soap) !== undefined) {
            def.soap = CamelDefinitionApi.createSoapDataFormat(element.soap);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMt) !== undefined) {
            def.swiftMt = CamelDefinitionApi.createSwiftMtDataFormat(element.swiftMt);
        }
        if ((element === null || element === void 0 ? void 0 : element.swiftMx) !== undefined) {
            def.swiftMx = CamelDefinitionApi.createSwiftMxDataFormat(element.swiftMx);
        }
        if ((element === null || element === void 0 ? void 0 : element.syslog) !== undefined) {
            def.syslog = CamelDefinitionApi.createSyslogDataFormat(element.syslog);
        }
        if ((element === null || element === void 0 ? void 0 : element.tarFile) !== undefined) {
            def.tarFile = CamelDefinitionApi.createTarFileDataFormat(element.tarFile);
        }
        if ((element === null || element === void 0 ? void 0 : element.thrift) !== undefined) {
            def.thrift = CamelDefinitionApi.createThriftDataFormat(element.thrift);
        }
        if ((element === null || element === void 0 ? void 0 : element.tidyMarkup) !== undefined) {
            def.tidyMarkup = CamelDefinitionApi.createTidyMarkupDataFormat(element.tidyMarkup);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityCsv) !== undefined) {
            def.univocityCsv = CamelDefinitionApi.createUniVocityCsvDataFormat(element.univocityCsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityFixed) !== undefined) {
            def.univocityFixed = CamelDefinitionApi.createUniVocityFixedDataFormat(element.univocityFixed);
        }
        if ((element === null || element === void 0 ? void 0 : element.univocityTsv) !== undefined) {
            def.univocityTsv = CamelDefinitionApi.createUniVocityTsvDataFormat(element.univocityTsv);
        }
        if ((element === null || element === void 0 ? void 0 : element.xmlSecurity) !== undefined) {
            def.xmlSecurity = CamelDefinitionApi.createXMLSecurityDataFormat(element.xmlSecurity);
        }
        if ((element === null || element === void 0 ? void 0 : element.xstream) !== undefined) {
            def.xstream = CamelDefinitionApi.createXStreamDataFormat(element.xstream);
        }
        if ((element === null || element === void 0 ? void 0 : element.yaml) !== undefined) {
            def.yaml = CamelDefinitionApi.createYAMLDataFormat(element.yaml);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipDeflater) !== undefined) {
            def.zipDeflater = CamelDefinitionApi.createZipDeflaterDataFormat(element.zipDeflater);
        }
        if ((element === null || element === void 0 ? void 0 : element.zipFile) !== undefined) {
            def.zipFile = CamelDefinitionApi.createZipFileDataFormat(element.zipFile);
        }
        return def;
    };
    CamelDefinitionApi.createEndpointTransformerDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EndpointTransformerDefinition(__assign({}, element)) : new CamelDefinition_1.EndpointTransformerDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createTransformersDefinition = function (element) {
        var def = element ? new CamelDefinition_1.TransformersDefinition(__assign({}, element)) : new CamelDefinition_1.TransformersDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.customTransformer) !== undefined) {
            def.customTransformer = CamelDefinitionApi.createCustomTransformerDefinition(element.customTransformer);
        }
        if ((element === null || element === void 0 ? void 0 : element.dataFormatTransformer) !== undefined) {
            def.dataFormatTransformer = CamelDefinitionApi.createDataFormatTransformerDefinition(element.dataFormatTransformer);
        }
        if ((element === null || element === void 0 ? void 0 : element.endpointTransformer) !== undefined) {
            def.endpointTransformer = CamelDefinitionApi.createEndpointTransformerDefinition(element.endpointTransformer);
        }
        return def;
    };
    CamelDefinitionApi.createCustomValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.CustomValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.CustomValidatorDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createEndpointValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.EndpointValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.EndpointValidatorDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        return def;
    };
    CamelDefinitionApi.createPredicateValidatorDefinition = function (element) {
        var def = element ? new CamelDefinition_1.PredicateValidatorDefinition(__assign({}, element)) : new CamelDefinition_1.PredicateValidatorDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        def.expression = CamelDefinitionApi.createExpressionDefinition(element.expression);
        return def;
    };
    CamelDefinitionApi.createValidatorsDefinition = function (element) {
        var def = element ? new CamelDefinition_1.ValidatorsDefinition(__assign({}, element)) : new CamelDefinition_1.ValidatorsDefinition();
        def.uuid = (element === null || element === void 0 ? void 0 : element.uuid) ? element.uuid : def.uuid;
        if ((element === null || element === void 0 ? void 0 : element.customValidator) !== undefined) {
            def.customValidator = CamelDefinitionApi.createCustomValidatorDefinition(element.customValidator);
        }
        if ((element === null || element === void 0 ? void 0 : element.endpointValidator) !== undefined) {
            def.endpointValidator = CamelDefinitionApi.createEndpointValidatorDefinition(element.endpointValidator);
        }
        if ((element === null || element === void 0 ? void 0 : element.predicateValidator) !== undefined) {
            def.predicateValidator = CamelDefinitionApi.createPredicateValidatorDefinition(element.predicateValidator);
        }
        return def;
    };
    CamelDefinitionApi.createStep = function (name, body, clone) {
        if (clone === void 0) { clone = false; }
        var newBody = CamelUtil_1.CamelUtil.camelizeBody(name, body, clone);
        switch (name) {
            case 'ProcessorDefinition': return CamelDefinitionApi.createProcessorDefinition(newBody);
            case 'BeansDeserializer': return CamelDefinitionApi.createBeansDeserializer(newBody);
            case 'ErrorHandlerBuilderDeserializer': return CamelDefinitionApi.createErrorHandlerBuilderDeserializer(newBody);
            case 'NamedBeanDefinition': return CamelDefinitionApi.createNamedBeanDefinition(newBody);
            case 'OutputAwareFromDefinition': return CamelDefinitionApi.createOutputAwareFromDefinition(newBody);
            case 'AggregateDefinition': return CamelDefinitionApi.createAggregateDefinition(newBody);
            case 'BeanDefinition': return CamelDefinitionApi.createBeanDefinition(newBody);
            case 'CatchDefinition': return CamelDefinitionApi.createCatchDefinition(newBody);
            case 'ChoiceDefinition': return CamelDefinitionApi.createChoiceDefinition(newBody);
            case 'CircuitBreakerDefinition': return CamelDefinitionApi.createCircuitBreakerDefinition(newBody);
            case 'ClaimCheckDefinition': return CamelDefinitionApi.createClaimCheckDefinition(newBody);
            case 'ContextScanDefinition': return CamelDefinitionApi.createContextScanDefinition(newBody);
            case 'ConvertBodyDefinition': return CamelDefinitionApi.createConvertBodyDefinition(newBody);
            case 'DataFormatDefinition': return CamelDefinitionApi.createDataFormatDefinition(newBody);
            case 'DelayDefinition': return CamelDefinitionApi.createDelayDefinition(newBody);
            case 'DescriptionDefinition': return CamelDefinitionApi.createDescriptionDefinition(newBody);
            case 'DynamicRouterDefinition': return CamelDefinitionApi.createDynamicRouterDefinition(newBody);
            case 'EnrichDefinition': return CamelDefinitionApi.createEnrichDefinition(newBody);
            case 'ErrorHandlerDefinition': return CamelDefinitionApi.createErrorHandlerDefinition(newBody);
            case 'ExpressionSubElementDefinition': return CamelDefinitionApi.createExpressionSubElementDefinition(newBody);
            case 'FaultToleranceConfigurationDefinition': return CamelDefinitionApi.createFaultToleranceConfigurationDefinition(newBody);
            case 'FilterDefinition': return CamelDefinitionApi.createFilterDefinition(newBody);
            case 'FinallyDefinition': return CamelDefinitionApi.createFinallyDefinition(newBody);
            case 'FromDefinition': return CamelDefinitionApi.createFromDefinition(newBody);
            case 'GlobalOptionDefinition': return CamelDefinitionApi.createGlobalOptionDefinition(newBody);
            case 'GlobalOptionsDefinition': return CamelDefinitionApi.createGlobalOptionsDefinition(newBody);
            case 'IdempotentConsumerDefinition': return CamelDefinitionApi.createIdempotentConsumerDefinition(newBody);
            case 'InOnlyDefinition': return CamelDefinitionApi.createInOnlyDefinition(newBody);
            case 'InOutDefinition': return CamelDefinitionApi.createInOutDefinition(newBody);
            case 'InputTypeDefinition': return CamelDefinitionApi.createInputTypeDefinition(newBody);
            case 'InterceptDefinition': return CamelDefinitionApi.createInterceptDefinition(newBody);
            case 'InterceptFromDefinition': return CamelDefinitionApi.createInterceptFromDefinition(newBody);
            case 'InterceptSendToEndpointDefinition': return CamelDefinitionApi.createInterceptSendToEndpointDefinition(newBody);
            case 'KameletDefinition': return CamelDefinitionApi.createKameletDefinition(newBody);
            case 'LoadBalanceDefinition': return CamelDefinitionApi.createLoadBalanceDefinition(newBody);
            case 'LogDefinition': return CamelDefinitionApi.createLogDefinition(newBody);
            case 'LoopDefinition': return CamelDefinitionApi.createLoopDefinition(newBody);
            case 'MarshalDefinition': return CamelDefinitionApi.createMarshalDefinition(newBody);
            case 'MulticastDefinition': return CamelDefinitionApi.createMulticastDefinition(newBody);
            case 'OnCompletionDefinition': return CamelDefinitionApi.createOnCompletionDefinition(newBody);
            case 'OnExceptionDefinition': return CamelDefinitionApi.createOnExceptionDefinition(newBody);
            case 'OnFallbackDefinition': return CamelDefinitionApi.createOnFallbackDefinition(newBody);
            case 'OptimisticLockRetryPolicyDefinition': return CamelDefinitionApi.createOptimisticLockRetryPolicyDefinition(newBody);
            case 'OtherwiseDefinition': return CamelDefinitionApi.createOtherwiseDefinition(newBody);
            case 'OutputDefinition': return CamelDefinitionApi.createOutputDefinition(newBody);
            case 'OutputTypeDefinition': return CamelDefinitionApi.createOutputTypeDefinition(newBody);
            case 'PackageScanDefinition': return CamelDefinitionApi.createPackageScanDefinition(newBody);
            case 'PausableDefinition': return CamelDefinitionApi.createPausableDefinition(newBody);
            case 'PipelineDefinition': return CamelDefinitionApi.createPipelineDefinition(newBody);
            case 'PolicyDefinition': return CamelDefinitionApi.createPolicyDefinition(newBody);
            case 'PollEnrichDefinition': return CamelDefinitionApi.createPollEnrichDefinition(newBody);
            case 'ProcessDefinition': return CamelDefinitionApi.createProcessDefinition(newBody);
            case 'PropertyDefinition': return CamelDefinitionApi.createPropertyDefinition(newBody);
            case 'PropertyExpressionDefinition': return CamelDefinitionApi.createPropertyExpressionDefinition(newBody);
            case 'RecipientListDefinition': return CamelDefinitionApi.createRecipientListDefinition(newBody);
            case 'RedeliveryPolicyDefinition': return CamelDefinitionApi.createRedeliveryPolicyDefinition(newBody);
            case 'RemoveHeaderDefinition': return CamelDefinitionApi.createRemoveHeaderDefinition(newBody);
            case 'RemoveHeadersDefinition': return CamelDefinitionApi.createRemoveHeadersDefinition(newBody);
            case 'RemovePropertiesDefinition': return CamelDefinitionApi.createRemovePropertiesDefinition(newBody);
            case 'RemovePropertyDefinition': return CamelDefinitionApi.createRemovePropertyDefinition(newBody);
            case 'ResequenceDefinition': return CamelDefinitionApi.createResequenceDefinition(newBody);
            case 'Resilience4jConfigurationDefinition': return CamelDefinitionApi.createResilience4jConfigurationDefinition(newBody);
            case 'RestContextRefDefinition': return CamelDefinitionApi.createRestContextRefDefinition(newBody);
            case 'ResumableDefinition': return CamelDefinitionApi.createResumableDefinition(newBody);
            case 'RollbackDefinition': return CamelDefinitionApi.createRollbackDefinition(newBody);
            case 'RouteBuilderDefinition': return CamelDefinitionApi.createRouteBuilderDefinition(newBody);
            case 'RouteConfigurationContextRefDefinition': return CamelDefinitionApi.createRouteConfigurationContextRefDefinition(newBody);
            case 'RouteConfigurationDefinition': return CamelDefinitionApi.createRouteConfigurationDefinition(newBody);
            case 'RouteContextRefDefinition': return CamelDefinitionApi.createRouteContextRefDefinition(newBody);
            case 'RouteDefinition': return CamelDefinitionApi.createRouteDefinition(newBody);
            case 'RouteTemplateBeanDefinition': return CamelDefinitionApi.createRouteTemplateBeanDefinition(newBody);
            case 'RouteTemplateDefinition': return CamelDefinitionApi.createRouteTemplateDefinition(newBody);
            case 'RouteTemplateParameterDefinition': return CamelDefinitionApi.createRouteTemplateParameterDefinition(newBody);
            case 'RoutingSlipDefinition': return CamelDefinitionApi.createRoutingSlipDefinition(newBody);
            case 'SagaActionUriDefinition': return CamelDefinitionApi.createSagaActionUriDefinition(newBody);
            case 'SagaDefinition': return CamelDefinitionApi.createSagaDefinition(newBody);
            case 'SamplingDefinition': return CamelDefinitionApi.createSamplingDefinition(newBody);
            case 'ScriptDefinition': return CamelDefinitionApi.createScriptDefinition(newBody);
            case 'SetBodyDefinition': return CamelDefinitionApi.createSetBodyDefinition(newBody);
            case 'SetExchangePatternDefinition': return CamelDefinitionApi.createSetExchangePatternDefinition(newBody);
            case 'SetHeaderDefinition': return CamelDefinitionApi.createSetHeaderDefinition(newBody);
            case 'SetPropertyDefinition': return CamelDefinitionApi.createSetPropertyDefinition(newBody);
            case 'SortDefinition': return CamelDefinitionApi.createSortDefinition(newBody);
            case 'SplitDefinition': return CamelDefinitionApi.createSplitDefinition(newBody);
            case 'StepDefinition': return CamelDefinitionApi.createStepDefinition(newBody);
            case 'StopDefinition': return CamelDefinitionApi.createStopDefinition(newBody);
            case 'TemplatedRouteBeanDefinition': return CamelDefinitionApi.createTemplatedRouteBeanDefinition(newBody);
            case 'TemplatedRouteDefinition': return CamelDefinitionApi.createTemplatedRouteDefinition(newBody);
            case 'TemplatedRouteParameterDefinition': return CamelDefinitionApi.createTemplatedRouteParameterDefinition(newBody);
            case 'ThreadPoolProfileDefinition': return CamelDefinitionApi.createThreadPoolProfileDefinition(newBody);
            case 'ThreadsDefinition': return CamelDefinitionApi.createThreadsDefinition(newBody);
            case 'ThrottleDefinition': return CamelDefinitionApi.createThrottleDefinition(newBody);
            case 'ThrowExceptionDefinition': return CamelDefinitionApi.createThrowExceptionDefinition(newBody);
            case 'ToDefinition': return CamelDefinitionApi.createToDefinition(newBody);
            case 'ToDynamicDefinition': return CamelDefinitionApi.createToDynamicDefinition(newBody);
            case 'TransactedDefinition': return CamelDefinitionApi.createTransactedDefinition(newBody);
            case 'TransformDefinition': return CamelDefinitionApi.createTransformDefinition(newBody);
            case 'TryDefinition': return CamelDefinitionApi.createTryDefinition(newBody);
            case 'UnmarshalDefinition': return CamelDefinitionApi.createUnmarshalDefinition(newBody);
            case 'ValidateDefinition': return CamelDefinitionApi.createValidateDefinition(newBody);
            case 'ValueDefinition': return CamelDefinitionApi.createValueDefinition(newBody);
            case 'WhenDefinition': return CamelDefinitionApi.createWhenDefinition(newBody);
            case 'WhenSkipSendToEndpointDefinition': return CamelDefinitionApi.createWhenSkipSendToEndpointDefinition(newBody);
            case 'WireTapDefinition': return CamelDefinitionApi.createWireTapDefinition(newBody);
            case 'BlacklistServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createBlacklistServiceCallServiceFilterConfiguration(newBody);
            case 'CachingServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createCachingServiceCallServiceDiscoveryConfiguration(newBody);
            case 'CombinedServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createCombinedServiceCallServiceDiscoveryConfiguration(newBody);
            case 'CombinedServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createCombinedServiceCallServiceFilterConfiguration(newBody);
            case 'ConsulServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createConsulServiceCallServiceDiscoveryConfiguration(newBody);
            case 'CustomServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createCustomServiceCallServiceFilterConfiguration(newBody);
            case 'DefaultServiceCallServiceLoadBalancerConfiguration': return CamelDefinitionApi.createDefaultServiceCallServiceLoadBalancerConfiguration(newBody);
            case 'DnsServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createDnsServiceCallServiceDiscoveryConfiguration(newBody);
            case 'HealthyServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createHealthyServiceCallServiceFilterConfiguration(newBody);
            case 'KubernetesServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createKubernetesServiceCallServiceDiscoveryConfiguration(newBody);
            case 'PassThroughServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createPassThroughServiceCallServiceFilterConfiguration(newBody);
            case 'ServiceCallConfigurationDefinition': return CamelDefinitionApi.createServiceCallConfigurationDefinition(newBody);
            case 'ServiceCallDefinition': return CamelDefinitionApi.createServiceCallDefinition(newBody);
            case 'ServiceCallExpressionConfiguration': return CamelDefinitionApi.createServiceCallExpressionConfiguration(newBody);
            case 'ServiceCallServiceChooserConfiguration': return CamelDefinitionApi.createServiceCallServiceChooserConfiguration(newBody);
            case 'ServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createServiceCallServiceDiscoveryConfiguration(newBody);
            case 'ServiceCallServiceFilterConfiguration': return CamelDefinitionApi.createServiceCallServiceFilterConfiguration(newBody);
            case 'ServiceCallServiceLoadBalancerConfiguration': return CamelDefinitionApi.createServiceCallServiceLoadBalancerConfiguration(newBody);
            case 'StaticServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createStaticServiceCallServiceDiscoveryConfiguration(newBody);
            case 'ZooKeeperServiceCallServiceDiscoveryConfiguration': return CamelDefinitionApi.createZooKeeperServiceCallServiceDiscoveryConfiguration(newBody);
            case 'BatchResequencerConfig': return CamelDefinitionApi.createBatchResequencerConfig(newBody);
            case 'StreamResequencerConfig': return CamelDefinitionApi.createStreamResequencerConfig(newBody);
            case 'ASN1DataFormat': return CamelDefinitionApi.createASN1DataFormat(newBody);
            case 'Any23DataFormat': return CamelDefinitionApi.createAny23DataFormat(newBody);
            case 'AvroDataFormat': return CamelDefinitionApi.createAvroDataFormat(newBody);
            case 'BarcodeDataFormat': return CamelDefinitionApi.createBarcodeDataFormat(newBody);
            case 'Base64DataFormat': return CamelDefinitionApi.createBase64DataFormat(newBody);
            case 'BindyDataFormat': return CamelDefinitionApi.createBindyDataFormat(newBody);
            case 'CBORDataFormat': return CamelDefinitionApi.createCBORDataFormat(newBody);
            case 'CryptoDataFormat': return CamelDefinitionApi.createCryptoDataFormat(newBody);
            case 'CsvDataFormat': return CamelDefinitionApi.createCsvDataFormat(newBody);
            case 'CustomDataFormat': return CamelDefinitionApi.createCustomDataFormat(newBody);
            case 'DataFormatsDefinition': return CamelDefinitionApi.createDataFormatsDefinition(newBody);
            case 'FhirJsonDataFormat': return CamelDefinitionApi.createFhirJsonDataFormat(newBody);
            case 'FhirXmlDataFormat': return CamelDefinitionApi.createFhirXmlDataFormat(newBody);
            case 'FlatpackDataFormat': return CamelDefinitionApi.createFlatpackDataFormat(newBody);
            case 'GrokDataFormat': return CamelDefinitionApi.createGrokDataFormat(newBody);
            case 'GzipDeflaterDataFormat': return CamelDefinitionApi.createGzipDeflaterDataFormat(newBody);
            case 'HL7DataFormat': return CamelDefinitionApi.createHL7DataFormat(newBody);
            case 'IcalDataFormat': return CamelDefinitionApi.createIcalDataFormat(newBody);
            case 'JacksonXMLDataFormat': return CamelDefinitionApi.createJacksonXMLDataFormat(newBody);
            case 'JaxbDataFormat': return CamelDefinitionApi.createJaxbDataFormat(newBody);
            case 'JsonApiDataFormat': return CamelDefinitionApi.createJsonApiDataFormat(newBody);
            case 'JsonDataFormat': return CamelDefinitionApi.createJsonDataFormat(newBody);
            case 'LZFDataFormat': return CamelDefinitionApi.createLZFDataFormat(newBody);
            case 'MimeMultipartDataFormat': return CamelDefinitionApi.createMimeMultipartDataFormat(newBody);
            case 'PGPDataFormat': return CamelDefinitionApi.createPGPDataFormat(newBody);
            case 'ProtobufDataFormat': return CamelDefinitionApi.createProtobufDataFormat(newBody);
            case 'RssDataFormat': return CamelDefinitionApi.createRssDataFormat(newBody);
            case 'SoapDataFormat': return CamelDefinitionApi.createSoapDataFormat(newBody);
            case 'SwiftMtDataFormat': return CamelDefinitionApi.createSwiftMtDataFormat(newBody);
            case 'SwiftMxDataFormat': return CamelDefinitionApi.createSwiftMxDataFormat(newBody);
            case 'SyslogDataFormat': return CamelDefinitionApi.createSyslogDataFormat(newBody);
            case 'TarFileDataFormat': return CamelDefinitionApi.createTarFileDataFormat(newBody);
            case 'ThriftDataFormat': return CamelDefinitionApi.createThriftDataFormat(newBody);
            case 'TidyMarkupDataFormat': return CamelDefinitionApi.createTidyMarkupDataFormat(newBody);
            case 'UniVocityCsvDataFormat': return CamelDefinitionApi.createUniVocityCsvDataFormat(newBody);
            case 'UniVocityFixedDataFormat': return CamelDefinitionApi.createUniVocityFixedDataFormat(newBody);
            case 'UniVocityHeader': return CamelDefinitionApi.createUniVocityHeader(newBody);
            case 'UniVocityTsvDataFormat': return CamelDefinitionApi.createUniVocityTsvDataFormat(newBody);
            case 'XMLSecurityDataFormat': return CamelDefinitionApi.createXMLSecurityDataFormat(newBody);
            case 'XStreamDataFormat': return CamelDefinitionApi.createXStreamDataFormat(newBody);
            case 'YAMLDataFormat': return CamelDefinitionApi.createYAMLDataFormat(newBody);
            case 'YAMLTypeFilterDefinition': return CamelDefinitionApi.createYAMLTypeFilterDefinition(newBody);
            case 'ZipDeflaterDataFormat': return CamelDefinitionApi.createZipDeflaterDataFormat(newBody);
            case 'ZipFileDataFormat': return CamelDefinitionApi.createZipFileDataFormat(newBody);
            case 'DeadLetterChannelDefinition': return CamelDefinitionApi.createDeadLetterChannelDefinition(newBody);
            case 'DefaultErrorHandlerDefinition': return CamelDefinitionApi.createDefaultErrorHandlerDefinition(newBody);
            case 'JtaTransactionErrorHandlerDefinition': return CamelDefinitionApi.createJtaTransactionErrorHandlerDefinition(newBody);
            case 'NoErrorHandlerDefinition': return CamelDefinitionApi.createNoErrorHandlerDefinition(newBody);
            case 'RefErrorHandlerDefinition': return CamelDefinitionApi.createRefErrorHandlerDefinition(newBody);
            case 'SpringTransactionErrorHandlerDefinition': return CamelDefinitionApi.createSpringTransactionErrorHandlerDefinition(newBody);
            case 'CSimpleExpression': return CamelDefinitionApi.createCSimpleExpression(newBody);
            case 'ConstantExpression': return CamelDefinitionApi.createConstantExpression(newBody);
            case 'DatasonnetExpression': return CamelDefinitionApi.createDatasonnetExpression(newBody);
            case 'ExchangePropertyExpression': return CamelDefinitionApi.createExchangePropertyExpression(newBody);
            case 'ExpressionDefinition': return CamelDefinitionApi.createExpressionDefinition(newBody);
            case 'GroovyExpression': return CamelDefinitionApi.createGroovyExpression(newBody);
            case 'HeaderExpression': return CamelDefinitionApi.createHeaderExpression(newBody);
            case 'Hl7TerserExpression': return CamelDefinitionApi.createHl7TerserExpression(newBody);
            case 'JavaScriptExpression': return CamelDefinitionApi.createJavaScriptExpression(newBody);
            case 'JoorExpression': return CamelDefinitionApi.createJoorExpression(newBody);
            case 'JqExpression': return CamelDefinitionApi.createJqExpression(newBody);
            case 'JsonPathExpression': return CamelDefinitionApi.createJsonPathExpression(newBody);
            case 'LanguageExpression': return CamelDefinitionApi.createLanguageExpression(newBody);
            case 'MethodCallExpression': return CamelDefinitionApi.createMethodCallExpression(newBody);
            case 'MvelExpression': return CamelDefinitionApi.createMvelExpression(newBody);
            case 'OgnlExpression': return CamelDefinitionApi.createOgnlExpression(newBody);
            case 'PythonExpression': return CamelDefinitionApi.createPythonExpression(newBody);
            case 'RefExpression': return CamelDefinitionApi.createRefExpression(newBody);
            case 'SimpleExpression': return CamelDefinitionApi.createSimpleExpression(newBody);
            case 'SpELExpression': return CamelDefinitionApi.createSpELExpression(newBody);
            case 'TokenizerExpression': return CamelDefinitionApi.createTokenizerExpression(newBody);
            case 'XMLTokenizerExpression': return CamelDefinitionApi.createXMLTokenizerExpression(newBody);
            case 'XPathExpression': return CamelDefinitionApi.createXPathExpression(newBody);
            case 'XQueryExpression': return CamelDefinitionApi.createXQueryExpression(newBody);
            case 'CustomLoadBalancerDefinition': return CamelDefinitionApi.createCustomLoadBalancerDefinition(newBody);
            case 'FailoverLoadBalancerDefinition': return CamelDefinitionApi.createFailoverLoadBalancerDefinition(newBody);
            case 'RandomLoadBalancerDefinition': return CamelDefinitionApi.createRandomLoadBalancerDefinition(newBody);
            case 'RoundRobinLoadBalancerDefinition': return CamelDefinitionApi.createRoundRobinLoadBalancerDefinition(newBody);
            case 'StickyLoadBalancerDefinition': return CamelDefinitionApi.createStickyLoadBalancerDefinition(newBody);
            case 'TopicLoadBalancerDefinition': return CamelDefinitionApi.createTopicLoadBalancerDefinition(newBody);
            case 'WeightedLoadBalancerDefinition': return CamelDefinitionApi.createWeightedLoadBalancerDefinition(newBody);
            case 'ApiKeyDefinition': return CamelDefinitionApi.createApiKeyDefinition(newBody);
            case 'BasicAuthDefinition': return CamelDefinitionApi.createBasicAuthDefinition(newBody);
            case 'BearerTokenDefinition': return CamelDefinitionApi.createBearerTokenDefinition(newBody);
            case 'DeleteDefinition': return CamelDefinitionApi.createDeleteDefinition(newBody);
            case 'GetDefinition': return CamelDefinitionApi.createGetDefinition(newBody);
            case 'HeadDefinition': return CamelDefinitionApi.createHeadDefinition(newBody);
            case 'MutualTLSDefinition': return CamelDefinitionApi.createMutualTLSDefinition(newBody);
            case 'OAuth2Definition': return CamelDefinitionApi.createOAuth2Definition(newBody);
            case 'OpenIdConnectDefinition': return CamelDefinitionApi.createOpenIdConnectDefinition(newBody);
            case 'ParamDefinition': return CamelDefinitionApi.createParamDefinition(newBody);
            case 'PatchDefinition': return CamelDefinitionApi.createPatchDefinition(newBody);
            case 'PostDefinition': return CamelDefinitionApi.createPostDefinition(newBody);
            case 'PutDefinition': return CamelDefinitionApi.createPutDefinition(newBody);
            case 'ResponseHeaderDefinition': return CamelDefinitionApi.createResponseHeaderDefinition(newBody);
            case 'ResponseMessageDefinition': return CamelDefinitionApi.createResponseMessageDefinition(newBody);
            case 'RestBindingDefinition': return CamelDefinitionApi.createRestBindingDefinition(newBody);
            case 'RestConfigurationDefinition': return CamelDefinitionApi.createRestConfigurationDefinition(newBody);
            case 'RestDefinition': return CamelDefinitionApi.createRestDefinition(newBody);
            case 'RestPropertyDefinition': return CamelDefinitionApi.createRestPropertyDefinition(newBody);
            case 'RestSecuritiesDefinition': return CamelDefinitionApi.createRestSecuritiesDefinition(newBody);
            case 'RestsDefinition': return CamelDefinitionApi.createRestsDefinition(newBody);
            case 'SecurityDefinition': return CamelDefinitionApi.createSecurityDefinition(newBody);
            case 'CustomTransformerDefinition': return CamelDefinitionApi.createCustomTransformerDefinition(newBody);
            case 'DataFormatTransformerDefinition': return CamelDefinitionApi.createDataFormatTransformerDefinition(newBody);
            case 'EndpointTransformerDefinition': return CamelDefinitionApi.createEndpointTransformerDefinition(newBody);
            case 'TransformersDefinition': return CamelDefinitionApi.createTransformersDefinition(newBody);
            case 'CustomValidatorDefinition': return CamelDefinitionApi.createCustomValidatorDefinition(newBody);
            case 'EndpointValidatorDefinition': return CamelDefinitionApi.createEndpointValidatorDefinition(newBody);
            case 'PredicateValidatorDefinition': return CamelDefinitionApi.createPredicateValidatorDefinition(newBody);
            case 'ValidatorsDefinition': return CamelDefinitionApi.createValidatorsDefinition(newBody);
            default: return new IntegrationDefinition_1.CamelElement('');
        }
    };
    CamelDefinitionApi.createExpression = function (name, body) {
        var newBody = CamelUtil_1.CamelUtil.camelizeBody(name, body, false);
        delete newBody.expressionName;
        delete newBody.dslName;
        switch (name) {
            case 'XPathExpression': return CamelDefinitionApi.createXPathExpression(newBody);
            case 'ConstantExpression': return CamelDefinitionApi.createConstantExpression(newBody);
            case 'GroovyExpression': return CamelDefinitionApi.createGroovyExpression(newBody);
            case 'SimpleExpression': return CamelDefinitionApi.createSimpleExpression(newBody);
            case 'MvelExpression': return CamelDefinitionApi.createMvelExpression(newBody);
            case 'RefExpression': return CamelDefinitionApi.createRefExpression(newBody);
            case 'MethodCallExpression': return CamelDefinitionApi.createMethodCallExpression(newBody);
            case 'LanguageExpression': return CamelDefinitionApi.createLanguageExpression(newBody);
            case 'ExchangePropertyExpression': return CamelDefinitionApi.createExchangePropertyExpression(newBody);
            case 'JavaScriptExpression': return CamelDefinitionApi.createJavaScriptExpression(newBody);
            case 'OgnlExpression': return CamelDefinitionApi.createOgnlExpression(newBody);
            case 'PythonExpression': return CamelDefinitionApi.createPythonExpression(newBody);
            case 'XQueryExpression': return CamelDefinitionApi.createXQueryExpression(newBody);
            case 'HeaderExpression': return CamelDefinitionApi.createHeaderExpression(newBody);
            case 'CSimpleExpression': return CamelDefinitionApi.createCSimpleExpression(newBody);
            case 'XMLTokenizerExpression': return CamelDefinitionApi.createXMLTokenizerExpression(newBody);
            case 'JqExpression': return CamelDefinitionApi.createJqExpression(newBody);
            case 'DatasonnetExpression': return CamelDefinitionApi.createDatasonnetExpression(newBody);
            case 'TokenizerExpression': return CamelDefinitionApi.createTokenizerExpression(newBody);
            case 'SpELExpression': return CamelDefinitionApi.createSpELExpression(newBody);
            case 'JoorExpression': return CamelDefinitionApi.createJoorExpression(newBody);
            case 'JsonPathExpression': return CamelDefinitionApi.createJsonPathExpression(newBody);
            case 'Hl7TerserExpression': return CamelDefinitionApi.createHl7TerserExpression(newBody);
            default: return new CamelDefinition_1.SimpleExpression(newBody);
        }
    };
    CamelDefinitionApi.createDataFormat = function (name, body) {
        var newBody = CamelUtil_1.CamelUtil.camelizeBody(name, body, false);
        delete newBody.dataFormatName;
        delete newBody.dslName;
        switch (name) {
            case 'Any23DataFormat': return CamelDefinitionApi.createAny23DataFormat(newBody);
            case 'TarFileDataFormat': return CamelDefinitionApi.createTarFileDataFormat(newBody);
            case 'CBORDataFormat': return CamelDefinitionApi.createCBORDataFormat(newBody);
            case 'LZFDataFormat': return CamelDefinitionApi.createLZFDataFormat(newBody);
            case 'UniVocityFixedDataFormat': return CamelDefinitionApi.createUniVocityFixedDataFormat(newBody);
            case 'JsonApiDataFormat': return CamelDefinitionApi.createJsonApiDataFormat(newBody);
            case 'XMLSecurityDataFormat': return CamelDefinitionApi.createXMLSecurityDataFormat(newBody);
            case 'TidyMarkupDataFormat': return CamelDefinitionApi.createTidyMarkupDataFormat(newBody);
            case 'FhirJsonDataFormat': return CamelDefinitionApi.createFhirJsonDataFormat(newBody);
            case 'ThriftDataFormat': return CamelDefinitionApi.createThriftDataFormat(newBody);
            case 'IcalDataFormat': return CamelDefinitionApi.createIcalDataFormat(newBody);
            case 'CryptoDataFormat': return CamelDefinitionApi.createCryptoDataFormat(newBody);
            case 'ZipFileDataFormat': return CamelDefinitionApi.createZipFileDataFormat(newBody);
            case 'JaxbDataFormat': return CamelDefinitionApi.createJaxbDataFormat(newBody);
            case 'UniVocityTsvDataFormat': return CamelDefinitionApi.createUniVocityTsvDataFormat(newBody);
            case 'BarcodeDataFormat': return CamelDefinitionApi.createBarcodeDataFormat(newBody);
            case 'CsvDataFormat': return CamelDefinitionApi.createCsvDataFormat(newBody);
            case 'GrokDataFormat': return CamelDefinitionApi.createGrokDataFormat(newBody);
            case 'FlatpackDataFormat': return CamelDefinitionApi.createFlatpackDataFormat(newBody);
            case 'SoapDataFormat': return CamelDefinitionApi.createSoapDataFormat(newBody);
            case 'AvroDataFormat': return CamelDefinitionApi.createAvroDataFormat(newBody);
            case 'GzipDeflaterDataFormat': return CamelDefinitionApi.createGzipDeflaterDataFormat(newBody);
            case 'JacksonXMLDataFormat': return CamelDefinitionApi.createJacksonXMLDataFormat(newBody);
            case 'ProtobufDataFormat': return CamelDefinitionApi.createProtobufDataFormat(newBody);
            case 'BindyDataFormat': return CamelDefinitionApi.createBindyDataFormat(newBody);
            case 'JsonDataFormat': return CamelDefinitionApi.createJsonDataFormat(newBody);
            case 'MimeMultipartDataFormat': return CamelDefinitionApi.createMimeMultipartDataFormat(newBody);
            case 'ZipDeflaterDataFormat': return CamelDefinitionApi.createZipDeflaterDataFormat(newBody);
            case 'ASN1DataFormat': return CamelDefinitionApi.createASN1DataFormat(newBody);
            case 'XStreamDataFormat': return CamelDefinitionApi.createXStreamDataFormat(newBody);
            case 'YAMLDataFormat': return CamelDefinitionApi.createYAMLDataFormat(newBody);
            case 'SwiftMxDataFormat': return CamelDefinitionApi.createSwiftMxDataFormat(newBody);
            case 'CustomDataFormat': return CamelDefinitionApi.createCustomDataFormat(newBody);
            case 'HL7DataFormat': return CamelDefinitionApi.createHL7DataFormat(newBody);
            case 'Base64DataFormat': return CamelDefinitionApi.createBase64DataFormat(newBody);
            case 'RssDataFormat': return CamelDefinitionApi.createRssDataFormat(newBody);
            case 'PGPDataFormat': return CamelDefinitionApi.createPGPDataFormat(newBody);
            case 'UniVocityCsvDataFormat': return CamelDefinitionApi.createUniVocityCsvDataFormat(newBody);
            case 'SyslogDataFormat': return CamelDefinitionApi.createSyslogDataFormat(newBody);
            case 'FhirXmlDataFormat': return CamelDefinitionApi.createFhirXmlDataFormat(newBody);
            case 'SwiftMtDataFormat': return CamelDefinitionApi.createSwiftMtDataFormat(newBody);
            default: return new CamelDefinition_1.JsonDataFormat(newBody);
        }
    };
    CamelDefinitionApi.createSteps = function (elements) {
        var result = [];
        if (elements !== undefined) {
            elements.forEach(function (e) {
                result.push(CamelDefinitionApi.createStep(e.dslName, e));
            });
        }
        return result;
    };
    return CamelDefinitionApi;
}());
exports.CamelDefinitionApi = CamelDefinitionApi;
